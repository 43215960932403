import {
  GET_LICENSE_SERVER_FAILURE,
  GET_LICENSE_SERVER_SUCCESS,
  GET_LICENSE_SERVER_START,
  RERENDER_LICENSE_SERVER,
} from "./actions";

/* initial state */
export const initialState = {
  list: [],
  loading: false,
  error: null,
  rerender: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_LICENSE_SERVER_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_LICENSE_SERVER_SUCCESS: {
      const { list } = payload;
      return {
        ...state,
        loading: false,
        list,
      };
    }
    case GET_LICENSE_SERVER_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }
    case RERENDER_LICENSE_SERVER: {
      const { rerender } = payload;
      return {
        ...state,
        rerender: !rerender,
      };
    }

    default:
      return state;
  }
}
