import domainStore from "./helpers/kcc/DomainStore";
import { getKccDomain, isKccUrl } from "./libs/kcc/domainUrls";

// Feature constants
export const CASE_RESIDUALS = "CASE_RESIDUALS";
export const AUTH_2FA = "AUTH_2FA";
export const GEODICT = "GEODICT";
export const TERMINATE_MACHINE = "TERMINATE_MACHINE";
export const STOP_SIMULATION_STEP = "STOP_SIMULATION_STEP";
export const DOE = "DOE";
export const AIBAT = "AIBAT";
export const BULK_DOWNLOAD = "BULK_DOWNLOAD";
export const TUTORIAL = "TUTORIAL";
export const PAYMENTS = "PAYMENTS";
export const LICENSE_SERVER = "LICENSE_SERVER";
export const SESES = "SESES";
export const COMSOL = "COMSOL";
export const SIMFLOW = "SIMFLOW";
export const TOGGLE = "TOGGLE";
// this is used for environments that have payment available (used on signup form)
export const SIGNUP_DISPLAY_CPU_HOURS_TEXT = "SIGNUP_DISPLAY_CPU_HOURS_TEXT";

export const featureFlagsConfig = {
  development: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: true,
    [PAYMENTS]: true,
    [LICENSE_SERVER]: true,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  test: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  staging: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: true,
    [PAYMENTS]: true,
    [LICENSE_SERVER]: true,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  production: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: true,
    [PAYMENTS]: true,
    [LICENSE_SERVER]: true,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  kidev: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: true,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  geodict: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: true,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  kstestcloud1: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: true,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  kiproduction: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  kistaging: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  kitestcloud1: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  kstestcloud2: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: true,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  kstestcloud3: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: true,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: true,
  },
  kitestcloud2: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  kitestcloud3: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: true,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  // new aibat kcc
  simvantage: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: true,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  seses: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: true,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  comsol: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: false,
    [AIBAT]: false,
    [SIMFLOW]: false,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: true,
    [LICENSE_SERVER]: true,
    [COMSOL]: true,
    [TOGGLE]: false,
    [SIGNUP_DISPLAY_CPU_HOURS_TEXT]: false,
  },
  simflow: {
    [CASE_RESIDUALS]: false,
    [AUTH_2FA]: true,
    [GEODICT]: false,
    [TERMINATE_MACHINE]: true,
    [BULK_DOWNLOAD]: true,
    [STOP_SIMULATION_STEP]: false,
    [DOE]: true,
    [AIBAT]: false,
    [SIMFLOW]: true,
    [SESES]: false,
    [TUTORIAL]: false,
    [PAYMENTS]: false,
    [LICENSE_SERVER]: false,
    [COMSOL]: false,
    [TOGGLE]: false,
  },
};

/**
 * It load the feature flags for the
 * different envs
 * @param {string} env
 * @returns
 */
const getFeatureFlagsForEnv = env => {
  let domain = domainStore.getDomain();
  const currentUrl = `${window.location.protocol}//${window.location.host}`; // returns the absolute URL of a page

  const isKccDom = isKccUrl(currentUrl);

  if (isKccDom) {
    const domainData = getKccDomain(currentUrl);
    domain = { url: currentUrl, domain: domainData };
    domainStore.storeDomain(domain);
  }

  if (domain !== null) {
    const data = featureFlagsConfig[domain.domain];
    return data;
  }

  if (env in featureFlagsConfig) {
    return featureFlagsConfig[env];
  }

  throw new Error("No featureFlags config present for current env");
};

export default getFeatureFlagsForEnv;
