/**
 * returns only the fileId, filePath and fileSize
 * @param {Object} caseData
 */
const formatCaseFileObject = caseData => {
  const caseFileObject = caseData.map(caseValue => {
    return { _id: caseValue._id, path: caseValue.filePath, fileSize: caseValue.fileSize };
  });

  return caseFileObject;
};

/**
 * Receive a cases file object {_id,path,fileSize}
 * and the initial values {result:[]} for the tree nodes,
 * result array contains the final tree nodes used in UI
 * all the nodes have an string value with the following format
 * `${pathValue}\0${fileId}\0${fileSize}`
 * "\0" character is used to separate the filePath
 * from other values (fileId, fileSize)
 * @param {Object} caseFile
 * @param {Object} initialNodes
 */
const buildTreeCheckboxNodes = (caseFile, initialNodes) => {
  const fileId = caseFile._id;
  const { fileSize } = caseFile;

  // split filePath value to get every  folder level
  caseFile.path.split("/").reduce((newObject, pathValue, index, val) => {
    const newTreeObject = newObject;

    // check if the node was already created
    if (!newObject[pathValue]) {
      newTreeObject[pathValue] = { result: [] };
      // Validates if it is the last element on the array
      if (index === val.length - 1) {
        newTreeObject.result.push({
          value: `${pathValue}\0${fileId}\0${fileSize}`,
          label: pathValue,
        });
      } else {
        newTreeObject.result.push({
          value: `${pathValue}\0${fileId}\0${fileSize}`,
          label: pathValue,
          children: newObject[pathValue].result,
        });
      }
    }

    return newObject[pathValue];
  }, initialNodes);

  return initialNodes.result;
};

/**
 * Convert array of objects into a valid format
 * for the TreeCheckbox component nodes
 * https://www.npmjs.com/package/react-checkbox-tree
 * @param {Array} paths
 */
export const convertPathToTreeCheckbox = caseFileData => {
  // validate path length
  if (caseFileData.length === 0) {
    return [];
  }

  const finalResult = [];

  // for every element on paths variable
  caseFileData.forEach((currentVal, i) => {
    let result = [];
    const initialNodes = { result: [] };

    // formating caseFiles object to contain only required fields
    const arrayPaths = formatCaseFileObject(currentVal);

    arrayPaths.forEach(caseFile => {
      // build a tree object
      result = buildTreeCheckboxNodes(caseFile, initialNodes);
    });

    const partresult = {
      value: `${caseFileData[i][0].caseFileName}`,
      label: `${caseFileData[i][0].caseFileName}`,
      className: "NodesLabel",
      children: result,
    };

    finalResult.push(partresult);
  });

  // get the project name from the received objects
  const { projectName } = caseFileData[0][0];
  const response = [{ value: projectName, label: projectName, className: "NodesLabel", children: finalResult }];
  return response;
};

export default convertPathToTreeCheckbox;
