import React, { useEffect } from "react";
import config from "react-global-configuration";
import ms from "ms";
import isEqual from "lodash/isEqual";
import watch from "redux-watch";

import { ApolloProvider } from "@apollo/client";
// import { ApolloProvider } from "react-apollo";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { QueryClient, QueryClientProvider } from "react-query";

import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

import client from "./apollo";
import CombinedContextProvider from "./context/CombinedContextProvider";
import SignupPage from "./components/login/signup/SignupPage";
import Signin from "./components/login/signin/Signin";
import NotFound404 from "./components/common/NotFound404";
import RecoveryCodePage from "./components/login/recovery-code/RecoveryCodePage";
import SendNewEmail from "./components/login/confirm-email/SendNewEmail";
import DocHead from "./components/DocHead";
import ThemeProvider from "./themes/ThemeProvider";
import ForgotPasswordPage from "./components/login/forgot-password/ForgotPasswordPage";
import ResetPassword from "./components/login/forgot-password/ResetPassword";
import ConnectedMsgDialog from "./components/common/ConnectedMsgDialog";
import domainStore from "./helpers/kcc/DomainStore";
import CustomDomainPage from "./components/custom-domain/CustomDomainPage";
import configureReduxStore from "./store/store";
import { writeStorage, reducersWhiteList, readStorage, STATE_KEY } from "./storage";
import { refreshTokenAPI, getConfigByUrl } from "./axios";

const loadingSpinner = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
// eslint-disable-next-line import/no-cycle
const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

const host = `${window.location.protocol}//${window.location.host}`; // returns the absolute URL of a page
const queryClient = new QueryClient();

function PrivateRoute({ Component, ...rest }) {
  // check if the user has a token, if they do they are logged in
  try {
    const authed = !(localStorage.getItem("token") === null);
    if (!authed) {
      return <Redirect to="/login" />;
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } catch (err) {
    console.error(err);
  }
  return null;
}

function Logout() {
  client.resetStore();
  client.cache.reset();
  try {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("firebaseToken");
    localStorage.removeItem("BrowserCompatibilityWarningPageShown");
    localStorage.removeItem(STATE_KEY);
    localStorage.removeItem("custom-domain");
  } catch (err) {
    console.error(err);
  }
  return <Redirect to="/" />;
}

/**
 * set up redux store
 * passing saved data as an initial on each store initializing (page loading)
 * e.g. keep data saved
 */
const { store } = configureReduxStore(readStorage());
export const configuredStore = store;

/*
 * Gets expiration time for the access token
 * and calculates the refresh interval
 * refresh interval =  tokenExpirationTime - 1 minute
 * */
const expirationToken = config.get("tokenExpiration");
const timeInterval = ms(expirationToken) <= 60000 ? ms(expirationToken) : ms(expirationToken) - 1 * 60000;

function App() {
  useEffect(() => {
    /**
     * defined watchers, list of reducers which data we want to keep across page reloading
     * defined listeners, list of reducers which were subscribed on update
     * defined storeUnsubscribeList, list of functions to unsubscribe
     */
    const watchers = reducersWhiteList.map((key) => watch(store.getState, key, isEqual));
    const listeners = watchers.map((w) => w(() => writeStorage(store.getState)));
    const storeUnsubscribeList = listeners.map((l) => store.subscribe(l));

    // unsubscribe on componentWillUnmount
    return () => storeUnsubscribeList.forEach((unsubscribe) => unsubscribe());
  }, []);

  useEffect(() => {
    if (localStorage.getItem("custom-domain") === null) {
      getConfigByUrl(host).then((data) => {
        const response = data.data;
        if (response != null) {
          domainStore.storeDomain(data.data);
        } else {
          domainStore.clearDomain();
        }
      });
    }
  }, [host]);

  useEffect(() => {
    // request a new token to the API
    const timer = setInterval(() => {
      refreshTokenAPI()
        .then((data) => {
          if (data && data.data.success) {
            const newToken = data.data.data.token;
            localStorage.setItem("token", newToken);
          }
        })
        .catch(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("firebaseToken");
          window.location = "/login";
        });
    }, timeInterval);
    // clearing interval
    return () => clearInterval(timer);
  });
  console.log(client);
  return (
    <ThemeProvider>
      <ApolloProvider client={client}>
        <ReduxProvider store={store}>
          <CombinedContextProvider>
            <QueryClientProvider client={queryClient}>
              <DocHead />
              <BrowserRouter>
                <React.Suspense fallback={loadingSpinner()}>
                  <Switch>
                    <Route path="/login" exact component={Signin} />
                    <Route path="/login/recovery-code" exact component={RecoveryCodePage} />
                    <Route path="/logout" exact component={Logout} />
                    <Route path="/register/:token" exact component={SignupPage} />
                    {/* Since we need this url to work without params */}
                    <Route path="/register" exact component={SignupPage} />
                    <Route path="/new-conf-email" exact component={SendNewEmail} />
                    <Route path="/forgot-password" exact component={ForgotPasswordPage} />
                    <Route path="/reset-password/:emailtoken" exact component={ResetPassword} />
                    {/* hiding route for production envs (ks,ki) */}
                    {process.env.REACT_APP_ENV.includes("production") ? null : (
                      <Route path="/custom-domain" exact component={CustomDomainPage} />
                    )}
                    <PrivateRoute path="/" component={DefaultLayout} />
                    <Route path="*" component={NotFound404} />
                  </Switch>
                  <ToastContainer autoClose={3000} hideProgressBar />
                </React.Suspense>
              </BrowserRouter>
              <ConnectedMsgDialog />
            </QueryClientProvider>
          </CombinedContextProvider>
        </ReduxProvider>
      </ApolloProvider>
    </ThemeProvider>
  );
}

export default App;
