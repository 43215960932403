import React from "react";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const TextInputInline = ({ groupClass, name, type, onChange, placeholder, value, icon, autocomplete, testId }) => {
  return (
    <>
      <InputGroup className={groupClass}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          type={type}
          name={name}
          placeholder={placeholder}
          autoComplete={autocomplete}
          onChange={onChange}
          value={value}
          data-test-id={testId}
        />
      </InputGroup>
    </>
  );
};

TextInputInline.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  autocomplete: PropTypes.string,
  groupClass: PropTypes.string,
};

export default TextInputInline;
