import config from "react-global-configuration";

export default function featureFlagsReducer(state = { ...config.get("featureFlags") }, { type }) {
  switch (type) {
    default:
      return state;
  }
}

export const getFeatureFlag = (state, flag) => !!state.featureFlags[flag];
export const getSomeFeatureFlags = (state, flag) => {
  if (Array.isArray(flag)) {
    return flag.map(eachFlag => state.featureFlags[eachFlag]).some(isEnabled => isEnabled);
  }
  return getFeatureFlag(state, flag);
};
