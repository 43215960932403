import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ImgPreviewSimvantageType } from "./imgPreviewSimvantageType";

const initialState: ImgPreviewSimvantageType = {
  processing: false,
  imgGcsUrl: null,
  error: false,
  errorMsg: null,
};

/**
 * Simvantage Image Preview Reducer
 */
const imgPreviewSimvantageSlice = createSlice({
  name: "imgPreviewSimvantage",
  initialState,
  reducers: {
    setProcessing: (state, action: PayloadAction<boolean>) => {
      state.processing = action.payload;
    },
    setPublicUrl: (state, action: PayloadAction<string | null>) => {
      state.imgGcsUrl = action.payload;
    },
    setError: (state, action: PayloadAction<{ error: boolean; msg: string }>) => {
      state.error = action.payload.error;
    },
    setFirebaseUpdate: (state, action: PayloadAction<{ data: ImgPreviewSimvantageType }>) => {
      state = { ...action.payload.data };
      return state;
    },
    resetSimvantageImgStore: (state) => {
      state = initialState;
      return state;
    },
  },
});

export const { setProcessing, setPublicUrl, setError, setFirebaseUpdate, resetSimvantageImgStore } = imgPreviewSimvantageSlice.actions;

export default imgPreviewSimvantageSlice.reducer;
