import React from "react";
import Recaptcha from "react-recaptcha";
import config from "react-global-configuration";

function Captcha(props) {
  const onloadCallback = () => {};

  /**
   *  for testcloud environments, a dev captcha key is required to disable
   *  extra validation when clicking Captcha
   */
  const sitekey = config.get("captchaKey");

  return (
    <div data-test-id="captcha" style={{ padding: "0.5em" }}>
      <center>
        <Recaptcha
          sitekey={sitekey}
          render="explicit"
          onloadCallback={onloadCallback}
          verifyCallback={props.verifyCallback}
        />
      </center>
    </div>
  );
}
export default Captcha;
