import React, { Suspense } from "react";
import config from "react-global-configuration";

import { GEODICT } from "../featureFlags";
import domainStore from "../helpers/kcc/DomainStore";
import { getKccTheme } from "../helpers/kcc/kccTheme";
import { GeodictTheme, DefaultTheme } from "./themes";

const featureFlags = config.get("featureFlags");
const isGeodictEnabled = featureFlags[[GEODICT]];

/**
 * Component that load the theme for the app, switches for kcc and given node_envs
 * @param {*} param0
 * @returns
 */
function ThemeProvider({ children }) {
  let theme = <DefaultTheme />;

  if (isGeodictEnabled) {
    theme = <GeodictTheme />;
  }

  const kccTheme = getKccTheme(domainStore.getDomain());

  // add kcc theme if corresponds
  if (kccTheme !== null) {
    theme = kccTheme;
  }

  return (
    <Suspense fallback={<div>Loading theme...</div>}>
      {theme}
      {children}
    </Suspense>
  );
}

export default ThemeProvider;
