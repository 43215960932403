import React, { useCallback, useState } from "react";
import { Redirect } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const ConfirmCreatedAccountModal = () => {
  const [redirect, setRedirect] = useState(false);
  // redirect page to /login
  const redirectPage = (e: any) => {
    e.preventDefault();
    setRedirect(true);
  };

  return (
    <>
      {redirect && <Redirect to="/login" />}
      <Modal className="modal-primary modal-dialog-centered" isOpen={true} backdrop="static">
        <ModalHeader>Account created</ModalHeader>
        <ModalBody>Your signup is completed. You can now login to the system using the button below.</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={redirectPage}>
            Back to login
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
export default ConfirmCreatedAccountModal;
