import { SUBSCRIBE_START, SUBSCRIBE_UPDATE, SUBSCRIBE_ERROR } from "./actions";

// heartbeat reducer
export default function heartBeatReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBE_START: {
      const { runId } = payload;
      return {
        ...state,
        [runId]: {
          error: null,
        },
      };
    }

    case SUBSCRIBE_UPDATE: {
      const { runId, heartbeat } = payload;
      return {
        ...state,
        [runId]: {
          error: null,
          ...heartbeat,
        },
      };
    }

    case SUBSCRIBE_ERROR: {
      const { runId, error } = payload;
      return {
        ...state,
        [runId]: {
          ...state[runId],
          error,
        },
      };
    }

    default:
      return state;
  }
}

// selectors
export const getHeartbeatByRunId = (state, runId) => state.heartbeat[runId];
