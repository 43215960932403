import React, { createContext, useReducer, useMemo } from "react";
import {
  DELETE_FILE,
  DISPLAY_GPU_COMPONENT,
  ENABLED_SESES,
  GENERAL_STATE_UPDATE,
  REMOVE_JSON_FILE,
  RESET_STATE,
  SET_CPU,
  SET_DEFAULT_JSON_PARAMETER,
  SET_DETAILS_FOR_EDITING,
  SET_EMAIL_NOTIFICATION,
  SET_FILES,
  SET_GPU_TYPE,
  SET_JSON_DATA,
  SET_JSON_PARAMETER,
  SET_MACHINE,
  SET_SLIDER_VALUE,
  SET_SOLVER_NAME,
  SET_SOFTWARE_LICENSE,
  SET_LICENSE_SERVER_NAME,
  EXPAND_ALL_ROWS_SIMVANTAGE,
  COLLAPSE_ALL_ROWS_SIMVANTAGE,
} from "./actionTypes";

// eslint-disable-next-line import/no-cycle
import { createCaseContextReducer } from "./createCaseReducer";
import { initialState } from "./inititalState";

export const createCaseContext = createContext(initialState);
export const { Provider, Consumer } = createCaseContext;

// action creators
export const setSesesEnabled = () => ({ type: ENABLED_SESES, payload: {} });
export const setMachineDetail = machineDetail => ({
  type: GENERAL_STATE_UPDATE,
  payload: { machineDetail },
});
export const setRedirectToProjectPage = () => ({
  type: GENERAL_STATE_UPDATE,
  payload: { redirectToProjectPage: true },
});
export const setErrors = errors => ({ type: GENERAL_STATE_UPDATE, payload: { errors } });
export const setSolverName = solverName => ({
  type: SET_SOLVER_NAME,
  payload: { solverName },
});
export const setScript = runScript => ({ type: GENERAL_STATE_UPDATE, payload: { runScript } });
export const setNumCPU = numCPUs => ({ type: SET_CPU, payload: { numCPUs } });
export const setDecomposeParDict = decomposeParDict => ({
  type: GENERAL_STATE_UPDATE,
  payload: { decomposeParDict },
});
export const setMachine = (id, cpu) => ({ type: SET_MACHINE, payload: { id, cpu } });
export const setParallel = parallel => ({ type: GENERAL_STATE_UPDATE, payload: { parallel } });
export const setCaseName = name => ({ type: GENERAL_STATE_UPDATE, payload: { name } });
export const setFiles = files => ({ type: SET_FILES, payload: { files } });
export const removeFile = fileName => ({ type: DELETE_FILE, payload: { fileName } });
export const setProjectDetails = (openFoam, projectId) => ({
  type: GENERAL_STATE_UPDATE,
  payload: { openFoam, projectId },
});
export const setCaseDetailsForEditing = caseData => {
  return {
    type: SET_DETAILS_FOR_EDITING,
    payload: {
      ...caseData,
      create: false,
    },
  };
};
export const setSliderValue = sliderValue => {
  return {
    type: SET_SLIDER_VALUE,
    payload: {
      sliderValue,
    },
  };
};
export const setGpuType = gpuType => ({
  type: SET_GPU_TYPE,
  payload: {
    gpuType,
  },
});
export const setEmailNotification = emailNotification => ({
  type: SET_EMAIL_NOTIFICATION,
  payload: {
    emailNotification,
  },
});
export const resetState = () => {
  return {
    type: RESET_STATE,
    payload: {},
  };
};
export const setJSONData = (data, file) => {
  return {
    type: SET_JSON_DATA,
    payload: { data, file },
  };
};

export const setDefaultJSONData = (data, file) => {
  return {
    type: SET_DEFAULT_JSON_PARAMETER,
    payload: { data, file },
  };
};

export const removeJsonFile = () => {
  return {
    type: REMOVE_JSON_FILE,
  };
};
export const setJSONParameter = (index, parameterIndex, value) => {
  return {
    type: SET_JSON_PARAMETER,
    payload: { index, parameterIndex, value },
  };
};

export const setDisplayGpuComponent = displayGpu => {
  return {
    type: DISPLAY_GPU_COMPONENT,
    payload: { displayGpu },
  };
};

export const setSoftwareLicense = softwareLicenseId => {
  return {
    type: SET_SOFTWARE_LICENSE,
    payload: { softwareLicenseId },
  };
};

export const setLicenseServerName = licenseServer => {
  return {
    type: SET_LICENSE_SERVER_NAME,
    payload: { licenseServer },
  };
};

export const setExpandAllRowsSimvantage = () => {
  return {
    type: EXPAND_ALL_ROWS_SIMVANTAGE,
    payload: {},
  };
};

export const setCollapseAllRowsSimvantage = () => {
  return {
    type: COLLAPSE_ALL_ROWS_SIMVANTAGE,
    payload: {},
  };
};

// value used to mock and override
export const CreateCaseProvider = ({ children, value }) => {
  const [state, dispatch] = useReducer(createCaseContextReducer, initialState);

  const wrappedActionCreators = {
    setMachineDetail: machineDetail => dispatch(setMachineDetail(machineDetail)),
    setRedirectToProjectPage: () => dispatch(setRedirectToProjectPage()),
    setErrors: errors => dispatch(setErrors(errors)),
    setSesesEnabled: () => dispatch(setSesesEnabled()),
    setSolverName: solverName => dispatch(setSolverName(solverName)),
    setScript: runScript => dispatch(setScript(runScript)),
    setNumCPU: numCPUs => dispatch(setNumCPU(numCPUs)),
    setDecomposeParDict: decomposeParDict => dispatch(setDecomposeParDict(decomposeParDict)),
    setMachine: (id, cpu) => dispatch(setMachine(id, cpu)),
    setParallel: parallel => dispatch(setParallel(parallel)),
    setCaseName: name => dispatch(setCaseName(name)),
    setFiles: files => dispatch(setFiles(files)),
    removeFile: fileName => dispatch(removeFile(fileName)),
    setProjectDetails: (openFoam, projectId) => dispatch(setProjectDetails(openFoam, projectId)),
    setCaseDetailsForEditing: caseData => dispatch(setCaseDetailsForEditing(caseData)),
    setSliderValue: sliderValue => dispatch(setSliderValue(sliderValue)),
    setGpuType: gpuType => dispatch(setGpuType(gpuType)),
    setDisplayGpuComponent: displayGpu => dispatch(setDisplayGpuComponent(displayGpu)),
    setSoftwareLicense: softwareLicenseId => dispatch(setSoftwareLicense(softwareLicenseId)),
    setEmailNotification: emailNotification => dispatch(setEmailNotification(emailNotification)),
    setJSONData: (data, file) => dispatch(setJSONData(data, file)),
    setJSONParameter: (index, parameterIndex, parameterValue) =>
      dispatch(setJSONParameter(index, parameterIndex, parameterValue)),
    setDefaultJSONData: (index, parameterIndex, parameterValue) =>
      dispatch(setDefaultJSONData(index, parameterIndex, parameterValue)),
    removeJsonFile: () => dispatch(removeJsonFile()),
    resetState: () => dispatch(resetState()),
    setLicenseServer: licenseServer => dispatch(setLicenseServerName(licenseServer)),
    setExpandAllRowsSimvantage: () => dispatch(setExpandAllRowsSimvantage()),
    setCollapseAllRowsSimvantage: () => dispatch(setCollapseAllRowsSimvantage()),
  };

  const contextValue = useMemo(() => ({ state, dispatch, ...wrappedActionCreators, ...value }), [
    state,
    dispatch,
    wrappedActionCreators,
    value,
  ]);

  return <Provider value={contextValue}>{children}</Provider>;
};
