/**
 * KCC urls for all the lite installations
 */
const kccUrls = {
  testEnv: "https://testkcc-dot-app-dot-kaleidosimtestenv.ew.r.appspot.com",
  simvantage_stage: "https://simvantage.app.kaleidosim-test.com",
  simvantage_prod: "https://app.simvantage.com",
  seses_stage: "https://seses.app.kaleidosim-test.com",
  seses_prod: "https://seses.app.computecluster.cloud",
  comsol: "https://comsol.app.kaleidosim.com",
  comsol_stage: "https://comsol.app.kaleidosim-test.com",
  simflow_stage: "https://simflow.app.kaleidosim-test.com",
  simflow: "https://simflow.app.kaleidosim.com",
  kistage: "https://geodict.app.kaleidosim-test.com",
  kiproduction: "https://geodict.kaleidosim.com",
};

/**
 * supported kcc domains with its service name
 */
const kccDomains = {
  simvantage: "simvantage",
  seses: "seses",
  comsol: "comsol",
  simflow: "simflow",
  geodict: "geodict",
};

/**
 * Check if an url belongs to KCC domains
 * @param {String} url
 * @returns
 */
function isKccUrl(url) {
  let isKccDomain = false;
  for (const key in kccUrls) {
    if (kccUrls[key] === url) {
      isKccDomain = true;
    }
  }
  return isKccDomain;
}

/**
 * function that return the desired kcc domain
 * this is used to load the theme and logo
 * @param {String} url
 */
function getKccDomain(url) {
  switch (url) {
    case kccUrls.simvantage_stage:
      return kccDomains.simvantage;
    case kccUrls.simvantage_prod:
      return kccDomains.simvantage;
    case kccUrls.seses_stage:
      return kccDomains.seses;
    case kccUrls.seses_prod:
      return kccDomains.seses;
    case kccUrls.comsol:
      return kccDomains.comsol;
    case kccUrls.comsol_stage:
      return kccDomains.comsol;
    case kccUrls.simflow_stage:
      return kccDomains.simflow;
    case kccUrls.simflow:
      return kccDomains.simflow;
    case kccUrls.kistage:
      return kccDomains.geodict;
    case kccUrls.kiproduction:
      return kccDomains.geodict;
  }
}

module.exports = { isKccUrl, kccUrls, getKccDomain, kccDomains };
