// action types
export const GET_GPU_LIST_START = "cases/GET_GPU_LIST_START";
export const GET_GPU_LIST_SUCCESS = "cases/GET_GPU_LIST_SUCCESS";
export const GET_GPU_LIST_FAILURE = "cases/GET_GPU_LIST_FAILURE";

// action creators
export const getGpuListStart = () => ({
  type: GET_GPU_LIST_START,
  payload: {},
});

export const getGpuListSuccess = gpuList => ({
  type: GET_GPU_LIST_SUCCESS,
  payload: { gpuList },
});

export const getGpuListFailure = error => ({
  type: GET_GPU_LIST_FAILURE,
  payload: { error },
});
