import React from "react";
import { Card, CardGroup, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

import ThemedLogo from "./themedLogo";

const LayoutLogo = () => {
  return (
    <Row className="justify-content-center mb-3 mt-3">
      <Col md="8">
        <CardGroup>
          <Card className="p-4">
            <Link to="/login">
              <StyledLogo className="mx-auto d-block register-logo" alt="Logo" />
            </Link>
          </Card>
        </CardGroup>
      </Col>
    </Row>
  );
};

const StyledLogo = styled(ThemedLogo)`
  width: 95%;
`;

export default LayoutLogo;
