import { SUBSCRIBE_UPDATE, SUBSCRIBE_ERROR } from "./actions";

const initialState = { organizationId: "", vmBalance: 0, error: "" };
// heartbeat reducer
export default function cpuBalanceReducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SUBSCRIBE_UPDATE: {
      const { organizationId, vmBalance } = payload;
      return {
        organizationId,
        vmBalance,
      };
    }

    case SUBSCRIBE_ERROR: {
      const { organizationId, error } = payload;
      return {
        ...state,
        organizationId,
        error,
      };
    }

    default:
      return state;
  }
}
