import { getStateDescription } from "../../libs/sm-constants/src/stateDescriptions";
import { frontendStates } from "../../libs/sm-constants/src/smStates";

import {
  UPDATE_CASES,
  SET_CASE_LOADING_STATE,
  SET_CASE_OPEN_STATE,
  SET_CASE_UPLOADING_STATE,
  GET_PROJECT_CASES_STATUS_START,
  GET_PROJECT_CASES_STATUS_SUCCESS,
  GET_PROJECT_CASES_STATUS_FAILURE,
} from "./actions";

const initialState = {
  getProjectCasesStatusLoading: false,
  projectCasesStatus: {},
};

const caseStatusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_CASES: {
      const { data } = payload;
      return {
        ...state,
        ...data.reduce((obj, item) => {
          // update only if its currently not in uploading state

          // eslint-disable-next-line no-param-reassign
          obj[item.caseId] = {
            ...getStateDescription(item.vmState),
            runId: item.runId,
            hasResults: item.hasResults || false,
          };

          return obj;
        }, {}),
      };
    }

    case SET_CASE_LOADING_STATE: {
      const { caseId } = payload;
      return {
        ...state,
        [caseId]: { ...getStateDescription(frontendStates.REQUESTSTATE) },
      };
    }

    case SET_CASE_UPLOADING_STATE: {
      const { caseId } = payload;
      return {
        ...state,
        [caseId]: { ...getStateDescription(frontendStates.UPLOADSTATE) },
      };
    }

    case SET_CASE_OPEN_STATE: {
      const { caseId } = payload;
      return {
        ...state,
        [caseId]: { ...getStateDescription(frontendStates.OPENSTATE) },
      };
    }

    case GET_PROJECT_CASES_STATUS_START: {
      return {
        ...state,
        getProjectCasesStatusLoading: true,
      };
    }

    case GET_PROJECT_CASES_STATUS_SUCCESS: {
      const { projectCasesStatus } = payload;
      return {
        ...state,
        projectCasesStatus,
        getProjectCasesStatusLoading: false,
      };
    }

    case GET_PROJECT_CASES_STATUS_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        getProjectCasesStatusLoading: false,
        getProjectCasesStatusError: error,
      };
    }

    default:
      return state;
  }
};

// selectors

export const getCaseStatus = (state, caseId) =>
  state.caseStatus[caseId] || { ...getStateDescription(frontendStates.INITIALSTATE) };
export const getCaseStatusState = (state, caseId) => state.caseStatus?.[caseId]?.state;

export const getRunId = (state, caseId) => state.caseStatus?.[caseId]?.runId;

export default caseStatusReducer;
