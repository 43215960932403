import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  dataFolderName,
  logsFolderName,
  reportsFolderName,
  videosFolderName,
} from "./simvantageFileCardConstants";

/**
 * Reducer file that contains the files
 * that are selected per every option displayed
 * in the Simulation Files card for Simvantage
 */
export interface CaseFileInfoSimvantage {
  selected: string[];
  totalSize: number;
  totalFiles: number;
}

export type AllCaseFiles = {
  projectId: string;
  projectName: string;
  caseId: string;
  _id: string;
  fileName: string;
  fileExt: string;
  filePath: string;
  fileSize: number;
  caseFileName: string;
  createdOn: string;
};

const initialState: { [key: string]: CaseFileInfoSimvantage } = {
  [reportsFolderName]: {
    selected: [], // selected report files
    totalSize: 0,
    totalFiles: 0,
  },
  [videosFolderName]: {
    selected: [], // selected videos
    totalSize: 0,
    totalFiles: 0,
  },
  [dataFolderName]: {
    selected: [], // selected data files
    totalSize: 0,
    totalFiles: 0,
  },
  [logsFolderName]: {
    selected: [], // selected log files
    totalSize: 0,
    totalFiles: 0,
  },
};

export const caseFilesSimvantage = createSlice({
  name: "caseFilesSimvantage",
  initialState,
  reducers: {
    updateSimvantageSelectedFiles: (state, action: PayloadAction<{ caseFiles: AllCaseFiles[] }>) => {
      // process here only once all the data for every folder
      const caseFilesData = action.payload.caseFiles;
      let newState = initialState;

      if (caseFilesData) {
        caseFilesData.forEach((fileValue: AllCaseFiles) => {
          if (fileValue.filePath.includes(`${reportsFolderName}/`)) {
            // Add to reports object state
            newState = {
              ...newState,
              [reportsFolderName]: {
                selected: newState[reportsFolderName].selected.concat(fileValue._id),
                totalFiles: newState[reportsFolderName].totalFiles + 1,
                totalSize: newState[reportsFolderName].totalSize + fileValue.fileSize * 1000,
              },
            };
          }
          if (fileValue.filePath.includes(`${videosFolderName}/`)) {
            // Add to videos object state
            newState = {
              ...newState,
              [videosFolderName]: {
                selected: newState[videosFolderName].selected.concat(fileValue._id),
                totalFiles: newState[videosFolderName].totalFiles + 1,
                totalSize: newState[videosFolderName].totalSize + fileValue.fileSize * 1000,
              },
            };
          }
          if (fileValue.filePath.includes(`${dataFolderName}/`)) {
            // Add to data object state
            newState = {
              ...newState,
              [dataFolderName]: {
                selected: newState[dataFolderName].selected.concat(fileValue._id),
                totalFiles: newState[dataFolderName].totalFiles + 1,
                totalSize: newState[dataFolderName].totalSize + fileValue.fileSize * 1000,
              },
            };
          }
          if (fileValue.filePath.includes(`${logsFolderName}/`)) {
            // Add to logs object state
            newState = {
              ...newState,
              [logsFolderName]: {
                selected: newState[logsFolderName].selected.concat(fileValue._id),
                totalFiles: newState[logsFolderName].totalFiles + 1,
                totalSize: newState[logsFolderName].totalSize + fileValue.fileSize * 1000,
              },
            };
          }
        });
      }

      return newState;
    },
  },
});

export const { updateSimvantageSelectedFiles } = caseFilesSimvantage.actions;

export default caseFilesSimvantage.reducer;
