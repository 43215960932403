// thunk types
export const GET_CASES_START = "cases/GET_CASES_START";
export const GET_CASES_SUCCESS = "cases/GET_CASES_SUCCESS";
export const GET_CASES_FAILURE = "cases/GET_CASES_FAILURE";

export const GET_TOTAL_NUMBER_OF_CASES_START = "cases/GET_TOTAL_NUMBER_OF_CASES_START";
export const GET_TOTAL_NUMBER_OF_CASES_SUCCESS = "cases/GET_TOTAL_NUMBER_OF_CASES_SUCCESS";
export const GET_TOTAL_NUMBER_OF_CASES_FAILURE = "cases/GET_TOTAL_NUMBER_OF_CASES_FAILURE";

export const GET_SINGLE_CASE_START = "cases/GET_SINGLE_CASE_START";
export const GET_SINGLE_CASE_SUCCESS = "cases/GET_SINGLE_CASE_SUCCESS";
export const GET_SINGLE_CASE_FAILURE = "cases/GET_SINGLE_CASE_FAILURE";

export const DELETE_CASES_START = "cases/DELETE_CASES_START";
export const DELETE_CASES_SUCCESS = "cases/DELETE_CASES_SUCCESS";
export const DELETE_CASES_FAILURE = "cases/DELETE_CASES_FAILURE";

export const PROCESS_AIBAT_CASE_START = "cases/PROCESS_AIBAT_CASE_START";
export const PROCESS_AIBAT_CASE_SUCCESS = "cases/PROCESS_AIBAT_CASE_SUCCESS";
export const PROCESS_AIBAT_CASE_FAILURE = "cases/PROCESS_AIBAT_CASE_FAILURE";

export const SET_CURRENT_PAGE = "cases/SET_CURRENT_PAGE";

export const REMOVE_FROM_LIST = "cases/REMOVE_FROM_LIST";

export const RESET_TABLE_LIST = "cases/RESET_TABLE_LIST";

// thunk action creators
export const getCasesStart = () => ({
  type: GET_CASES_START,
  payload: {},
});

export const getCasesSuccess = list => ({
  type: GET_CASES_SUCCESS,
  payload: { list },
});

export const getCasesFailure = error => ({
  type: GET_CASES_FAILURE,
  payload: { error },
});

// thunk action creators
export const getTotalNumberOfCasesStart = () => ({
  type: GET_TOTAL_NUMBER_OF_CASES_START,
  payload: {},
});

export const getTotalNumberOfCasesSuccess = totalNumberOfCases => ({
  type: GET_TOTAL_NUMBER_OF_CASES_SUCCESS,
  payload: { totalNumberOfCases },
});

export const getTotalNumberOfCasesFailure = error => ({
  type: GET_TOTAL_NUMBER_OF_CASES_FAILURE,
  payload: { error },
});

export const getSingleCaseStart = () => ({
  type: GET_SINGLE_CASE_START,
  payload: {},
});

export const getSingleCaseSuccess = singleCase => ({
  type: GET_SINGLE_CASE_SUCCESS,
  payload: { singleCase },
});

export const getSingleCaseFailure = error => ({
  type: GET_SINGLE_CASE_FAILURE,
  payload: { error },
});

export const deleteCasesStart = caseIds => ({
  type: DELETE_CASES_START,
  payload: { caseIds },
});

export const deleteCasesSuccess = () => ({
  type: DELETE_CASES_SUCCESS,
  payload: {},
});

export const deleteCasesFailure = error => ({
  type: DELETE_CASES_FAILURE,
  payload: { error },
});

export const processAibatCaseStart = () => ({
  type: PROCESS_AIBAT_CASE_START,
  payload: {},
});

export const processAibatCaseSuccess = () => ({
  type: PROCESS_AIBAT_CASE_SUCCESS,
  payload: {},
});

export const processAibatCaseFailure = error => ({
  type: PROCESS_AIBAT_CASE_FAILURE,
  payload: { error },
});

export const removeFromList = caseIds => ({
  type: REMOVE_FROM_LIST,
  payload: { caseIds },
});

export const setCurrentPage = currentPageNumber => ({
  type: SET_CURRENT_PAGE,
  payload: { currentPageNumber },
});

export const resetTableList = () => ({
  type: RESET_TABLE_LIST,
  payload: {},
});
