import {
  START_FETCH_INSTANT_LOG_FILE,
  SUCCESS_FETCH_INSTANT_LOG_FILE,
  FAILURE_FETCH_INSTANT_LOG_FILE,
  START_POLL_INSTANT_LOG_FILE,
  STOP_POLL_INSTANT_LOG_FILE,
  START_CREATION_OF_INSTANT_LOG_FILE,
  SUCCESS_CREATION_OF_INSTANT_LOG_FILE,
  FAILURE_CREATION_OF_INSTANT_LOG_FILE,
} from "./actions";
import { InstantLogFileType } from "./logFileTypes";

/* initial state */
export const initialState: InstantLogFileType = {
  logFileId: "",
  uploadCompleted: false,
  loading: false,
  startPolling: false,
};

export default function reducer(state = initialState, action: { type: string; payload: any }) {
  const { type, payload } = action;
  switch (type) {
    case START_FETCH_INSTANT_LOG_FILE: {
      return { ...state, loading: true };
    }
    case SUCCESS_FETCH_INSTANT_LOG_FILE: {
      const data = payload.logFileData;
      return { ...data, loading: false };
    }
    case FAILURE_FETCH_INSTANT_LOG_FILE: {
      const errorMsg = payload.errorMessage;
      return { ...state, loading: false, startPolling: false };
    }
    case START_CREATION_OF_INSTANT_LOG_FILE: {
      return { ...state, loading: true };
    }
    case SUCCESS_CREATION_OF_INSTANT_LOG_FILE: {
      const logFileData = payload.data;
      return { ...logFileData, startPolling: true, loading: true };
    }
    case FAILURE_CREATION_OF_INSTANT_LOG_FILE: {
      const errorMsg = payload.errorMessage;
      return { ...state, loading: false, startPolling: false };
    }
    case START_POLL_INSTANT_LOG_FILE: {
      return { ...state, startPolling: true };
    }
    case STOP_POLL_INSTANT_LOG_FILE: {
      return { ...state, startPolling: false };
    }

    default:
      return state;
  }
}
