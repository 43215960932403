// actions types
export const SET_SOME_FIELDS = "newExperiment/SET_SOME_FIELDS";
export const SET_EXPERIMENT_CASE_NAME = "newExperiment/SET_EXPERIMENT_CASE_NAME";
export const SET_EXPERIMENT_SOLVER_NAME = "newExperiment/SET_EXPERIMENT_SOLVER_NAME";
export const SET_EXPERIMENT_MACHINE = "newExperiment/SET_EXPERIMENT_MACHINE";
export const SET_DECOMPOSE_TO = "newExperiment/SET_DECOMPOSE_TO";
export const SET_RUN_SCRIPT = "newExperiment/SET_RUN_SCRIPT";
export const SET_DECOMPOSE_PAR_DICT = "newExperiment/SET_DECOMPOSE_PAR_DICT";
export const SET_EXPERIMENT_PARAMETERS = "newExperiment/SET_EXPERIMENT_PARAMETERS";
export const SET_EXPERIMENT_PARAMETERS_VALID = "newExperiment/SET_EXPERIMENT_PARAMETERS_VALID";
export const SET_PARAMETERS_ITERATION = "newExperiment/SET_PARAMETERS_ITERATION";
export const SET_RECEIVE_EMAILS = "newExperiment/SET_RECEIVE_EMAILS";
export const SET_FILE_UPLOAD_PROGRESS = "newExperiment/SET_FILE_UPLOAD_PROGRESS";
export const SET_CASE_INPUT_PARAM = "newExperiment/SET_CASE_INPUT_PARAM";
export const SET_CASE_INPUT_PARAM_VALID = "newExperiment/SET_CASE_INPUT_PARAM_VALID";
export const RESET_STATE = "newExperiment/RESET_STATE";

export const REMOVE_PARAMETERS_ROW = "newExperiment/REMOVE_PARAMETERS_ROW";
export const ADD_FILE = "newExperiment/ADD_FILE";
export const REMOVE_FILE = "newExperiment/REMOVE_FILE";

export const GET_SOLVERS_START = "newExperiment/GET_SOLVERS_START";
export const GET_SOLVERS_SUCCESS = "newExperiment/GET_SOLVERS_SUCCESS";
export const GET_SOLVERS_FAILURE = "newExperiment/GET_SOLVERS_FAILURE";

export const GET_MACHINES_START = "newExperiment/GET_MACHINES_START";
export const GET_MACHINES_SUCCESS = "newExperiment/GET_MACHINES_SUCCESS";
export const GET_MACHINES_FAILURE = "newExperiment/GET_MACHINES_FAILURE";

export const CREATE_NEW_EXPERIMENT_START = "newExperiment/CREATE_NEW_EXPERIMENT_START";
export const CREATE_NEW_EXPERIMENT_SUCCESS = "newExperiment/CREATE_NEW_EXPERIMENT_SUCCESS";
export const CREATE_NEW_EXPERIMENT_FAILURE = "newExperiment/CREATE_NEW_EXPERIMENT_FAILURE";

export const CREATE_NEW_CASE_START = "newExperiment/CREATE_NEW_CASE_START";
export const CREATE_NEW_CASE_SUCCESS = "newExperiment/CREATE_NEW_CASE_SUCCESS";
export const CREATE_NEW_CASE_FAILURE = "newExperiment/CREATE_NEW_CASE_FAILURE";

// actions for new experiment page
export const setSomeFields = data => ({
  type: SET_SOME_FIELDS,
  payload: { data },
});

export const setExperimentSolverName = (solverName, isSesesEnabled) => ({
  type: SET_EXPERIMENT_SOLVER_NAME,
  payload: { solverName, isSesesEnabled },
});

export const setExperimentCaseName = caseName => ({
  type: SET_EXPERIMENT_CASE_NAME,
  payload: { caseName },
});

export const setExperimentMachine = (machine, isSesesEnabled) => ({
  type: SET_EXPERIMENT_MACHINE,
  payload: { machine, isSesesEnabled },
});

export const setDecomposeTo = (decomposeTo, isSesesEnabled) => ({
  type: SET_DECOMPOSE_TO,
  payload: { decomposeTo, isSesesEnabled },
});

export const setRunScript = (runScript, isSesesEnabled) => ({
  type: SET_RUN_SCRIPT,
  payload: { runScript, isSesesEnabled },
});

export const setDecomposeParDict = decomposeParDict => ({
  type: SET_DECOMPOSE_PAR_DICT,
  payload: { decomposeParDict },
});

export const setExperimentParameters = experimentParameters => ({
  type: SET_EXPERIMENT_PARAMETERS,
  payload: { experimentParameters },
});

export const setExperimentParametersValid = experimentParamsValid => ({
  type: SET_EXPERIMENT_PARAMETERS_VALID,
  payload: { experimentParamsValid },
});

export const setParametersIteration = iterationType => ({
  type: SET_PARAMETERS_ITERATION,
  payload: { iterationType },
});

export const setReceiveEmails = receiveEmails => ({
  type: SET_RECEIVE_EMAILS,
  payload: { receiveEmails },
});

export const setFileUploadProgress = fileUploadProgress => ({
  type: SET_FILE_UPLOAD_PROGRESS,
  payload: { fileUploadProgress },
});

export const setCaseInputParam = param => ({
  type: SET_CASE_INPUT_PARAM,
  payload: { param },
});

export const setCaseInputParamValid = valid => ({
  type: SET_CASE_INPUT_PARAM_VALID,
  payload: { valid },
});

export const resetState = () => ({
  type: RESET_STATE,
  payload: {},
});

export const removeParametersRow = index => ({
  type: REMOVE_PARAMETERS_ROW,
  payload: { index },
});

export const addFile = files => ({
  type: ADD_FILE,
  payload: { files },
});

export const removeFile = () => ({
  type: REMOVE_FILE,
  payload: {},
});

export const getSolversStart = () => ({
  type: GET_SOLVERS_START,
  payload: {},
});

export const getSolversSuccess = data => ({
  type: GET_SOLVERS_SUCCESS,
  payload: { data },
});

export const getSolversError = error => ({
  type: GET_SOLVERS_FAILURE,
  payload: { error },
});

export const getMachinesStart = () => ({
  type: GET_MACHINES_START,
  payload: {},
});

export const getMachinesSuccess = data => ({
  type: GET_MACHINES_SUCCESS,
  payload: { data },
});

export const getMachinesError = error => ({
  type: GET_MACHINES_FAILURE,
  payload: { error },
});

export const createNewExperimentStart = () => ({
  type: CREATE_NEW_EXPERIMENT_START,
  payload: {},
});

export const createNewExperimentSuccess = () => ({
  type: CREATE_NEW_EXPERIMENT_SUCCESS,
  payload: {},
});

export const createNewExperimentError = error => ({
  type: CREATE_NEW_EXPERIMENT_FAILURE,
  payload: { error },
});

export const createNewCaseStart = () => ({
  type: CREATE_NEW_CASE_START,
  payload: {},
});

export const createNewCaseSuccess = () => ({
  type: CREATE_NEW_CASE_SUCCESS,
  payload: {},
});

export const createNewCaseFailure = error => ({
  type: CREATE_NEW_CASE_FAILURE,
  payload: { error },
});
