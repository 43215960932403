import { transformToParamSet } from "../../helpers/json";
import {
  DELETE_FILE,
  DISPLAY_GPU_COMPONENT,
  ENABLED_SESES,
  GENERAL_STATE_UPDATE,
  REMOVE_JSON_FILE,
  RESET_STATE,
  SET_CPU,
  SET_DEFAULT_JSON_PARAMETER,
  SET_DETAILS_FOR_EDITING,
  SET_EMAIL_NOTIFICATION,
  SET_FILES,
  SET_GPU_TYPE,
  SET_JSON_DATA,
  SET_JSON_PARAMETER,
  SET_MACHINE,
  SET_SLIDER_VALUE,
  SET_SOLVER_NAME,
  SET_SOFTWARE_LICENSE,
  SET_LICENSE_SERVER_NAME,
  EXPAND_ALL_ROWS_SIMVANTAGE,
  COLLAPSE_ALL_ROWS_SIMVANTAGE,
} from "./actionTypes";
import { initialState } from "./inititalState";
import { getIsParallel, getRunScriptValue } from "./helpers";
import setAibatParameters from "./utils/setAibatJsonParameters";
import { setCpuAndRunScript } from "./utils/setCpuAndRunScript";
import setSolverAndRunScript from "./utils/setSolverAndRunScript";
import validateFile from "./validateFile";
import { updateValueInJson } from "./utils/updateValueInJson";
import { DEFAULT_AIBAT_JSON_FILE } from "../../constants/aibat/aibatJsonFile";

/**
 * Create case reducer function
 * @param {object} state current state
 * @param {object} action dispatched action
 * @returns {object} new state
 */

export const createCaseContextReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case GENERAL_STATE_UPDATE:
      return { ...state, ...payload };
    case SET_DETAILS_FOR_EDITING:
      return {
        ...state,
        ...payload,
        machine: payload.machineDetail._id,
        gpuType: { ...payload.gpuDetails },
      };
    case SET_MACHINE:
      const { id, cpu } = payload;
      return { ...state, machine: id, decomposeParDict: cpu <= 1 ? "" : state.decomposeParDict };
    case SET_CPU: {
      return setCpuAndRunScript(payload, state);
    }
    case ENABLED_SESES: {
      return { ...state, isSesesEnabled: true };
    }

    case SET_FILES:
      const { files } = payload;
      // validate each file to be .zip
      const allFilesAreValid = files.every(file => validateFile(file.name));
      if (!allFilesAreValid) {
        return {
          ...state,
          files: [],
          errors: {
            ...state.errors,
            files: "Only .zip files are allowed.",
          },
        };
      }
      return {
        ...state,
        files,
        errors: {
          ...state.errors, // keep other errors
          files: "", // reset files errors
        },
      };
    case DELETE_FILE:
      const { fileName } = payload;
      if (state.files.length > 0) {
        const newFileArray = state.files.slice();
        const fileIndex = newFileArray.findIndex(file => file.name === fileName);
        if (fileIndex !== -1) {
          newFileArray.splice(fileIndex, 1);
          return { ...state, files: newFileArray };
        }
        return { ...state, files: newFileArray };
      }
      return state;
    case SET_SLIDER_VALUE: {
      const { sliderValue } = payload;
      const { numCPUs, solverName } = state;
      const parallel = getIsParallel(sliderValue, numCPUs);
      const runScript = getRunScriptValue(solverName, numCPUs, sliderValue, parallel, state.runScript);
      return { ...state, sliderValue, parallel, runScript };
    }

    case SET_SOLVER_NAME: {
      return setSolverAndRunScript(payload, state);
    }
    case SET_GPU_TYPE: {
      const { gpuType } = payload;
      return { ...state, gpuType };
    }
    case SET_EMAIL_NOTIFICATION: {
      const { emailNotification } = payload;
      return { ...state, emailNotification };
    }
    case SET_JSON_DATA:
      // when the user upload a new file
      const { data: jsonData, file } = payload;

      const { jsonParameters, differences } = setAibatParameters(state.json.data, jsonData);

      return {
        ...state,
        json: {
          ...state.json,
          data: jsonParameters,
          parameterItems: jsonParameters && transformToParamSet(jsonParameters),
          jsonDifferences: differences,
          displayDifferencesInJson: Object.keys(differences).length > 0,
          expandAllRows: true,
          file: {
            ...file,
            // overwriting json name to reactor parameters
            name: DEFAULT_AIBAT_JSON_FILE,
          },
        },
      };
    case SET_DEFAULT_JSON_PARAMETER:
      const { data: jsonDefaultData, file: defaultFile } = payload;

      // if not file was uploaded
      if (!state.json.file) {
        return {
          ...state,
          json: {
            data: jsonDefaultData,
            parameterItems: jsonDefaultData && transformToParamSet(jsonDefaultData),
            file: defaultFile,
            defaultJson: jsonDefaultData,
          },
        };
      }

      // if a new file was uploaded
      const newDefaultJsonParameters = setAibatParameters(jsonDefaultData, state.json);

      return {
        ...state,
        json: {
          ...state.json,
          defaultJson: newDefaultJsonParameters.jsonParameters,
          expandAllRows: true,
        },
      };

    case REMOVE_JSON_FILE:
      const defaultJsonValue = state.json.defaultJson;
      return {
        ...state,
        json: {
          data: defaultJsonValue,
          parameterItems: defaultJsonValue && transformToParamSet(defaultJsonValue),
          defaultJson: defaultJsonValue,
          file: { name: DEFAULT_AIBAT_JSON_FILE },
          jsonDifferences: {},
          displayDifferencesInJson: false,
        },
      };
    case SET_JSON_PARAMETER:
      const { index, parameterIndex, value } = payload;

      const newJson = updateValueInJson(value, index, parameterIndex, state);

      return {
        ...state,
        json: {
          ...state.json,
          data: newJson,
          parameterItems: state.json.parameterItems.map((item, i) => {
            if (i === index) {
              return {
                ...item,
                parameters: item.parameters.map((parameter, currentParameterIndex) => {
                  if (currentParameterIndex === parameterIndex) {
                    return {
                      ...parameter,
                      value,
                    };
                  }

                  return parameter;
                }),
              };
            }

            return item;
          }),
        },
      };
    case EXPAND_ALL_ROWS_SIMVANTAGE:
      return { ...state, json: { ...state.json, expandAllRows: true } };
    case COLLAPSE_ALL_ROWS_SIMVANTAGE:
      return { ...state, json: { ...state.json, expandAllRows: false } };
    case DISPLAY_GPU_COMPONENT:
      return {
        ...state,
        displayGpuCard: payload.displayGpu,
      };
    case SET_SOFTWARE_LICENSE:
      return {
        ...state,
        softwareLicenseId: payload.softwareLicenseId,
      };

    case SET_LICENSE_SERVER_NAME:
      return {
        ...state,
        licenseServer: payload.licenseServer,
      };
    case RESET_STATE:
      return { ...initialState };
    default:
      throw new Error();
  }
};
