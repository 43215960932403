const { convertPathToTreeCheckbox } = require("../../components/projects/download-files/utils/buildTreeCheckboxNodes");

export const formatCommonFilesForTreeCheckbox = caseFiles => {
  // prepare object for common case files
  const formatedCaseFiles = caseFiles.map(caseFile => {
    return {
      _id: caseFile.fileIds,
      caseFileName: "Common case files",
      filePath: caseFile.filePath,
      projectName: caseFile.projectName,
      fileSize: caseFile.fileSize,
    };
  });
  // convertPathToTreeCheckbox
  const treeDataNodes = convertPathToTreeCheckbox([formatedCaseFiles]);
  return treeDataNodes;
};

/**
 * Returns the total size for the selected files
 * @param {Array} selectedFiles
 * @param {Array} files
 */
export const getTotalFileSize = (selectedFiles, files) => {
  let totalSize = 0;
  selectedFiles.forEach(fileId => {
    const fileData = files.find(file => file._id === fileId);
    // totalSize in bytes
    totalSize += fileData.fileSize * 1000;
  });

  return totalSize;
};

/**
 * Returns the total size & the total files
 * for file extensions
 * @param {Array} selectedFiles
 * @param {Array} files
 */
export const getTotalFileExtensions = (selectedExtensions, files) => {
  let totalFileSize = 0;
  let totalFiles = 0;

  selectedExtensions.forEach(extension => {
    const extObject = files.find(ext => ext.fileExt === extension);
    // totalSize in bytes
    totalFileSize += extObject.fileSize * 1000;
    totalFiles += extObject.numberOfFiles;
  });

  const response = { totalFiles, totalFileSize };
  return response;
};
