import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import { toast } from "react-toastify";
import getRequest, { postRequest } from "../../axios";
import SelectInput from "../common/SelectInput";
import Spinner from "../common/Spinner";
import domainStore from "../../helpers/kcc/DomainStore";

/**
 * Page used for dev purposes to change to a specific KCC domain
 * @returns
 */
function CustomDomainPage() {
  const history = useHistory();
  const [kccDomain, setKccDomain] = useState("");
  const [kccDomainList, setKccDomainList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getRequest(`/domain-config/list`);
      setKccDomainList(data.data);
    };
    fetchData();
  }, []);

  useEffect(() => {
    localStorage.removeItem("custom-domain");
  });

  const resetToKsDomain = () => {
    domainStore.clearDomain();
    localStorage.removeItem("custom-domain");
    history.push("/login");
  };

  const changeToKccDomain = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const data = await postRequest("/custom-domain-config/", { _id: kccDomain });
      const response = data.data;
      if (response != null) {
        domainStore.storeDomain(data.data);
        localStorage.setItem("custom-domain", "customDomain");
      } else {
        domainStore.clearDomain();
      }

      if (response !== null) {
        toast.success("Changed to selected kcc domain!");
        history.push("/login");
      }
    } catch (err) {
      toast.error("Error changing to kcc domain, please try again");
    }
  };

  if (kccDomainList.length === 0) {
    return <Spinner />;
  }

  return (
    <Card className="card-resources">
      <CardHeader>KCC Domains</CardHeader>
      <CardBody>
        <SelectInput
          name="kccDomain"
          label={"Select Domain"}
          value={kccDomain}
          defaultOption={"Select a custom domain"}
          options={kccDomainList.map(kccDomain => {
            return {
              value: kccDomain["_id"],
              text: kccDomain["domain"],
            };
          })}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const id = e.target.value;
            setKccDomain(id);
          }}
          data-test-id="kccdomain-select"
          className={undefined}
          error={null}
        />
      </CardBody>
      <CardFooter>
        <Button active onClick={resetToKsDomain}>
          Reset to KS
        </Button>
        <Button
          className="swap-domain"
          type="submit"
          color="primary"
          data-test-id="changedomain-button"
          onClick={changeToKccDomain}
        >
          Change
        </Button>
      </CardFooter>
    </Card>
  );
}

export default CustomDomainPage;
