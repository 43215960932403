import { set } from "lodash";
import { CreateCaseContextType } from "../inititalState";

/**
 * Update an specific field in the json paramater file
 * used for running simvantage simulations
 * if the value is boolean, we just return it
 * if the value is string, we validate if the value is numeric or not
 * @param value
 * @param index
 * @param parameterIndex
 * @param state
 * @returns
 */
export const updateValueInJson = (value: any, index: number, parameterIndex: number, state: CreateCaseContextType) => {
  try {
    const editingParameter = state.json.parameterItems[index].parameters[parameterIndex];
    const jsonDataCloned = { ...state.json.data };

    if (Array.isArray(value)) {
      value.forEach(valueItem => {
        let val = valueItem.value;

        // validate only string types so we can properly parse the value to Float if necessary
        if (typeof valueItem.value === "string") {
          const match = valueItem.value.match(/^(\d*\.?\d+)$/);

          if (match) {
            // return the value as string
            val = parseFloat(valueItem.value);
          }
        }
        return set(jsonDataCloned, valueItem.pathToJSONKey, val);
      });
    } else {
      let val = value;

      // validate only string types so we can properly parse the value to Float if necessary
      if (typeof value === "string") {
        const match = value.match(/^(\d*\.?\d+)$/);

        if (match) {
          // return the value as string
          val = parseFloat(value);
        }
      }

      set(jsonDataCloned, editingParameter.pathToJSONKey, val);
    }

    return jsonDataCloned;
  } catch (e) {
    console.error(e); // eslint-disable-line
    return state.json.data;
  }
};
