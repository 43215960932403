export const STATE_KEY = "ks-ui-state";

/**
 * list of reducers, which data we want to save in local storage
 * and keep across page reloading
 */
export const reducersWhiteList = ["projects"];

export const writeStorage = getState => {
  // leave only those reducers, which are in whitelist
  const filtered = Object.entries(getState()).reduce((memo, [key, value]) => {
    if (reducersWhiteList.includes(key)) {
      return {
        ...memo,
        [key]: value,
      };
    }

    return memo;
  }, {});
  const state = JSON.stringify(filtered);

  // save to localStorage
  window.localStorage.setItem(STATE_KEY, state);
};

export const readStorage = () => {
  let state = {};
  try {
    // get from localStorage
    state = JSON.parse(window.localStorage.getItem(STATE_KEY)) || "{}";
  } catch (err) {
    // saved data is not valid
    // eslint-disable-next-line no-console
    console.info(err, "localStorage data corrupted");
  }

  return state;
};
