import reactorImg from "../assets/img/tips/reactor_diameter.png";
import name from "../assets/img/tips/Name.jpg";
import cuboidReactorReactorLengthX from "../assets/img/tips/Cuboid reactor Reactor length X [m].jpg";
import cuboidReactorReactorLengthY from "../assets/img/tips/Cuboid reactor Reactor length Y [m].jpg";
import reactorDiameter from "../assets/img/tips/Reactor diameter [m].jpg";
import brothWeight from "../assets/img/tips/Broth weight [kg].jpg";
import calculationNodesPerMeter from "../assets/img/tips/Calculation nodes per meter [-].jpg";
import produce3DoutputDataEach from "../assets/img/tips/Produce 3D output data each [s].jpg";
import produce2DoutputDataEach from "../assets/img/tips/Produce 2D output data each [s].jpg";
import produceTextoutputDataEach from "../assets/img/tips/Produce text output each [s].jpg";
import stopSimulationAfter from "../assets/img/tips/Stop simulation after [s].jpg";
import stopSimulationEarlier from "../assets/img/tips/Stop simulation earlier when substrate is fully mixed [truefalse].jpg";
import mixingQualityAtWhichSimulationStopsAutomatically from "../assets/img/tips/Mixing quality at which simulation stops automatically.jpg";
import liquidDensity from "../assets/img/tips/Liquid density [kgmⁿ].jpg";
import powerLawCoefficient from "../assets/img/tips/Power law coefficient [kg s^(n-2)m].jpg";
import powerLawIndex from "../assets/img/tips/Power law index n [-].jpg";
import kloepperhead from "../assets/img/tips/Kloepperhead [truefalse].jpg";
import gasSpargerHeight from "../assets/img/tips/Gas sparger height [m].jpg";
import gastSpargerDiameter from "../assets/img/tips/Gas sparger diameter [m].jpg";
import ringSpargerHeight from "../assets/img/tips/Ring sparger height [m].jpg";
import gasSpargerXdirection from "../assets/img/tips/Gas sparger offset in x-direction [m].jpg";
import gasSpargerYdirection from "../assets/img/tips/Gas sparger offset in y-direction [m].jpg";
import shaftDiameter from "../assets/img/tips/Shaft diameter [m].jpg";
import verticalDistanceOfShaftBottomToReactorBottom from "../assets/img/tips/Vertical distance of shaft bottom to reactor bottom [m].jpg";
import verticalDistanceOfShaftTopToReactorBottom from "../assets/img/tips/Vertical distance of shaft top to reactor bottom [m].jpg";
import offsetShaftBottomXdirection from "../assets/img/tips/Offset of the shaft bottom in x-direction [m].jpg";
import offsetShaftBottomYdirection from "../assets/img/tips/Offset of the shaft bottom in y-direction [m].jpg";
import offsetShaftTopXdirection from "../assets/img/tips/Offset of the shaft top in x-direction [m].jpg";
import offsetShaftTopYdirection from "../assets/img/tips/Offset of the shaft top in y-direction [m].jpg";
import baffleWidth from "../assets/img/tips/Baffle width [m].jpg";
import baffleGapToReactor from "../assets/img/tips/Baffle gap to reactor wall [m].jpg";
import numberOfBaffles from "../assets/img/tips/Number of baffles [-].jpg";
import distanceOfBaffleLowerEndToTankBottom from "../assets/img/tips/Distance of baffle lower end to tank bottom [m].jpg";
import stirrerSpeed from "../assets/img/tips/Stirrer speed [revmin].jpg";
import firstStirrer from "../assets/img/tips/First stirrer [truefalse].jpg";
import fStirrerDistanceAlongShaftFromShaftBottom from "../assets/img/tips/First stirrer distance along shaft from shaft bottom [m].jpg";
import fStirrerDiskDiameter from "../assets/img/tips/First stirrer disk diameter [m].jpg";
import fStirrerBladeOuterDiameter from "../assets/img/tips/First stirrer blade outer diameter [m].jpg";
import fStirrerBladeInnerDiameter from "../assets/img/tips/First stirrer blade inner diameter [m].jpg";
import fStirrerBladeHeigh from "../assets/img/tips/First stirrer blade height (true blade height at angle) [m].jpg";
import fStirrerBladeThickness from "../assets/img/tips/First stirrer blade thickness [m].jpg";
import fStirrerDiscThickness from "../assets/img/tips/First stirrer disc thickness [m].jpg";
import fStirrerBladeAngle from "../assets/img/tips/First stirrer blade angle (positive angle for downpumping) [°].jpg";
import fStirrerBladeSpread from "../assets/img/tips/First stirrer blade spread (if 0.0 = Elephant ear) [°].jpg";
import fStirrerBladeNumber from "../assets/img/tips/First stirrer blade number [-].jpg";
import fStirrerLevmixer from "../assets/img/tips/First stirrer is a Levmixer [truefalse].jpg";
import fStirrerEdgeHeigh from "../assets/img/tips/First levmixer edge height [m].jpg";
import fStirrerBladeOuterAngle from "../assets/img/tips/First stirrer blade outer angle (if 0.0 = Intermig) [°].jpg";
import fStirrerBladesVerticalSeparationDistance from "../assets/img/tips/First stirrer outer blades vertical separation distance (Intermig) [m].jpg";
import fStirrerOuterBladesHeigh from "../assets/img/tips/First stirrer outer blades height (Intermig) [m].jpg";
import fStirrerHalfCircleRadius from "../assets/img/tips/First stirrer half circle radius (if 0.0 = Propeller) [m].jpg";
import fStirrerConeTipeAxisDistance from "../assets/img/tips/First stirrer cone tip to axis distance (Propeller) [m].jpg";
import fStirrerInitialTurningAngle from "../assets/img/tips/First stirrer initial turning angle [°].jpg";
import sensor1 from "../assets/img/tips/Sensor 1 [truefalse].jpg";
import sensor1CircleDiamterMiddlePoint from "../assets/img/tips/Circle diameter of sensor 1 middle point [m].jpg";
import sensor1AngleMiddlePoint from "../assets/img/tips/Angle of sensor 1 middle point [°].jpg";
import sensor1DistanceReactorBottom from "../assets/img/tips/Distance of sensor 1 to reactor bottom [m].jpg";
import sensor1Diameter from "../assets/img/tips/Diameter of sensor 1 [m].jpg";
import substrate1Transport from "../assets/img/tips/Substrate 1 transport [truefalse].jpg";
import radiousOfSubstrate1DischargePoint from "../assets/img/tips/Radius of substrate 1 discharge point [m].jpg";
import angleOfSubstrate1DischargePoint from "../assets/img/tips/Angle of substrate 1 discharge point [°].jpg";
import heighOfSubstrate1DischargePoint from "../assets/img/tips/Height of substrate 1 discharge point [m].jpg";
import substrate1Density from "../assets/img/tips/Substrate 1 density [kgmⁿ].jpg";
import substrate1DischargeRate from "../assets/img/tips/Substrate 1 discharge rate [kgh].jpg";
import substrate1DischargeConcentration from "../assets/img/tips/Substrate 1 discharge concentration [kgkg].jpg";
import substrate1DischargeAmount from "../assets/img/tips/Substrate 1 discharge amount [kg].jpg";
import substrate1DischargeStartTime from "../assets/img/tips/Substrate 1 discharge start time [s].jpg";
import substrate1SplitTransport from "../assets/img/tips/Substrate 1 split transport [truefalse].jpg";
import angleOfSubstrate1SplitDischargePoint from "../assets/img/tips/Angle of substrate 1 discharge point [°].jpg";
import enablePelletsCalculation from "../assets/img/tips/Enable pellets calculation [truefalse].jpg";
import pelletReleaseStartTime from "../assets/img/tips/Pellet release start time [s].jpg";
import pelletsReleasesPerSecond from "../assets/img/tips/Pellets released per second [1s].jpg";
import initialDiameterOfPellets from "../assets/img/tips/Initial diameter of pellets [mm].jpg";
import releasePointRadiusFromCenter from "../assets/img/tips/Release point radius from center [m].jpg";
import releasePointAngleFromXaxis from "../assets/img/tips/Release point angle from x-Axis [°].jpg";
import releasePointHeighFromTankBottom from "../assets/img/tips/Release point height from tank bottom [m].jpg";
import maximumNumberOfPellets from "../assets/img/tips/Maximum number of pellets [-].jpg";
import minimumDiameterOfPellets from "../assets/img/tips/Minimum diameter of pellets [mm].jpg";
import shearRateAtWhichPelletsAreBrokenInTwo from "../assets/img/tips/Shear rate at which pellets are broken in two [1s].jpg";
import shearRateAtWhichPelletsAreBrokenInFour from "../assets/img/tips/Shear rate at which pellets are broken in four [1s].jpg";
import shearRateAtWhichPelletsAreDead from "../assets/img/tips/Shear rate at which pellets are dead [1s].jpg";
import numberOfPelletsWhoseLifelineIsRecorded from "../assets/img/tips/Number of pellets whose lifeline is recorded [-].jpg";
import DiffusionCoefficientN2inWater from "../assets/img/tips/Diffusion coefficient N2 in Water [10^-9 ms^2].jpg";
import DiffusionCoefficientO2inWater from "../assets/img/tips/Diffusion coefficient O2 in Water [10^-9 ms^2].jpg";
import gasFlowRate from "../assets/img/tips/Gas flow rate [Nmh].jpg";
import headSpacePressure from "../assets/img/tips/Head space pressure [Pa].jpg";
import initialDiameterOfTheBubbles from "../assets/img/tips/Initial diameter of the bubbles [m].jpg";
import initialGasDensity from "../assets/img/tips/Initial gas density [kgm].jpg";
import initialNumberOfBubblesInAparcel from "../assets/img/tips/Initial number of bubbles in a parcel [-].jpg";
import numberOfBubblesReleasedPerReleaseEvent from "../assets/img/tips/Number of bubbles released per release event [-].jpg";
import oxygenDissolutionAndTransport from "../assets/img/tips/Oxygen dissolution and transport [truefalse].jpg";
import surfaceTension from "../assets/img/tips/Surface tension [Nm].jpg";

export type SimvantageTipsType = { [key: string]: { imgSrc?: string; text?: string; title?: string } };

export const TIPS: SimvantageTipsType = {
  Name: {
    imgSrc: name,
  },
  "Cuboid reactor: Reactor length X": {
    imgSrc: cuboidReactorReactorLengthX,
  },
  "Cuboid reactor: Reactor length Y": {
    imgSrc: cuboidReactorReactorLengthY,
  },
  "Broth weight": {
    imgSrc: brothWeight,
  },
  "Reactor diameter": {
    imgSrc: reactorDiameter,
  },
  "Calculation nodes per meter": {
    imgSrc: calculationNodesPerMeter,
  },
  "Produce 3D output data each": {
    imgSrc: produce3DoutputDataEach,
  },
  "Produce 2D output data each": {
    imgSrc: produce2DoutputDataEach,
  },
  "Produce text output each": {
    imgSrc: produceTextoutputDataEach,
  },
  "Stop simulation after": {
    imgSrc: stopSimulationAfter,
  },
  "Stop simulation earlier when substrate is fully mixed": {
    imgSrc: stopSimulationEarlier,
  },
  "Mixing quality at which simulation stops automatically": {
    imgSrc: mixingQualityAtWhichSimulationStopsAutomatically,
  },
  "Liquid density": {
    imgSrc: liquidDensity,
  },
  "Power law coefficient": {
    imgSrc: powerLawCoefficient,
  },
  "Power law index n": {
    imgSrc: powerLawIndex,
  },
  Kloepperhead: {
    imgSrc: kloepperhead,
  },

  "Gas sparger height": {
    imgSrc: gasSpargerHeight,
  },
  "Gas sparger diameter": {
    imgSrc: gastSpargerDiameter,
  },
  "Ring sparger height": {
    imgSrc: ringSpargerHeight,
  },
  "Gas sparger offset in x-direction": {
    imgSrc: gasSpargerXdirection,
  },
  "Gas sparger offset in y-direction": {
    imgSrc: gasSpargerYdirection,
  },
  "Shaft diameter": {
    imgSrc: shaftDiameter,
  },
  "Vertical distance of shaft bottom to reactor bottom": {
    imgSrc: verticalDistanceOfShaftBottomToReactorBottom,
  },
  "Vertical distance of shaft top to reactor bottom": {
    imgSrc: verticalDistanceOfShaftTopToReactorBottom,
  },
  "Offset of the shaft top in x-direction": {
    imgSrc: offsetShaftTopXdirection,
  },
  "Offset of the shaft top in y-direction": {
    imgSrc: offsetShaftTopYdirection,
  },
  "Offset of the shaft bottom in x-direction": {
    imgSrc: offsetShaftBottomXdirection,
  },
  "Offset of the shaft bottom in y-direction": {
    imgSrc: offsetShaftBottomYdirection,
  },
  "Baffle width": {
    imgSrc: baffleWidth,
  },
  "Baffle gap to reactor wall": {
    imgSrc: baffleGapToReactor,
  },
  "Number of baffles": {
    imgSrc: numberOfBaffles,
  },
  "Distance of baffle lower end to tank bottom": {
    imgSrc: distanceOfBaffleLowerEndToTankBottom,
  },
  "Stirrer speed": {
    imgSrc: stirrerSpeed,
  },
  "First stirrer": {
    imgSrc: firstStirrer,
  },
  "First stirrer distance along shaft from shaft bottom": {
    imgSrc: fStirrerDistanceAlongShaftFromShaftBottom,
  },
  "First stirrer disk diameter": {
    imgSrc: fStirrerDiskDiameter,
  },
  "First stirrer blade outer diameter": {
    imgSrc: fStirrerBladeOuterDiameter,
  },
  "First stirrer blade inner diameter": {
    imgSrc: fStirrerBladeInnerDiameter,
  },
  "First stirrer blade height (true blade height at angle)": {
    imgSrc: fStirrerBladeHeigh,
  },
  "First stirrer blade thickness": {
    imgSrc: fStirrerBladeThickness,
  },
  "First stirrer disc thickness": {
    imgSrc: fStirrerDiscThickness,
  },
  "First stirrer blade angle (positive angle for downpumping)": {
    imgSrc: fStirrerBladeAngle,
  },
  "First stirrer blade spread (if >0.0 => Elephant ear)": {
    imgSrc: fStirrerBladeSpread,
  },
  "First stirrer blade number": {
    imgSrc: fStirrerBladeNumber,
  },
  "First stirrer is a Levmixer": {
    imgSrc: fStirrerLevmixer,
  },
  "First levmixer edge height": {
    imgSrc: fStirrerEdgeHeigh,
  },
  "First stirrer blade outer angle (if >0.0 => Intermig)": {
    imgSrc: fStirrerBladeOuterAngle,
  },
  "First stirrer outer blades vertical separation distance (Intermig)": {
    imgSrc: fStirrerBladesVerticalSeparationDistance,
  },
  "First stirrer outer blades height (Intermig)": {
    imgSrc: fStirrerOuterBladesHeigh,
  },
  "First stirrer half circle radius (if >0.0 => Propeller)": {
    imgSrc: fStirrerHalfCircleRadius,
  },
  "First stirrer cone tip to axis distance (Propeller)": {
    imgSrc: fStirrerConeTipeAxisDistance,
  },
  "First stirrer initial turning angle": {
    imgSrc: fStirrerInitialTurningAngle,
  },
  "Sensor 1": {
    imgSrc: sensor1,
  },
  "Circle diameter of sensor 1 middle point": {
    imgSrc: sensor1CircleDiamterMiddlePoint,
  },
  "Angle of sensor 1 middle point": {
    imgSrc: sensor1AngleMiddlePoint,
  },
  "Distance of sensor 1 to reactor bottom": {
    imgSrc: sensor1DistanceReactorBottom,
  },
  "Diameter of sensor 1": {
    imgSrc: sensor1Diameter,
  },
  "Substrate 1 transport": {
    imgSrc: substrate1Transport,
  },
  "Radius of substrate 1 discharge point": {
    imgSrc: radiousOfSubstrate1DischargePoint,
  },
  "Angle of substrate 1 discharge point": {
    imgSrc: angleOfSubstrate1DischargePoint,
  },
  "Height of substrate 1 discharge point": {
    imgSrc: heighOfSubstrate1DischargePoint,
  },
  "Substrate 1 density": {
    imgSrc: substrate1Density,
  },
  "Substrate 1 discharge rate": {
    imgSrc: substrate1DischargeRate,
  },
  "Substrate 1 discharge concentration": {
    imgSrc: substrate1DischargeConcentration,
  },
  "Substrate 1 discharge amount": {
    imgSrc: substrate1DischargeAmount,
  },
  "Substrate 1 discharge start time": {
    imgSrc: substrate1DischargeStartTime,
  },
  "Substrate 1 split transport": {
    imgSrc: substrate1SplitTransport,
  },
  "Radius of substrate 1 split discharge point": {
    imgSrc: radiousOfSubstrate1DischargePoint,
  },
  "Angle of substrate 1 split discharge point": {
    imgSrc: angleOfSubstrate1SplitDischargePoint,
  },
  "Enable pellets calculation": {
    imgSrc: enablePelletsCalculation,
  },
  "Pellet release start time": {
    imgSrc: pelletReleaseStartTime,
  },
  "Pellets released per second": {
    imgSrc: pelletsReleasesPerSecond,
  },
  "Initial diameter of pellets": {
    imgSrc: initialDiameterOfPellets,
  },
  "Release point radius from center": {
    imgSrc: releasePointRadiusFromCenter,
  },
  "Release point angle from x-Axis": {
    imgSrc: releasePointAngleFromXaxis,
  },
  "Release point height from tank bottom": {
    imgSrc: releasePointHeighFromTankBottom,
  },
  "Maximum number of pellets": {
    imgSrc: maximumNumberOfPellets,
  },
  "Minimum diameter of pellets": {
    imgSrc: minimumDiameterOfPellets,
  },
  "Shear rate at which pellets are broken in two": {
    imgSrc: shearRateAtWhichPelletsAreBrokenInTwo,
  },
  "Shear rate at which pellets are broken in four": {
    imgSrc: shearRateAtWhichPelletsAreBrokenInFour,
  },
  "Shear rate at which pellets are dead": {
    imgSrc: shearRateAtWhichPelletsAreDead,
  },
  "Number of pellets whose lifeline is recorded": {
    imgSrc: numberOfPelletsWhoseLifelineIsRecorded,
  },
  "Installations volume": {
    text: "Volume of baffles, stirrer and sparger",
  },
  "Diffusion coefficient N2 in Water": {
    imgSrc: DiffusionCoefficientN2inWater,
  },
  "Diffusion coefficient O2 in Water": {
    imgSrc: DiffusionCoefficientO2inWater,
  },
  "Gas flow rate": {
    imgSrc: gasFlowRate,
  },
  "Head space pressure": {
    imgSrc: headSpacePressure,
  },
  "Initial diameter of the bubbles": {
    imgSrc: initialDiameterOfTheBubbles,
  },
  "Initial gas density": {
    imgSrc: initialGasDensity,
  },
  "Initial number of bubbles in a parcel": {
    imgSrc: initialNumberOfBubblesInAparcel,
  },
  "Number of bubbles released per release event": {
    imgSrc: numberOfBubblesReleasedPerReleaseEvent,
  },
  "Oxygen dissolution and transport": {
    imgSrc: oxygenDissolutionAndTransport,
  },
  "Surface tension": {
    imgSrc: surfaceTension,
  },
};
