import { ApolloClient, HttpLink, ApolloLink, InMemoryCache, fromPromise } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import config from "react-global-configuration";
import { typeDefs } from "./schema/types";
import { resolvers } from "./schema/resolvers";
import getNewToken from "./auth/getNewToken";

const uri = config.get("graphql");

// eslint-disable-next-line import/no-mutable-exports
let client;

const httpLink = new HttpLink({ uri });

const authLink = new ApolloLink((operation, next) => {
  const token = localStorage.getItem("token");
  const refreshToken = localStorage.getItem("refreshToken");

  operation.setContext((context) => ({
    ...context,
    headers: {
      ...context.headers,
      token,
      refreshToken,
    },
  }));

  return next(operation);
});

// eslint-disable-next-line consistent-return
const logoutLink = onError(({ networkError, graphQLErrors, operation, forward }) => {
  // validating networkErrors  & graphqlErrors
  if (graphQLErrors) {
    // destructure to remove initial array
    const [errors] = graphQLErrors;

    if (errors.extensions.code === "UNAUTHENTICATED") {
      //
      return fromPromise(
        getNewToken(client).catch(() => {
          // removing tokens and redirecting to login page
          localStorage.removeItem("token");
          localStorage.removeItem("refreshToken");
          localStorage.removeItem("firebaseToken");
          window.location = "/login";
        }),
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          // replace headers
          const oldHeaders = operation.getContext().headers;
          localStorage.setItem("token", accessToken);

          operation.setContext({
            headers: {
              ...oldHeaders,
              token: accessToken || "",
            },
          });

          // retry the request, returning the new observable
          return forward(operation);
        });
    }
  }
  if (typeof networkError !== "undefined") {
    console.log("networkError", networkError);

    if (networkError.statusCode === 500) {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("firebaseToken");
      window.location = "/login";
    }
  }
});

// Create apollo client
client = new ApolloClient({
  cache: new InMemoryCache(),
  link: logoutLink.concat(authLink.concat(httpLink)),
  resolvers,
  typeDefs,
});

export default client;
