import React from "react";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

const LoginTextInput = ({ groupClass, error, icon, ...props }) => {
  return (
    <>
      <InputGroup className={groupClass}>
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input {...props} />
        {error && <div className="alert alert-danger">{error}</div>}
      </InputGroup>
    </>
  );
};

LoginTextInput.propTypes = {
  required: PropTypes.bool,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  icon: PropTypes.string,
  autocomplete: PropTypes.string,
  error: PropTypes.string,
  groupClass: PropTypes.string,
};

export default LoginTextInput;
