import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Spinner from "../../common/Spinner";

/**
 * This components is being rendered while the login request is being processed
 * It closes automatically once the request is completed
 * @param param0
 * @returns
 */
const CheckingYourConnectionPage = ({ openModal }: { openModal: boolean }) => {
  const [modal, setModal] = useState(openModal);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setModal(openModal);
  }, [openModal]);

  return (
    <>
      <Modal size="sm" className="modal-primary modal-dialog-centered" isOpen={modal} toggle={toggle}>
        <ModalHeader>Connection check</ModalHeader>
        <ModalBody>
          <center>
            <p>Checking your connection, please wait.</p>
            <p>This can take up to 10 seconds.</p>
            <Spinner />
          </center>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CheckingYourConnectionPage;
