import { getLocalRunData, getLocalNumberCases } from "../queries/local-queries";

export const resolvers = {
  Mutation: {
    addSetRun: (_, { json, inputFile }, { cache }) => {
      const data = {
        runState: {
          __typename: "runState",
          json: json,
          inputFile: inputFile,
        },
      };

      cache.writeData({ data });
      return true;
    },
    incrementCounter: (_, val, { cache }) => {
      try {
        var getLocalCases = cache.readQuery({ query: getLocalNumberCases });

        var {
          getLocalNumberCases: { cases },
        } = getLocalCases;

        var increment = cases + 1;
        const data = {
          getLocalNumberCases: {
            __typename: "counter",
            cases: increment,
          },
        };

        cache.writeData({ data });
        return increment;
      } catch (e) {
        return 0;
      }
    },
    decrementCounter: (_, val, { cache }) => {
      try {
        var getLocalCases = cache.readQuery({ query: getLocalNumberCases });
        var {
          getLocalNumberCases: { cases },
        } = getLocalCases;
        var increment = cases - 1;
        const data = {
          getLocalNumberCases: {
            __typename: "counter",
            cases: increment,
          },
        };

        cache.writeData({ data });
        return increment;
      } catch (e) {
        return 0;
      }
    },
    resetCounter: (_, val, { cache }) => {
      try {
        // var getLocalCases = cache.readQuery({ query: getLocalNumberCases });

        var increment = 0;
        const data = {
          getLocalNumberCases: {
            __typename: "counter",
            cases: increment,
          },
        };

        cache.writeData({ data });
        return increment;
      } catch (e) {
        return 0;
      }
    },
    setLocalRun: (_, { run }, { cache }) => {
      try {
        let newRun;
        let { getLocalRun } = cache.readQuery({ query: getLocalRunData });

        if (getLocalRun.length === 0) {
          newRun = getLocalRun.concat({ ...run, __typename: "getLocalRun" });
        } else {
          newRun = getLocalRun.concat({ ...run, __typename: "getLocalRun" });
        }

        cache.writeData({ data: { getLocalRun: newRun } });
        return true;
      } catch {
        return false;
      }
    },
    updateLocalRun: (_, { run }, { cache }) => {
      try {
        let { getLocalRun } = cache.readQuery({ query: getLocalRunData });
        var test = getLocalRun.findIndex(x => x.caseId === run.caseId);
        if (getLocalRun.length === 1) {
          cache.writeData({ data: { getLocalRun: [] } });
        } else {
          getLocalRun.splice(test, 1);
        }
        return true;
      } catch {
        return false;
      }
    },
    restoreLocal: (_, args, { cache }) => {
      try {
        let { getLocalRun } = cache.readQuery({ query: getLocalRunData });
        getLocalRun.splice(0, getLocalRun.length);
        cache.writeData({ data: { getLocalRun: [] } });
        return true;
      } catch {
        return false;
      }
    },
    updateLocalFileId: (_, args, { cache }) => {
      let { getLocalRun } = cache.readQuery({ query: getLocalRunData });
      const data = {
        getLocalRun: [
          {
            ...getLocalRun,
            inputFileId: args.inputFileId,
            url: args.url,
          },
        ],
      };

      cache.writeData({ data });
      return true;
    },
  },
};
