import { InstantLogFileType } from "./logFileTypes";

// ACTION TYPES
export const START_FETCH_INSTANT_LOG_FILE = "instantLogFile/START_FETCH_INSTANT_LOG_FILE";
export const SUCCESS_FETCH_INSTANT_LOG_FILE = "instantLogFile/SUCCESS_FETCH_INSTANT_LOG_FILE";
export const FAILURE_FETCH_INSTANT_LOG_FILE = "instantLogFile/FAILURE_FETCH_INSTANT_LOG_FILE";

export const START_POLL_INSTANT_LOG_FILE = "instantLogFile/START_POLL_INSTANT_LOG_FILE";
export const STOP_POLL_INSTANT_LOG_FILE = "instantLogFile/STOP_POLL_INSTANT_LOG_FILE";

export const START_CREATION_OF_INSTANT_LOG_FILE = "instantLogFile/START_CREATION_OF_INSTANT_LOG_FILE";
export const SUCCESS_CREATION_OF_INSTANT_LOG_FILE = "instantLogFile/SUCCESS_CREATION_OF_INSTANT_LOG_FILE";
export const FAILURE_CREATION_OF_INSTANT_LOG_FILE = "instantLogFile/FAILURE_CREATION_OF_INSTANT_LOG_FILE";

// ACTION CREATORS

/**
 * Fetching log file from API
 */
export const startFetchInstantLogFile = () => ({
  type: START_FETCH_INSTANT_LOG_FILE,
  payload: {},
});

export const successFetchInstantLogFile = (logFileData: InstantLogFileType) => ({
  type: SUCCESS_FETCH_INSTANT_LOG_FILE,
  payload: {
    logFileData,
  },
});

export const failureFetchingInstantLogFile = (msg: string) => ({
  type: FAILURE_FETCH_INSTANT_LOG_FILE,
  payload: { errorMessage: msg },
});

/**
 * Polling data from API
 */
export const startPollingForInstantLogFile = () => ({
  type: START_POLL_INSTANT_LOG_FILE,
  payload: {},
});

export const stopPollingForInstantLogFile = () => ({
  type: STOP_POLL_INSTANT_LOG_FILE,
  payload: {},
});

/**
 * Request to create Intant Log File
 */
export const startCreationOfInstantLogFile = () => ({
  type: START_CREATION_OF_INSTANT_LOG_FILE,
  payload: {},
});

export const successCreationOfInstantLogFile = (data: InstantLogFileType) => ({
  type: SUCCESS_CREATION_OF_INSTANT_LOG_FILE,
  payload: {
    data,
  },
});

export const failureCreationOfInstantLogFile = (msg: string) => ({
  type: FAILURE_CREATION_OF_INSTANT_LOG_FILE,
  payload: {
    errorMessage: msg,
  },
});
