import {
  LOAD_PROJECT_LIST,
  RESET_PROJECT_LIST,
  SELECT_ALL_PROJECTS,
  SELECT_PROJECT,
  UNSELECT_ALL_PROJECTS,
  UNSELECT_PROJECT,
} from "./actions";

export type ProjectCheckBox = {
  _id: string;
  checked: boolean;
};

export type ProjectCheckBoxReduxState = {
  projects: ProjectCheckBox[];
  selectAll: boolean;
};
export const initialState: ProjectCheckBoxReduxState = {
  projects: [],
  selectAll: false,
};

// reducer for new experiment page
export default function reducer(state = initialState, action: { type: any; payload: any }) {
  const { type, payload } = action;
  switch (type) {
    case LOAD_PROJECT_LIST: {
      const { data } = payload;
      const listCheckBox = data.map((proj: { _id: string }) => ({ _id: proj._id, checked: false }));
      return { ...state, projects: listCheckBox };
    }

    case SELECT_PROJECT: {
      const { projectId } = payload;
      const listProjects = state.projects.map(proj => {
        if (proj._id === projectId) {
          return { ...proj, checked: true };
        }
        return proj;
      });

      return { ...state, projects: listProjects };
    }

    case UNSELECT_PROJECT: {
      const { projectId } = payload;
      const newCheckBoxState = state.projects.map(proj => {
        if (proj._id === projectId) {
          return { ...proj, checked: false };
        }
        return proj;
      });
      return { ...state, projects: newCheckBoxState, selectAll: false };
    }

    case SELECT_ALL_PROJECTS: {
      const projects = state.projects.map(proj => ({ ...proj, checked: true }));
      return { ...state, projects: projects, selectAll: true };
    }

    case UNSELECT_ALL_PROJECTS: {
      const projects = state.projects.map(proj => ({ ...proj, checked: false }));
      return { ...state, projects: projects, selectAll: false };
    }

    case RESET_PROJECT_LIST: {
      return { projects: [], selectAll: false };
    }
    default:
      return state;
  }
}
