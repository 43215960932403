import {
  SET_PROJECT_NAME,
  SET_PROJECT_OPEN_FOAM,
  SET_PROJECT_EXPERIMENT,
  GET_PROJECT_START,
  GET_PROJECT_SUCCESS,
  GET_PROJECT_FAILURE,
  VALIDATE_STATUS_START,
  VALIDATE_STATUS_SUCCESS,
  VALIDATE_STATUS_FAILURE,
  DELETE_PROJECT_START,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  DOWNLOAD_INPUT_FILE_START,
  DOWNLOAD_INPUT_FILE_SUCCESS,
  DOWNLOAD_INPUT_FILE_FAILURE,
  SET_SOFTWARE_LICENSE,
  SET_SIMULATION_SOFTWARE_LIST,
} from "./actions";

/* initial state */
export const initialState = {
  redirectToExperimentProjectPage: false,
  experimentProjectId: null,
  name: "",
  openFoam: "",
  softwareLicenseId: "",
  softwareLicenseName: "",
  isExperiment: false,
  getProjectLoading: false,
  inputFileDownloading: false,
  project: {},
  validateStatusLoading: false,
  deleteProjectLoading: false,
  projectCouldBeDeleted: false,
  softwareLicenseList: [],
  simulationSoftwareList: [],
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PROJECT_NAME: {
      const { name } = payload;
      return {
        ...state,
        name,
      };
    }
    case SET_PROJECT_OPEN_FOAM: {
      const { openFoam } = payload;
      return {
        ...state,
        openFoam,
      };
    }

    case SET_SOFTWARE_LICENSE: {
      const { softwareLicenseId, softwareLicenseName } = payload;
      return {
        ...state,
        softwareLicenseId,
        softwareLicenseName,
        openFoam: "",
      };
    }

    case SET_SIMULATION_SOFTWARE_LIST: {
      const { softwareList } = payload;
      return {
        ...state,
        simulationSoftwareList: softwareList,
      };
    }

    case SET_PROJECT_EXPERIMENT: {
      const { isExperiment } = payload;
      return {
        ...state,
        isExperiment,
      };
    }

    case GET_PROJECT_START: {
      return {
        ...state,
        getProjectLoading: true,
      };
    }
    case GET_PROJECT_SUCCESS: {
      return {
        ...state,
        getProjectLoading: false,
      };
    }
    case GET_PROJECT_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        getProjectLoading: false,
        getProjectError: error,
      };
    }

    case VALIDATE_STATUS_START: {
      return {
        ...state,
        validateStatusLoading: true,
      };
    }
    case VALIDATE_STATUS_SUCCESS: {
      const { projectCouldBeDeleted } = payload;
      return {
        ...state,
        projectCouldBeDeleted,
        validateStatusLoading: false,
      };
    }
    case VALIDATE_STATUS_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        validateStatusLoading: false,
        validateStatusError: error,
      };
    }

    case DELETE_PROJECT_START: {
      return {
        ...state,
        deleteProjectLoading: true,
      };
    }
    case DELETE_PROJECT_SUCCESS: {
      return {
        ...state,
        deleteProjectLoading: false,
      };
    }
    case DELETE_PROJECT_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectError: error,
      };
    }

    case DOWNLOAD_INPUT_FILE_START: {
      return {
        ...state,
        inputFileDownloading: true,
      };
    }
    case DOWNLOAD_INPUT_FILE_SUCCESS: {
      return {
        ...state,
        inputFileDownloading: false,
      };
    }
    case DOWNLOAD_INPUT_FILE_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        inputFileDownloading: false,
        inputFileDownloadingError: error,
      };
    }
    default:
      return state;
  }
}
