// eslint-disable-next-line no-unused-vars
import React, { lazy } from "react";
import { getKccDomain, isKccUrl, kccDomains } from "../../libs/kcc/domainUrls";
import GeodictLogo from "../../components/common/themedLogo/GeodictLogo";

const SimvantageLogo = lazy(() => import("../../components/common/themedLogo/SimvantageLogo"));
const SesesLogo = lazy(() => import("../../components/common/themedLogo/SesesLogo"));
const ComsolLogo = lazy(() => import("../../components/common/themedLogo/CloudCompanionLogo"));

const SimFlowLogo = lazy(() => import("../../components/common/themedLogo/SimFlowLogo"));

const DefaultLogo = lazy(() => import("../../components/common/themedLogo/DefaultLogo"));
/**
 * function to load the Logo given a kcc domain
 * @param {*} host
 * @returns
 */
export function getKccLogo(host) {
  if (host !== null) {
    const isKcc = isKccUrl(host.url);
    if (isKcc) {
      const response = getKccDomain(host.url);
      switch (response) {
        case kccDomains.simvantage:
          return SimvantageLogo;
        case kccDomains.seses:
          return SesesLogo;
        case kccDomains.comsol:
          return ComsolLogo;
        case kccDomains.simflow:
          return SimFlowLogo;
        case kccDomains.geodict:
          return GeodictLogo;
        default:
          return DefaultLogo;
      }
    }
  }
  return null;
}
