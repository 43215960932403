// thunk types
export const CREATE_PROJECT_EXPERIMENT_SAVE = "projects/CREATE_PROJECT_EXPERIMENT_SAVE";

export const SET_PROJECT_NAME = "projects/SET_PROJECT_NAME";
export const SET_PROJECT_OPEN_FOAM = "projects/SET_PROJECT_OPEN_FOAM";
export const SET_PROJECT_EXPERIMENT = "projects/SET_PROJECT_EXPERIMENT";

export const SET_SOFTWARE_LICENSE = "projects/SET_SOFTWARE_LICENSE";
export const SET_SIMULATION_SOFTWARE_LIST = "projects/SET_SIMULATION_SOFTWARE_LIST";

export const GET_PROJECT_START = "projects/GET_PROJECT_START";
export const GET_PROJECT_SUCCESS = "projects/GET_PROJECT_SUCCESS";
export const GET_PROJECT_FAILURE = "projects/GET_PROJECT_FAILURE";

export const VALIDATE_STATUS_START = "projects/VALIDATE_STATUS_START";
export const VALIDATE_STATUS_SUCCESS = "projects/VALIDATE_STATUS_SUCCESS";
export const VALIDATE_STATUS_FAILURE = "projects/VALIDATE_STATUS_FAILURE";

export const DELETE_PROJECT_START = "projects/DELETE_PROJECT_START";
export const DELETE_PROJECT_SUCCESS = "projects/DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILURE = "projects/DELETE_PROJECT_FAILURE";

export const DOWNLOAD_INPUT_FILE_START = "projects/DOWNLOAD_INPUT_FILE_START";
export const DOWNLOAD_INPUT_FILE_SUCCESS = "projects/DOWNLOAD_INPUT_FILE_SUCCESS";
export const DOWNLOAD_INPUT_FILE_FAILURE = "projects/DOWNLOAD_INPUT_FILE_FAILURE";

// thunk action creators
export const setProjectName = (name) => ({
  type: SET_PROJECT_NAME,
  payload: { name },
});

export const setProjectOpenFoam = (openFoam) => ({
  type: SET_PROJECT_OPEN_FOAM,
  payload: { openFoam },
});

export const setSoftwareLicenseId = (softwareLicenseId, softwareLicenseName) => ({
  type: SET_SOFTWARE_LICENSE,
  payload: { softwareLicenseId, softwareLicenseName },
});

export const setSimulationSoftwareList = (softwareList) => ({
  type: SET_SIMULATION_SOFTWARE_LIST,
  payload: { softwareList },
});

export const setProjectIsExperiment = (isExperiment) => ({
  type: SET_PROJECT_EXPERIMENT,
  payload: { isExperiment },
});

export const getProjectStart = () => ({
  type: GET_PROJECT_START,
  payload: {},
});

export const getProjectSuccess = () => ({
  type: GET_PROJECT_SUCCESS,
  payload: {},
});

export const getProjectFailure = (error) => ({
  type: GET_PROJECT_FAILURE,
  payload: { error },
});

export const validateStatusStart = () => ({
  type: VALIDATE_STATUS_START,
  payload: {},
});

export const validateStatusSuccess = (projectCouldBeDeleted) => ({
  type: VALIDATE_STATUS_SUCCESS,
  payload: { projectCouldBeDeleted },
});

export const validateStatusFailure = (error) => ({
  type: VALIDATE_STATUS_FAILURE,
  payload: { error },
});

export const deleteProjectStart = () => ({
  type: DELETE_PROJECT_START,
  payload: {},
});

export const deleteProjectSuccess = () => ({
  type: DELETE_PROJECT_SUCCESS,
  payload: {},
});

export const deleteProjectFailure = (error) => ({
  type: DELETE_PROJECT_FAILURE,
  payload: { error },
});

export const downloadInputFileStart = () => ({
  type: DOWNLOAD_INPUT_FILE_START,
  payload: {},
});

export const downloadInputFileSuccess = () => ({
  type: DOWNLOAD_INPUT_FILE_SUCCESS,
  payload: {},
});

export const downloadInputFileFailure = (error) => ({
  type: DOWNLOAD_INPUT_FILE_FAILURE,
  payload: { error },
});
