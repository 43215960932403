import React, { useState, useCallback } from "react";
import { useMutation } from "@apollo/client";
import { Container, Row, Col, Card, Button, Label, Form, Alert } from "reactstrap";
import { toast } from "react-toastify";
import { Redirect, Link } from "react-router-dom";
import LayoutLogo from "../../common/LayoutLogo";
import TextInputInline from "../../common/TextInputInline";
import { sendResetPasswordEmailMutation } from "../../../queries/user-queries";

/**
 * Sends an email to reset user password
 */
function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retry, setRetry] = useState(false);

  const [resetPassword] = useMutation(sendResetPasswordEmailMutation);

  const onChange = useCallback(event => {
    setEmail(event.target.value);
  });

  const sendResetEmail = useCallback(async e => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await resetPassword({ variables: { email } });
      const { success, errors } = data.sendResetPasswordEmail;
      // validating response
      if (success) {
        setMessage("");
        toast.success("Your request is being processed. You will receive an email shortly");
        setRedirect(true);
      } else {
        setMessage(errors[0].message);
        setRetry(true);
        toast.error("Something went wrong. Please try again.");
      }
    } catch (err) {
      console.error(err);
      setRetry(true);
      toast.error("Something went wrong. Please try again.");
    }
    setLoading(false);
  });
  return (
    <div className="app flex-row align-items-center">
      {redirect && <Redirect to="/login" />}
      <Container>
        <LayoutLogo />
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="p-4">
              <Form onSubmit={sendResetEmail}>
                <Label>Please enter your email to reset the password.</Label>
                <TextInputInline
                  groupClass="mb-3"
                  required
                  type="email"
                  value={email}
                  name="email"
                  placeholder="Email"
                  autoComplete="email"
                  onChange={onChange}
                  icon="fa fa-at"
                />
                {message && <Alert color="danger">{message}</Alert>}
                <Link to="/login">
                  <Button color="secondary">Cancel</Button>
                </Link>
                &nbsp;&nbsp;
                <Button disabled={loading} id="reset-password" color="primary">
                  {retry && <i className="fa fa-refresh" aria-hidden="true" />}
                  &nbsp;Reset my Password
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
export default ForgotPasswordPage;
