import { getIsParallel, getRunScriptValue } from "../../../context/createCase/helpers";
import { NewExperimentStateType } from "./newExperimentStateType";

export const defaultSesesRunScript = "k2d Input.k2d &> $ROOT_DIRECTORY/log.txt";

/**
 * Gets a default run script for DoE
 * @param state
 * @returns
 */
export function getRunScript(state: NewExperimentStateType, isSesesEnabled: boolean): string {
  let runScript: string;

  // we dont update the runScript for SESES
  if (isSesesEnabled) {
    runScript = state.runScript;
  } else {
    // update runScript when the user selects more than 2 vcpus
    const numCPUs = state?.machine?.cpu || 0;
    const parallel = getIsParallel(state.decomposeTo, numCPUs);

    runScript = getRunScriptValue(state.solverName || "", numCPUs, state.decomposeTo, parallel, state.runScript);
  }
  return runScript;
}
