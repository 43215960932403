import { getRunScriptValue } from "../helpers";
import { CreateCaseContextType } from "../inititalState";

/**
 * Update solver selection and run script
 * @param payload
 * @param state
 * @returns
 */
export default function setSolverAndRunScript(payload: { solverName: string }, state: CreateCaseContextType): CreateCaseContextType {
  const { solverName } = payload;
  const { numCPUs, sliderValue, parallel } = state;

  let runScript =  getRunScriptValue(solverName, numCPUs, sliderValue, parallel, state.runScript);

  return { ...state, solverName, runScript };
}
