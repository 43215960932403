// Action types

export const SUBSCRIBE_UPDATE = "user/SUBSCRIBE_UPDATE";
export const SUBSCRIBE_ERROR = "user/SUBSCRIBE_ERROR";
export const SET_USER_DATA = "user/SET_USER_DATA";

// Action creators
export const subscribeUpdate = (userId, showTutorial) => ({
  type: SUBSCRIBE_UPDATE,
  payload: { userId, showTutorial },
});

export const subscribeError = (userId, error) => ({
  type: SUBSCRIBE_ERROR,
  payload: { userId, error },
});
