import { InvoiceRecord } from "../../components/payments/invoices/InvoiceList";
import { GET_INVOICES_START, GET_INVOICES_SUCCESS, GET_INVOICES_FAILURE } from "./actions";

type InvoiceState = {
  list: InvoiceRecord[];
  loading: boolean;
  error: string;
};

/* initial state */
export const initialState: InvoiceState = {
  list: [],
  loading: false,
  error: "",
};

type Payload = {
  list: InvoiceRecord[];
  error: string;
};
type Action = {
  type: string;
  payload: Payload;
};

export default function reducer(state = initialState, action: Action) {
  const { type, payload } = action;
  switch (type) {
    case GET_INVOICES_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_INVOICES_SUCCESS: {
      const { list } = payload;
      return {
        ...state,
        loading: false,
        list,
      };
    }
    case GET_INVOICES_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        loading: false,
        error,
      };
    }

    default:
      return state;
  }
}
