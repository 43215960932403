const allowedExtensions = ["zip"];
const disallowedExtensions = ["tar"];

/**
 * Function that checks filename for allowed extensions
 * @param {string} filename a filename as a string to check for allowed extensions
 * @returns {boolean} test result for the filename
 */
const validateFile = filename => {
  const splitFilename = filename.split(".");
  const ext1 = splitFilename[splitFilename.length - 1];
  const ext2 = splitFilename[splitFilename.length - 2];
  // check if we got disallowed extensions
  if (disallowedExtensions.includes(ext1) || disallowedExtensions.includes(ext2)) return false;
  // check if we have allowed extension
  if (allowedExtensions.includes(ext1)) return true;
  // default
  return false;
};

export default validateFile;
