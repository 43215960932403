import { SLIDER_CHANGE, HOURS_INPUT, PRICE_INPUT, EMPTY, FETCH_START, FETCH_SUCCESS, FETCH_ERROR } from "./actions";
/* initial state */
const initialState = {
  totalAmount: 0,
  totalPrice: 0,

  // from graphQL
  loading: false,
  error: null,
  shortId: "",
  description: "",
  priceInDollars: 0,
  sliderMin: 0,
  sliderMax: 0,
  sliderStep: 0,
  sliderInitialValue: 0,
  sliderDescText: "",
  sliderCalcText: "",
  minPurchaseValue: 0,

  invoiceText: "",
  cartItem: {},
};

/**
 * Checks to see if the value that is passed in is properly formatted.
 * Properly formatted means that the number doesn't start with a 0 and
 * is followed by any other digit.
 * @param {number} value
 */
function testForDigits(value) {
  return /^[1-9]\d*$/.test(value); // ^[1-9] -> string may only start with a digit between 1 & 9;
  // \d*$ -> followed by and ending with 0 or more of any digit
}

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SLIDER_CHANGE:
      return {
        ...state,
        totalAmount: payload.value,
        totalPrice: payload.value * state.priceInDollars,
      };
    case HOURS_INPUT: {
      if (testForDigits(payload.value)) {
        const newValue = payload.value > state.sliderMax ? state.sliderMax : payload.value; // we don't want to go above sliderMax
        return {
          ...state,
          totalAmount: newValue,
          totalPrice: newValue * state.priceInDollars,
        };
      }
      return state;
    }
    case PRICE_INPUT: {
      if (testForDigits(payload.value)) {
        const maxAmount = state.sliderMax * state.priceInDollars;
        const newValue = payload.value > maxAmount ? maxAmount : payload.value; // we don't want to go above sliderMax
        return {
          ...state,
          totalAmount: newValue / state.priceInDollars,
          totalPrice: newValue,
        };
      }
      return state;
    }
    case EMPTY: {
      return {
        ...state,
        totalAmount: 0,
        totalPrice: 0,
      };
    }

    case FETCH_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_SUCCESS: {
      const { cartItem } = payload;
      // calculating the minimum amount...
      const minPurchaseValue = cartItem.priceInDollars * cartItem.sliderMin;

      return {
        ...state,
        shortId: cartItem.shortId,
        description: cartItem.description,
        priceInDollars: cartItem.priceInDollars,
        sliderMin: cartItem.sliderMin,
        sliderMax: cartItem.sliderMax,
        sliderStep: cartItem.sliderStep,
        sliderInitialValue: cartItem.sliderInitialValue,
        sliderDescText: cartItem.sliderDescText,
        sliderCalcText: cartItem.sliderCalcText,
        minPurchaseValue,
        loading: false,
        cartItem,
        totalAmount: cartItem.sliderMin,
        totalPrice: minPurchaseValue,
      };
    }
    case FETCH_ERROR: {
      const { error } = payload;
      return {
        ...state,
        error,
        loading: false,
      };
    }
    default:
      return state;
  }
}
