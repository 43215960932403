import { InvoiceRecord } from "../../components/payments/invoices/InvoiceList";

export const GET_INVOICES_START = "invoices/GET_INVOICES_START";
export const GET_INVOICES_SUCCESS = "invoices/GET_INVOICES_SUCCESS";
export const GET_INVOICES_FAILURE = "invoices/GET_INVOICES_FAILURE";

export const getInvoicesStart = () => ({
  type: GET_INVOICES_START,
  payload: {},
});

export const getInvoicesSuccess = (list: InvoiceRecord[]) => ({
  type: GET_INVOICES_SUCCESS,
  payload: { list },
});

export const getInvoicesFailure = (error: string) => ({
  type: GET_INVOICES_FAILURE,
  payload: { error },
});
