export const SET_CONTENTS = "resultsAggregation/SET_CONTENTS";

export const setContents = (runId, contents) => ({
  type: SET_CONTENTS,
  payload: { runId, contents },
});

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CONTENTS: {
      const { runId, contents } = payload;
      return { ...state, [runId]: contents };
    }

    default:
      return state;
  }
};

export const getContentsForRunID = (state, runId) => state.resultsAggregation[runId];
