import React from "react";
import SignupForm from "./SignupForm";

export const SignUpPage = ({ match }) => (
  <>
    <SignupForm match={match} />
  </>
);

export default SignUpPage;
