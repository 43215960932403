import { refreshTokenMutation } from "../queries/user-queries";

const getNewToken = apolloClient => {
  const refreshToken = localStorage.getItem("refreshToken");

  return apolloClient.mutate({ mutation: refreshTokenMutation, variables: { refreshToken } }).then(response => {
    // extract your accessToken from your response data and return it
    const { token } = response.data.refreshToken;
    return token;
  });
};
export default getNewToken;
