import { gql } from "@apollo/client";

const loginMutation = gql`
  mutation loginMutation($email: String!, $password: String!, $customUrl: String) {
    login(email: $email, password: $password, customUrl: $customUrl) {
      userId
      success
      token
      refreshToken
      accountEnabled
      showTutorial
      twoFactorAuth
      errors {
        message
      }
    }
  }
`;

const refreshTokenMutation = gql`
  mutation refreshTokenMutation($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      success
      token
      refreshToken
      errors {
        message
      }
    }
  }
`;

const sendResetPasswordEmailMutation = gql`
  mutation sendResetPasswordEmailMutation($email: String!) {
    sendResetPasswordEmail(email: $email) {
      success
      errors {
        message
      }
    }
  }
`;
const resetPasswordMutation = gql`
  mutation resetPasswordMutation($token: String!, $password: String!) {
    resetPassword(token: $token, password: $password) {
      success
      errors {
        message
      }
    }
  }
`;
const loginWithToken = gql`
  mutation loginWithToken($userId: ID, $token: String, $shouldDisable: Boolean) {
    loginWithToken(userId: $userId, token: $token, shouldDisable: $shouldDisable) {
      success
      response
      message
    }
  }
`;
const loginWithRecoveryCode = gql`
  mutation loginWithRecoveryCode($userId: ID!, $recoveryCode: String!) {
    loginWithRecoveryCode(userId: $userId, recoveryCode: $recoveryCode) {
      userId
      twoFactorAuth
      success
      token
      showTutorial
      errors {
        message
      }
    }
  }
`;
const changePassword = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword) {
      success
      errors {
        message
      }
    }
  }
`;
const getCurrentUserId = gql`
  mutation getCurrentUserId {
    getCurrentUserId {
      _id
    }
  }
`;

const createUser = gql`
  mutation createUser(
    $roles: String
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $organizationId: ID
  ) {
    createUser(
      enabled: true
      roles: $roles
      firstName: $firstName
      lastName: $lastName
      email: $email
      password: $password
      organizationId: $organizationId
    ) {
      success
      errors {
        message
      }
    }
  }
`;
const whoIam = gql`
  query whoIam {
    whoIam {
      _id
      firstName
      lastName
      email
      roles
      organization
      organizationId
      multiFactorAuthEnabled
      emailNotifications
      showTutorial
    }
  }
`;
const updateUser = gql`
  mutation updateUser($email: String!, $firstName: String!, $lastName: String!, $emailNotifications: Boolean!) {
    updateUser(email: $email, firstName: $firstName, lastName: $lastName, emailNotifications: $emailNotifications)
  }
`;
const validateIfUserIsKaleidoTeam = gql`
  query isKaleidoOrganizationUser {
    isKaleidoOrganizationUser
  }
`;
const getDebugInfo = gql`
  query getDebugInfo($caseId: ID!) {
    getDebugInfo(caseId: $caseId) {
      runId
      organizationId
      inputFile
      logFile
      outputFile
    }
  }
`;
const initMultiFactorAuth = gql`
  mutation initMultiFactorAuth($userId: ID!) {
    initMultiFactorAuth(userId: $userId) {
      success
      response
      message
    }
  }
`;
const enableMultiFactorAuth = gql`
  mutation enableMultiFactorAuth($userId: ID!, $token: String!) {
    enableMultiFactorAuth(userId: $userId, token: $token) {
      success
      response
      message
    }
  }
`;
const disableMultiFactorAuth = gql`
  mutation disableMultiFactorAuth($userId: ID!, $token: String!) {
    disableMultiFactorAuth(userId: $userId, token: $token)
  }
`;
// it sends an email to the user to confirm his email address
const confirmEmail = gql`
  mutation confirmEmail($userId: ID!, $email: String!) {
    sendEmail(userId: $userId, email: $email) {
      success
      errors {
        message
        path
      }
    }
  }
`;
const sendTwoFactorAuthDisabledEmail = gql`
  mutation sendTwoFactorAuthDisabledEmail($userId: ID!) {
    sendTwoFactorAuthDisabledEmail(userId: $userId)
  }
`;

const myConsume = gql`
  query myConsume {
    userCPUconsumed {
      consumed
      _id
    }
  }
`;

// create a personal account
const personalAccount = gql`
  mutation personalAccount($user: iUser, $token: String, $captchaToken: String!) {
    createPersonalAccount(user: $user, token: $token, captchaToken: $captchaToken) {
      success
      userId
      errors {
        message
        path
      }
    }
  }
`;
const allUsersAtOrg = gql`
  query allUsersOrganization {
    allUsersOrganization {
      _id {
        _id
        firstName
        lastName
        email
        status
      }
      total
      consumed
    }
  }
`;

// verify email and if it is valid sends a new confirmation email
const verifyEmail = gql`
  mutation verifyEmail($email: String!) {
    verifyEmail(email: $email) {
      success
      errors {
        path
        message
      }
    }
  }
`;

// org admin disable user
const disableUser = gql`
  mutation disableUser($userId: ID!) {
    disableUser(userId: $userId) {
      success
      errors {
        path
        message
      }
    }
  }
`;

// org admin enable user
const enableUser = gql`
  mutation enableUser($userId: ID!) {
    enableUser(userId: $userId) {
      success
      errors {
        path
        message
      }
    }
  }
`;

const updateUserShowTutorialState = gql`
  mutation updateUserShowTutorialState($userId: ID!) {
    updateUserShowTutorialState(userId: $userId) {
      success
      message
    }
  }
`;

export {
  loginMutation,
  loginWithToken,
  sendResetPasswordEmailMutation,
  resetPasswordMutation,
  loginWithRecoveryCode,
  createUser,
  validateIfUserIsKaleidoTeam,
  getDebugInfo,
  confirmEmail,
  verifyEmail,
  sendTwoFactorAuthDisabledEmail,
  getCurrentUserId,
  whoIam,
  updateUser,
  changePassword,
  myConsume,
  allUsersAtOrg,
  personalAccount,
  initMultiFactorAuth,
  enableMultiFactorAuth,
  disableMultiFactorAuth,
  disableUser,
  enableUser,
  refreshTokenMutation,
  updateUserShowTutorialState,
};
