import { combineReducers } from "@reduxjs/toolkit";

import heartbeat from "./heartBeat/reducer";
import featureFlags from "./featureFlags/reducer";
import shoppingCart from "./shoppingCart/reducer";
import confirmEmail from "./confirmEmail/reducer";
import fileUpload from "./fileUpload/reducer";
import caseStatus from "./caseStatus/reducer";
import caseListCheckBox from "./caseListCheckbox/reducer";
import modals from "./modals/reducer";
import user from "./user/reducer";
import resultsAggregation from "./resultsAggregation/reducer";
import cpuBalance from "./cpuBalance/reducer";
import caseFiles from "./caseFiles/reducer";
import caseFilesInfo from "./caseFilesInfo/reducer";
import projects from "./projects/reducer";
import newExperiment from "./newExperiment/reducer";
import cases from "./cases/reducer";
import machines from "./machines/reducer";
import licenseServer from "./licenseServer/reducer";
import invoices from "./invoices/reducer";
import instantLogFile from "./instantLogFile/reducer";
import projectList from "./projectList/reducer";
import exampleReducer from "./exampleRtk/exampleReducer";
import caseFilesSimvantageReducer from "./caseFilesSimvantage/caseFilesSimvantageReducer";
import imgPreviewSimvantageReducer from "./imgPreviewSimvantage/imgPreviewSimvantageReducer";
import layoutReducer from "./layoutStore/layoutReducer";

/**
 * Combined reducer contains
 * the reducers created with vanilla redux
 * and reducers created using RTK (Redux Toolkit)
 */
export default combineReducers({
  heartbeat,
  featureFlags,
  shoppingCart,
  confirmEmail,
  fileUpload,
  caseStatus,
  caseListCheckBox,
  modals,
  user,
  resultsAggregation,
  cpuBalance,
  caseFiles,
  caseFilesInfo,
  projects,
  newExperiment,
  cases,
  machines,
  licenseServer,
  invoices,
  instantLogFile,
  projectList,
  exampleReducer,
  caseFilesSimvantageReducer,
  imgPreviewSimvantageReducer,
  layoutReducer,
});
