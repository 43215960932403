import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

/**
 * Common component for able user to select something form dropdown
 * passes ...props to native select
 */

const SelectInput = ({ name, label, onChange, defaultOption, value, error, options, className, ...props }) => {
  return (
    <div className={classnames("version-selector", "form-group", className)}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className="field">
        {/* Note, value is set here rather than on the option - docs: https://facebook.github.io/react/docs/forms.html */}
        <select name={name} value={value} onChange={onChange} className="form-control" {...props}>
          {defaultOption && <option value="">{defaultOption}</option>}
          {options !== "No values"
            ? options.map(option => {
                return (
                  <option key={option.value} value={option.value}>
                    {option.text}
                  </option>
                );
              })
            : ""}
        </select>
        {error && <div className="alert alert-danger">{error}</div>}
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultOption: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
};

export default SelectInput;
