/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/**
 * Find differences between 2 json using as
 * reference the jsonFile1 parameter
 * @returns
 */
export function findJsonDifferences(jsonFile1: {}, jsonFile2: {}) {
  const diff = {};
  const base = jsonFile1;
  const uploaded = jsonFile2;

  const deepCompare = (
    baseObj: { [x: string]: any; hasOwnProperty?: any },
    uploadedObj: { [x: string]: any; hasOwnProperty: (arg0: string) => any },
    currentPath = "",
  ) => {
    for (const key in baseObj) {
      if (baseObj.hasOwnProperty(key)) {
        // get label from field name
        const matches2 = key.match(/\[(.*?)]/);
        const labelInnerObject = key.replace(matches2?.[0], "").trim();

        const newPath = currentPath ? `${currentPath} > ${labelInnerObject}` : labelInnerObject;

        if (!uploadedObj.hasOwnProperty(key)) {
          diff[newPath] = baseObj[key];
        } else if (typeof baseObj[key] === "object" && typeof uploadedObj[key] === "object") {
          deepCompare(baseObj[key], uploadedObj[key], newPath);
        } else if (baseObj[key] !== uploadedObj[key]) {
          diff[newPath] = baseObj[key];
        }
      }
    }
  };

  deepCompare(base, uploaded);
  return diff;
}
