// action types
export const TOGGLE_CONFIRM_RUN_MODAL = "modals/TOGGLE_CONFIRM_RUN_MODAL";
export const TOGGLE_TERMINATE_RUN_MODAL = "modals/TOGGLE_TERMINATE_RUN_MODAL";
export const TOGGLE_DELETE_CASES_MODAL = "modals/TOGGLE_DELETE_CASES_MODAL";
export const TOGGLE_ADD_USER_MODAL = "modals/TOGGLE_ADD_USER_MODAL";

export const SHOW_MESSAGE_DIALOG = "modals/SHOW_MESSAGE_DIALOG";
export const CLOSE_MESSAGE_DIALOG = "modals/CLOSE_MESSAGE_DIALOG";

// actions
export const toggleConfirmRunModal = () => ({
  type: TOGGLE_CONFIRM_RUN_MODAL,
});

export const toggleTerminateRunModal = () => ({
  type: TOGGLE_TERMINATE_RUN_MODAL,
});

export const toggleDeleteCasesModal = () => ({
  type: TOGGLE_DELETE_CASES_MODAL,
});

export const toggleAddUserModal = () => ({
  type: TOGGLE_ADD_USER_MODAL,
});

export const showMessageDialog = (header, message) => ({
  type: SHOW_MESSAGE_DIALOG,
  payload: {
    message,
    header,
  },
});

export const closeMessageDialog = () => ({
  type: CLOSE_MESSAGE_DIALOG,
});
