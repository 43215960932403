import { SET_USER_DATA, SUBSCRIBE_UPDATE, SUBSCRIBE_ERROR } from "./actions";

/**
 * Set user data like _id to store
 * @param {*} payload
 */
export const setUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_USER_DATA: {
      return { ...state, ...payload };
    }
    case SUBSCRIBE_UPDATE: {
      const { userId, showTutorial } = payload;
      return {
        ...state,
        userId,
        showTutorial,
      };
    }

    case SUBSCRIBE_ERROR: {
      const { userId, error } = payload;
      return {
        ...state,
        userId,
        error,
      };
    }

    default:
      return state;
  }
};

export const getUserId = (state) => state.user._id;
export const getEmailNotification = (state) => state.user.emailNotifications;
export const getOrganisationId = (state) => state.user.organizationId;
export const getIsAdmin = (state) => state.user?.roles?.includes("admin");

export const getShowTutorial = (state) => state.user.showTutorial;
