import { useSelector } from "react-redux";
import PropTypes from "prop-types";

import { getSomeFeatureFlags } from "../../store/featureFlags/reducer";

const FeatureToggle = ({ flag, children = null, hide = false, activeComponent = null, inactiveComponent = null }) => {
  const isFeatureEnabled = useSelector(state => getSomeFeatureFlags(state, flag));
  if (isFeatureEnabled && activeComponent) return activeComponent;
  if (!isFeatureEnabled && inactiveComponent) return inactiveComponent;
  // this is used when you need to show children when feature is enabled
  if (isFeatureEnabled && !hide) return children;
  // this is used essentially when you need to hide children when feature is disabled
  if (!isFeatureEnabled && hide) return children;
  return null;
};

FeatureToggle.propTypes = {
  flag: PropTypes.string.isRequired,
  children: PropTypes.node,
  hide: PropTypes.bool,
  activeComponent: PropTypes.node,
  inactiveComponent: PropTypes.node,
};

export default FeatureToggle;
