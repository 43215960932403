import { SAVE_EMAIL } from "./actions";

// confirmEmail reducer
export default function confirmEmailReducer(state = {}, action) {
  const { type, payload } = action;
  switch (type) {
    case SAVE_EMAIL: {
      const { email } = payload;
      return {
        ...state,
        email,
      };
    }

    default:
      return state;
  }
}
