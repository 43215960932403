/**
 * Check if there is a new build
 * and trigger the update action
 * @param {Object} installingWorker
 * @param {Object} config
 * @param {Object} registration
 */
function checkNewBuild(installingWorker, config, registration) {
  if (installingWorker.state === "installed") {
    if (navigator.serviceWorker.controller) {
      // At this point, the updated precached content has been fetched,
      // but the previous service worker will still serve the older
      // content until all client tabs are closed.
      console.log(
        "New content is available and will be used when all " +
          "tabs for this page are closed. See https://bit.ly/CRA-PWA.",
      );

      // Execute callback
      if (config && config.onUpdate) {
        config.onUpdate(registration);
      }
    } else {
      // At this point, everything has been precached.
      // It's the perfect time to display a
      // "Content is cached for offline use." message.
      console.log("Content is cached for offline use.");

      // Execute callback
      if (config && config.onSuccess) {
        config.onSuccess(registration);
      }
    }
  }
}

/**
 * Listener to detect changes on the react build
 * @param {Object} registration
 * @param {Object config
 */
export function onUpdateListener(registration, config) {
  // eslint-disable-next-line no-param-reassign
  registration.onupdatefound = () => {
    const installingWorker = registration.installing;
    if (installingWorker == null) {
      return;
    }
    installingWorker.onstatechange = () => checkNewBuild(installingWorker, config, registration);
  };
}

export function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then(registration => onUpdateListener(registration, config))
    .catch(error => {
      console.error("Error during service worker registration:", error);
    });
}

/**
 * Ensure service worker exists, and that we really are getting a JS file.
 */
function checkIfJSFile(response, swUrl, config) {
  const contentType = response.headers.get("content-type");
  if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
    // No service worker found. Probably a different app. Reload the page.
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister().then(() => {
        window.location.reload();
      });
    });
  } else {
    // Service worker found. Proceed as normal.
    registerValidSW(swUrl, config);
  }
}
/**
 * Check if service worker is valid
 * @param {Object} swUrl
 * @param {Object} config
 */
export function checkValidServiceWorker(swUrl, config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: { "Service-Worker": "script" },
  })
    .then(response => checkIfJSFile(response, swUrl, config))
    .catch(() => {
      console.log("No internet connection found. App is running in offline mode.");
    });
}

export default onUpdateListener;
