import axios from "axios";
import config from "react-global-configuration";

/**
 * This file contains monkeyPatched axios methods for CRUD operations
 * Basically we wrap it's GET POST PUT DELETE methods to provide our composed endpoints
 * and data to pass to BE
 */

const getAuthHeader = () => {
  const token = localStorage.getItem("token");
  return {
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Axios requests to the API
 * @param {String} path
 * @param {String} param
 */
export const getRequest = (route: string, params = "") => {
  const baseUri = config.get("apiBaseURL");
  const url = baseUri + route + params;
  return axios.get(url, { headers: getAuthHeader() });
};

export const postRequest = (route: string, data?: any) => {
  const baseUri = config.get("apiBaseURL");
  const url = baseUri + route;
  return axios.post(url, data, { headers: getAuthHeader() });
};

export const putRequest = (route: string, data: any, avoidBaseUri: boolean) => {
  const baseUri = config.get("apiBaseURL");
  let url = route;

  /**
   * Avoid base url is needed when we what to make a request on an url, that not related to our host
   * For example att file uploading, firstly we create an url on cloud storage, then we upload file into it
   */
  if (!avoidBaseUri) {
    url = baseUri + url;
  }

  return axios.put(url, data, { headers: getAuthHeader() });
};

export const deleteRequest = (route: string, id: any) => {
  const baseUri = config.get("apiBaseURL");
  const url = baseUri + route + id;
  return axios.delete(url, { headers: getAuthHeader() });
};

/**
 * Ping request to the API
 */
export const pingAPIRequest = () => {
  const baseUri = config.get("apiBaseURL");
  const url = `${baseUri}/ping`;
  return axios.get(url);
};

/**
 * Refresh token request
 */
export const refreshTokenAPI = () => {
  const baseUri = config.get("apiBaseURL");
  const token = localStorage.getItem("token");

  const url = `${baseUri}/auth/refreshToken/${token}`;
  if (token) {
    return axios.post(
      url,
      { token },
      {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      },
    );
  }
  return Promise.resolve();
};

export const getConfigByUrl = (host: string) => {
  const baseUri = config.get("apiBaseURL");

  const url = `${baseUri}/domain-config/`;
  return axios.post(
    url,
    { host },
    {
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );
};

export default getRequest;
