// action types
export const INIT_CASE = "caseListCheckBox/INIT_CASE";
export const INIT_ALL_CASES = "caseListCheckBox/INIT_ALL_CASES";
export const SELECT_ALL = "caseListCheckBox/SELECT_ALL";
export const UNDO_SELECT_ALL = "caseListCheckBox/UNDO_SELECT_ALL";
export const SELECT_OPEN = "caseListCheckBox/SELECT_OPEN";
export const SELECT_RUNNING = "caseListCheckBox/SELECT_RUNNING";
export const SELECT_COMPLETED = "caseListCheckBox/SELECT_COMPLETED";
export const SELECT_FAILED = "caseListCheckBox/SELECT_FAILED";
export const SELECT_CASE = "caseListCheckBox/SELECT_CASE";
export const UNDO_SELECT_CASE = "caseListCheckBox/UNDO_SELECT_CASE";
export const RESET_STATE = "caseListCheckBox/RESET_STATE";
export const REMOVE_CASE = "caseListCheckBox/REMOVE_CASE";
export const REMOVE_CASES = "caseListCheckBox/REMOVE_CASES";

/**
 *
 * @param {*} caseId
 * @param {*} checked
 * @param {*} caseRunning
 * @param {*} fileUploading
 */
// action creators
export const initCase = (caseId, fileId) => ({
  type: INIT_CASE,
  payload: { caseId, fileId },
});
export const initAllCases = cases => ({
  type: INIT_ALL_CASES,
  payload: cases,
});
export const selectAll = () => ({
  type: SELECT_ALL,
});

export const undoSelectAll = () => ({
  type: UNDO_SELECT_ALL,
});

export const selectOpen = store => ({
  type: SELECT_OPEN,
  payload: { store },
});

export const selectRunning = store => ({
  type: SELECT_RUNNING,
  payload: { store },
});

export const selectCompleted = store => ({
  type: SELECT_COMPLETED,
  payload: { store },
});

export const selectFailed = store => ({
  type: SELECT_FAILED,
  payload: { store },
});

export const selectSingleCase = caseId => ({
  type: SELECT_CASE,
  payload: { caseId },
});

export const undoSelectSingleCase = caseId => ({
  type: UNDO_SELECT_CASE,
  payload: { caseId },
});

export const resetCheckBoxState = () => ({
  type: RESET_STATE,
});

export const removeCaseCheckBox = caseId => ({
  type: REMOVE_CASE,
  payload: { caseId },
});

export const removeCaseCheckBoxes = cases => ({
  type: REMOVE_CASES,
  payload: { cases },
});
