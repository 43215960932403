import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Important! this code is used only
 * as an example for adding RTK (Redux Toolkit)
 * reducers
 */
export interface ExampleState {
  field1: string;
  field2: boolean;
  field3: number;
}

const initialState: ExampleState = {
  field1: "",
  field2: true,
  field3: 0,
};

export const exampleStateSlice = createSlice({
  name: "exampleState",
  initialState,
  reducers: {
    updateExampleState: (state, action: PayloadAction<{ field1: string; field2: boolean;field3:number }>) => {
      state.field1 = action.payload.field1;
      state.field2 = action.payload.field2;
      state.field3 = action.payload.field3;
    },
  },
});

export const { updateExampleState } = exampleStateSlice.actions;

export default exampleStateSlice.reducer;
