import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { connect } from "react-redux";

import { getMessageDialog } from "../../store/modals/reducer";
import { closeMessageDialog } from "../../store/modals/actions";

function ConnectedMsgDialog({ dialogData, handleClose }) {
  const { isOpen, header, message } = dialogData;
  return (
    <>
      <Modal className="modal-primary modal-dialog-centered" isOpen={isOpen} onClose={handleClose}>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          <h4>{message}</h4>
        </ModalBody>
        <ModalFooter>
          <Button onClick={handleClose}>OK</Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

const mapStateToProps = state => ({
  dialogData: getMessageDialog(state),
});

const mapDispatchToProps = {
  handleClose: closeMessageDialog,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConnectedMsgDialog);
