// Action types

export const SUBSCRIBE_UPDATE = "cpuBalance/SUBSCRIBE_UPDATE";
export const SUBSCRIBE_ERROR = "cpuBalance/SUBSCRIBE_ERROR";

// Action creators
export const subscribeUpdate = (organizationId, vmBalance) => ({
  type: SUBSCRIBE_UPDATE,
  payload: { organizationId, vmBalance },
});

export const subscribeError = (organizationId, error) => ({
  type: SUBSCRIBE_ERROR,
  payload: { organizationId, error },
});
