import {
  GET_CASES_START,
  GET_CASES_SUCCESS,
  GET_CASES_FAILURE,
  REMOVE_FROM_LIST,
  GET_SINGLE_CASE_START,
  GET_SINGLE_CASE_SUCCESS,
  GET_SINGLE_CASE_FAILURE,
  DELETE_CASES_START,
  DELETE_CASES_SUCCESS,
  DELETE_CASES_FAILURE,
  SET_CURRENT_PAGE,
  PROCESS_AIBAT_CASE_START,
  PROCESS_AIBAT_CASE_SUCCESS,
  PROCESS_AIBAT_CASE_FAILURE,
  RESET_TABLE_LIST,
  GET_TOTAL_NUMBER_OF_CASES_START,
  GET_TOTAL_NUMBER_OF_CASES_SUCCESS,
  GET_TOTAL_NUMBER_OF_CASES_FAILURE,
} from "./actions";

/* initial state */
export const initialState = {
  list: [],
  currentPageNumber: 0,
  listLoading: false,
  singleCase: {},
  singleCaseLoading: false,
  casesDeleting: [],
  processAibatCaseLoading: false,
  totalNumberOfCases: 0,
  loadingTotalNumberOfCases: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_CASES_START: {
      return {
        ...state,
        listLoading: true,
      };
    }
    case GET_CASES_SUCCESS: {
      const { list } = payload;
      return {
        ...state,
        listLoading: false,
        list,
      };
    }
    case GET_CASES_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        listLoading: false,
        getListError: error,
      };
    }

    case GET_TOTAL_NUMBER_OF_CASES_START: {
      return {
        ...state,
        loadingTotalNumberOfCases: true,
      };
    }
    case GET_TOTAL_NUMBER_OF_CASES_SUCCESS: {
      const { totalNumberOfCases } = payload;
      return {
        ...state,
        loadingTotalNumberOfCases: false,
        totalNumberOfCases,
      };
    }
    case GET_TOTAL_NUMBER_OF_CASES_FAILURE: {
      return {
        ...state,
        loadingTotalNumberOfCases: false,
        totalNumberOfCases: 0,
      };
    }

    case GET_SINGLE_CASE_START: {
      return {
        ...state,
        singleCaseLoading: true,
      };
    }
    case GET_SINGLE_CASE_SUCCESS: {
      const { singleCase } = payload;
      return {
        ...state,
        singleCaseLoading: false,
        singleCase,
      };
    }
    case GET_SINGLE_CASE_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        singleCaseLoading: false,
        getSingleCaseError: error,
      };
    }

    case DELETE_CASES_START: {
      const { caseIds } = payload;
      return {
        ...state,
        casesDeleting: caseIds,
      };
    }
    case DELETE_CASES_SUCCESS: {
      return {
        ...state,
        casesDeleting: [],
      };
    }
    case DELETE_CASES_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        casesDeleting: [],
        caseDeleteError: error,
      };
    }

    case PROCESS_AIBAT_CASE_START: {
      return {
        ...state,
        processAibatCaseLoading: true,
      };
    }
    case PROCESS_AIBAT_CASE_SUCCESS: {
      return {
        ...state,
        processAibatCaseLoading: false,
      };
    }
    case PROCESS_AIBAT_CASE_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        processAibatCaseLoading: false,
        processAibatCaseError: error,
      };
    }

    case REMOVE_FROM_LIST: {
      const { caseIds } = payload;
      return {
        ...state,
        list: state.list.filter(item => !caseIds.includes(item.caseId)),
      };
    }

    case SET_CURRENT_PAGE: {
      const { currentPageNumber } = payload;
      return {
        ...state,
        currentPageNumber,
      };
    }
    case RESET_TABLE_LIST: {
      return { ...state, list: [], totalNumberOfCases: 0, loadingTotalNumberOfCases: false };
    }

    default:
      return state;
  }
}
