import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import styled from "styled-components";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { useMutation } from "@apollo/client";
import NumberInput from "../../common/NumberInput";
import { loginWithToken, disableMultiFactorAuth, sendTwoFactorAuthDisabledEmail } from "../../../queries/user-queries";

/**
 * Modal that displays the NumberInput where user has to write the 6 digit token
 * It either does a loginWithToken or disableMultiFactorAuth
 * @param {userId, show, shouldDisableTwoFactorAuth, handleTwoFactorAuthStatus} param0
 * userId: current userId
 * show: if modal should be shown
 * shouldDisableTwoFactorAuth: boolean to decide if call login or disable mutation
 * handleTwoFactorAuthStatus: function from parent to do handle changes
 */
function InputCodeModal({ userId, show, shouldDisableTwoFactorAuth, handleTwoFactorAuthStatus }) {
  const [modal, setModal] = useState(show);
  const [token, setToken] = useState("");

  const [loginUsingToken] = useMutation(loginWithToken);
  const [disableAuthMutation] = useMutation(disableMultiFactorAuth);
  const [sendEmail] = useMutation(sendTwoFactorAuthDisabledEmail);

  const onSubmitCode = async () => {
    let response;
    if (shouldDisableTwoFactorAuth) {
      try {
        response = await disableAuthMutation({
          variables: {
            userId,
            token,
            shouldDisable: shouldDisableTwoFactorAuth,
          },
        });
        if (!response.data.disableMultiFactorAuth) {
          toast.error("The entered code is incorrect. Please try again");
          return;
        }
        const { data } = await sendEmail({
          variables: {
            userId,
          },
        });
        if (data.sendTwoFactorAuthDisabledEmail) {
          toast.success("Two Factor Auth has been disabled");
          handleTwoFactorAuthStatus(false);
        }
      } catch (err) {
        console.error(err);
      }
    } else {
      try {
        response = await loginUsingToken({
          variables: {
            userId,
            token,
            shouldDisable: shouldDisableTwoFactorAuth,
          },
        });
        if (!response.data.loginWithToken.success) {
          toast.error(response.data.loginWithToken.message);
          return;
        }
        handleTwoFactorAuthStatus(true);
      } catch (err) {
        console.error(err);
      }
    }
  };

  const toggleModal = useCallback(() => {
    handleTwoFactorAuthStatus(null);
    setModal(!modal);
  }, [handleTwoFactorAuthStatus, modal]);

  // function to get the current value for the input
  const onInputChange = e => {
    setToken(e.target.value);
  };

  return (
    <Modal isOpen={modal} toggle={toggleModal} backdrop={false} className="modal-primary modal-dialog-centered">
      <ModalHeader>Two Factor Authentication </ModalHeader>
      <ModalBody className="modal-body">
        <h5>Write your 6-digit code below</h5>
        <InputCodeModalDiv>
          <NumberInput onInputChange={onInputChange} maxLength={6} placeholder="Code" />
        </InputCodeModalDiv>
      </ModalBody>
      <ModalFooter>
        {!shouldDisableTwoFactorAuth ? (
          <Link to={{ pathname: "/login/recovery-code", userId }}>
            <Button color="info">Use Recovery Code</Button>
          </Link>
        ) : null}
        <Button color="secondary" onClick={toggleModal}>
          Dismiss
        </Button>
        <Button color="primary" onClick={onSubmitCode}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const InputCodeModalDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

InputCodeModal.propTypes = {
  handleTwoFactorAuthStatus: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  show: PropTypes.bool,
  shouldDisableTwoFactorAuth: PropTypes.bool.isRequired,
};

export default InputCodeModal;
