/* eslint-disable react/forbid-prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button, Card, CardBody, Col, Form, Row } from "reactstrap";
import LoginTextInput from "./LoginTextInput";
import LoginTextComp from "./LoginTextComp";
import CheckingYourConnectionPage from "../checking-connection/CheckingYourConnectionPage";

/**
 * Loading parameter indicates that the process to login is being executed
 * once the request is completed loading is set to false
 * @param {boolean} loading
 */
function LoginForm({ handleSubmit, errors = {}, formArgs, loading }) {
  const args = formArgs;
  let errorContent = "";
  const handleChange = (event) => {
    args[event.target.name] = event.target.value;
  };

  if (errors.length > 0) {
    errorContent = <div className="alert alert-danger">{errors[0].message}</div>;
  }

  return (
    <>
      <CheckingYourConnectionPage openModal={loading} />
      <Card className="p-4">
        <CardBody>
          <Form onSubmit={(e) => handleSubmit(e, args)} data-test-id="login-form">
            {errorContent}
            <h1>Login</h1>
            <LoginTextComp />

            <LoginTextInput
              groupClass="mb-3"
              required
              type="text"
              name="email"
              placeholder="Email"
              autoComplete="email"
              onChange={handleChange}
              icon="fa fa-at"
              data-test-id="login-form-email"
            />

            <LoginTextInput
              groupClass="mb-4"
              required
              type="password"
              name="password"
              placeholder="Password"
              autoComplete="current-password"
              onChange={handleChange}
              icon="fa fa-key"
              data-test-id="login-form-password"
            />

            <br />
            <Row>
              <Col xs="6">
                <Button type="submit" color="primary" className="px-4" data-test-id="login-form-submit">
                  {loading ? <i className="fa fa-spinner fa-spin fa-1x fa-fw" /> : null}
                  &nbsp;Login
                </Button>
              </Col>
              <ResetPassword to="/forgot-password">Forgot Password?</ResetPassword>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
}

const ResetPassword = styled(Link)`
  float: right;
  padding: 0.5;
`;

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errors: PropTypes.array,
  formArgs: PropTypes.array,
};

export default LoginForm;
