import { ADD_TO_QUEUE, MOVE_TO_INPROGRESS, MOVE_TO_DONE, UPDATE_PROGRESS, ALL_DONE } from "./actions";

// REDUCER

const initialState = {
  queue: [],
  inprogress: [],
  done: [],
  files: {},
  uploading: false,
  uploaded: false,
  maxUploadsAtTime: 6,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TO_QUEUE: {
      const { files } = payload;
      return {
        ...state,
        queue: [...state.queue, ...files],
        uploading: true,
        uploaded: false,
        files: {
          ...state.files,
          ...files.reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: {
                progress: 0,
                uploading: false,
                pending: true,
                finished: false,
              },
            }),
            {},
          ),
        },
      };
    }
    case MOVE_TO_INPROGRESS: {
      const { number } = payload;
      const itemsToMove = state.queue.slice(0, number);
      return {
        ...state,
        inprogress: [...state.inprogress, ...itemsToMove],
        queue: state.queue.slice(number),
        files: {
          ...state.files,
          ...itemsToMove.reduce(
            (obj, item) => ({
              ...obj,
              [item.id]: {
                ...state.files[item.id],
                uploading: true,
                pending: false,
              },
            }),
            {},
          ),
        },
      };
    }
    case MOVE_TO_DONE: {
      const { fileId } = payload;
      return {
        ...state,
        inprogress: state.inprogress.filter(item => item.id !== fileId),
        done: [...state.done, ...state.inprogress.filter(item => item.id === fileId)],
        files: {
          ...state.files,
          [fileId]: {
            ...state.files[fileId],
            uploading: false,
            finished: true,
          },
        },
      };
    }
    case UPDATE_PROGRESS: {
      const { fileId, info } = payload;
      const { totalBytes, uploadedBytes } = info;
      const progress = parseInt((uploadedBytes / totalBytes) * 100, 10);
      return {
        ...state,
        files: {
          ...state.files,
          [fileId]: {
            ...state.files[fileId],
            progress,
            uploading: true,
            pending: false,
          },
        },
      };
    }
    case ALL_DONE: {
      return {
        ...state,
        uploading: false,
        uploaded: true,
      };
    }

    default:
      return state;
  }
};

// SELECTORS

export const getMaxUploadsAtTime = state => state.fileUpload.maxUploadsAtTime;
export const getInProgress = state => state.fileUpload.inprogress;
export const getInQueue = state => state.fileUpload.queue;
export const getFileData = (state, fileId) => state.fileUpload.files[fileId] || {};
export const getIsUploading = state => state.fileUpload.uploading;
export const getItemsToUpload = (state, diff) => state.fileUpload.queue.slice(0, diff);
