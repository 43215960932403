// action types
export const GENERAL_STATE_UPDATE = "GENERAL_STATE_UPDATE";
export const SET_MACHINE = "SET_MACHINE";
export const SET_CPU = "SET_CPU";
export const RESET_STATE = "RESET_STATE";
export const SET_FILES = "SET_FILES";
export const SET_JSON_DATA = "SET_JSON_DATA";
export const DELETE_FILE = "DELETE_FILE";
export const SET_SLIDER_VALUE = "SET_SLIDER_VALUE";
export const SET_SOLVER_NAME = "SET_SOLVER_NAME";
export const SET_DETAILS_FOR_EDITING = "SET_DETAILS_FOR_EDITING";
export const SET_GPU_TYPE = "SET_GPU_TYPE";
export const SET_EMAIL_NOTIFICATION = "SET_EMAIL_NOTIFICATION";

export const SET_JSON_PARAMETER = "SET_JSON_PARAMETERSET_JSON_PARAMETER";
export const SET_DEFAULT_JSON_PARAMETER = "SET_DEFAULT_JSON_PARAMETER";
export const REMOVE_JSON_FILE = "REMOVE_JSON_FILE";

export const DISPLAY_GPU_COMPONENT = "DISPLAY_GPU_COMPONENT";
export const ENABLED_SESES = "ENABLED_SESES";

export const SET_SOFTWARE_LICENSE = "SET_SOFTWARE_LICENSE";

export const SET_LICENSE_SERVER_NAME = "SET_LICENSE_SERVER_NAME";

export const EXPAND_ALL_ROWS_SIMVANTAGE = "EXPAND_ALL_ROWS_SIMVANTAGE";
export const COLLAPSE_ALL_ROWS_SIMVANTAGE = "COLLAPSE_ALL_ROWS_SIMVANTAGE";