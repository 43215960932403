import validateFile from "../../context/createCase/validateFile";

import {
  SET_SOME_FIELDS,
  SET_EXPERIMENT_CASE_NAME,
  SET_EXPERIMENT_SOLVER_NAME,
  SET_EXPERIMENT_MACHINE,
  SET_DECOMPOSE_TO,
  SET_RUN_SCRIPT,
  SET_DECOMPOSE_PAR_DICT,
  SET_EXPERIMENT_PARAMETERS,
  SET_EXPERIMENT_PARAMETERS_VALID,
  SET_PARAMETERS_ITERATION,
  SET_RECEIVE_EMAILS,
  SET_FILE_UPLOAD_PROGRESS,
  SET_CASE_INPUT_PARAM,
  SET_CASE_INPUT_PARAM_VALID,
  RESET_STATE,
  REMOVE_PARAMETERS_ROW,
  ADD_FILE,
  REMOVE_FILE,
  GET_SOLVERS_START,
  GET_SOLVERS_SUCCESS,
  GET_SOLVERS_FAILURE,
  GET_MACHINES_START,
  GET_MACHINES_SUCCESS,
  GET_MACHINES_FAILURE,
  CREATE_NEW_EXPERIMENT_START,
  CREATE_NEW_EXPERIMENT_SUCCESS,
  CREATE_NEW_EXPERIMENT_FAILURE,
  CREATE_NEW_CASE_START,
  CREATE_NEW_CASE_SUCCESS,
  CREATE_NEW_CASE_FAILURE,
} from "./actions";
import { initialState } from "./utils/initialReducerState";
import { getRunScript } from "./utils/getRunScript";

// reducer for new experiment page
export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SOME_FIELDS: {
      const { data } = payload;
      return {
        ...state,
        ...data,
      };
    }
    case SET_EXPERIMENT_CASE_NAME: {
      const { caseName } = payload;
      return {
        ...state,
        caseName,
        errors: {
          ...state.errors,
          caseName: caseName ? "" : "Case name is required",
        },
      };
    }
    case SET_EXPERIMENT_SOLVER_NAME: {
      const { solverName, isSesesEnabled } = payload;

      return {
        ...state,
        solverName,
        runScript: getRunScript({ ...state, solverName }, isSesesEnabled),
        errors: {
          ...state.errors,
          solverName: solverName ? "" : "Solver is required",
        },
      };
    }
    case SET_EXPERIMENT_MACHINE: {
      const { machine, isSesesEnabled } = payload;

      const decomposeTo = machine.cpu > 1 ? "1" : "0";

      return {
        ...state,
        runScript: getRunScript({ ...state, machine, decomposeTo }, isSesesEnabled),
        decomposeTo,
        machine,
      };
    }
    case SET_DECOMPOSE_TO: {
      const { decomposeTo, isSesesEnabled } = payload;

      return {
        ...state,
        decomposeTo,
        runScript: getRunScript({ ...state, decomposeTo }, isSesesEnabled),
      };
    }
    case SET_RUN_SCRIPT: {
      const { runScript } = payload;
      return {
        ...state,
        runScript,
      };
    }
    case SET_DECOMPOSE_PAR_DICT: {
      const { decomposeParDict } = payload;
      return {
        ...state,
        decomposeParDict,
      };
    }
    case SET_EXPERIMENT_PARAMETERS: {
      const { experimentParameters } = payload;
      let updatedParameters = [];

      if (state.experimentParameters.length === experimentParameters.index) {
        // then add a new row
        updatedParameters = state.experimentParameters.concat(experimentParameters.data);
      } else {
        // then edit an existing one
        updatedParameters = state.experimentParameters.map((item, index) => {
          if (experimentParameters.setAllRows) {
            return {
              ...item,
              ...experimentParameters.data,
            };
          }

          if (index === experimentParameters.index) {
            return {
              ...item,
              ...experimentParameters.data,
            };
          }

          return item;
        });
      }

      return {
        ...state,
        experimentParameters: updatedParameters,
      };
    }
    case SET_EXPERIMENT_PARAMETERS_VALID: {
      const { experimentParamsValid } = payload;
      return {
        ...state,
        experimentParamsValid,
      };
    }
    case SET_PARAMETERS_ITERATION: {
      const { iterationType } = payload;
      const { experimentParameters } = state;

      return {
        ...state,
        iterationType,
        experimentParameters:
          iterationType === "sets"
            ? experimentParameters.map(item => ({ ...item, setsStepSize: experimentParameters[0].iteratedStepSize }))
            : experimentParameters,
      };
    }
    case REMOVE_PARAMETERS_ROW: {
      const { index } = payload;

      return {
        ...state,
        experimentParameters: state.experimentParameters.filter((_, existingIndex) => existingIndex !== index),
      };
    }
    case SET_RECEIVE_EMAILS: {
      const { receiveEmails } = payload;

      return {
        ...state,
        receiveEmails,
      };
    }
    case SET_FILE_UPLOAD_PROGRESS: {
      const { fileUploadProgress } = payload;

      return {
        ...state,
        fileUploadProgress,
      };
    }
    case SET_CASE_INPUT_PARAM: {
      const { param } = payload;

      return {
        ...state,
        caseInputParameter: state.caseInputParameter.map((item, index) => {
          if (index === param.index) {
            return {
              path: param.path,
              variableName: param.variableName,
              value: param.value,
            };
          }

          return item;
        }),
      };
    }
    case SET_CASE_INPUT_PARAM_VALID: {
      const { valid } = payload;

      return {
        ...state,
        caseInputParameterValid: valid,
      };
    }
    case RESET_STATE: {
      return {
        ...initialState,
        fileUploadProgress: state.fileUploadProgress || initialState.fileUploadProgress,
      };
    }
    case ADD_FILE: {
      const { files } = payload;
      const file = files[0];
      const isValid = validateFile(file.name);
      return {
        ...state,
        file: isValid ? file : null,
        errors: {
          ...state.errors,
          file: isValid ? "" : "Only .zip files are allowed.",
        },
      };
    }
    case REMOVE_FILE: {
      return {
        ...state,
        file: null,
        errors: {
          ...state.errors,
          file: "",
        },
      };
    }

    case GET_SOLVERS_START: {
      return {
        ...state,
        solversLoading: true,
      };
    }
    case GET_SOLVERS_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        solversLoading: false,
        solvers: data,
      };
    }
    case GET_SOLVERS_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        solversLoading: false,
        solversError: error,
      };
    }

    case GET_MACHINES_START: {
      return {
        ...state,
        machinesLoading: true,
      };
    }
    case GET_MACHINES_SUCCESS: {
      const { data } = payload;
      return {
        ...state,
        machinesLoading: false,
        machines: data,
      };
    }
    case GET_MACHINES_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        machinesLoading: false,
        machinesError: error,
      };
    }

    case CREATE_NEW_EXPERIMENT_START: {
      return {
        ...state,
        createNewExperimentLoading: true,
      };
    }
    case CREATE_NEW_EXPERIMENT_SUCCESS: {
      return {
        ...state,
        createNewExperimentLoading: false,
      };
    }
    case CREATE_NEW_EXPERIMENT_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        createNewExperimentLoading: false,
        newExperimentError: error,
      };
    }

    case CREATE_NEW_CASE_START: {
      return {
        ...state,
        createNewCaseLoading: true,
      };
    }
    case CREATE_NEW_CASE_SUCCESS: {
      return {
        ...state,
        createNewCaseLoading: false,
      };
    }
    case CREATE_NEW_CASE_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        createNewCaseLoading: false,
        newCaseError: error,
      };
    }
    default:
      return state;
  }
}
