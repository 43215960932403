/* eslint-disable react/forbid-prop-types */
import React from "react";
import { useSelector } from "react-redux";
import { getFeatureFlag } from "../../../store/featureFlags/reducer";
import { COMSOL } from "../../../featureFlags";

/**
 * Loading parameter indicates that the process to login is being executed
 * once the request is completed loading is set to false
 * @param {boolean} loading
 */
// TODO: https://phab.kaleidosim.com/T2081
const LoginTextComp = () => {
  const isComsolEnabled = useSelector(state => getFeatureFlag(state, COMSOL));
  if (isComsolEnabled) {
    return <p className="text-muted">Sign In to your cloudCompanion account</p>;
  }
  return <p className="text-muted">Sign In to your account</p>;
};

export default LoginTextComp;
