import { gql } from "@apollo/client";
// Query file:  queries/local-queries.ts

export const typeDefs = gql`
  extend type stateRun {
    json: String!
    inputFile: String
  }
  extend type counter {
    cases: Int!
  }
  extend type dataRun {
    caseName: String
    decomposeParameter: String
    parallel: Boolean
    recomposeParameter: String
    cloudProvider: String
    script1: String
    script2: String
    solverName: String
    vmZone: String
    caseId: ID!
    projectId: ID
    vmCpu: Int
    vmMemory: Float
    vmMachineType: String
    vmUsageMultiplier: Float
    version: String
    inputFileId: ID
    friendlyName: String
    url: String
  }
  extend input idataRun {
    caseName: String
    parallel: Boolean
    decomposeParameter: String
    recomposeParameter: String
    cloudProvider: String
    solverName: String
    version: String
    script1: String
    script2: String
    vmZone: String
    caseId: ID!
    projectId: ID
    vmCpu: Int
    vmMemory: Float
    vmMachineType: String
    vmUsageMultiplier: Float
    inputFileId: ID
    friendlyName: String
    url: String
  }
  extend type Query {
    runState: stateRun
    getLocalNumberCases: counter!
    getLocalRunData: [dataRun!]
  }
  extend type Mutation {
    addSetRun(machineType: String!): Boolean!
    incrementCounter: Int!
    decrementCounter: Int!
    resetCounter: Int!
    setLocalRun(run: idataRun!): Boolean!
    updateLocalRun(run: idataRun!): Boolean!
    updateLocalFileId(url: String!, inputFileId: ID!): Boolean!
    restoreLocal: Boolean!
  }
`;
