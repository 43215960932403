import React from "react";
import { getKccDomain, isKccUrl, kccDomains } from "../../libs/kcc/domainUrls";
import {
  ComsolTheme,
  DefaultTheme,
  GeodictTheme,
  SesesTheme,
  SimFlowTheme,
  SimvantageTheme,
} from "../../themes/themes";

/**
 * function to load the Logo given a kcc domain
 * @param {String} host
 * @returns
 */
export function getKccTheme(host) {
  if (host !== null) {
    const isKcc = isKccUrl(host.url);
    if (isKcc) {
      const response = getKccDomain(host.url);
      switch (response) {
        case kccDomains.simvantage:
          return <SimvantageTheme />;
        case kccDomains.seses:
          return <SesesTheme />;
        case kccDomains.comsol:
          return <ComsolTheme />;
        case kccDomains.simflow:
          return <SimFlowTheme />;
        case kccDomains.geodict:
          return <GeodictTheme />;
        default:
          return <DefaultTheme />;
      }
    }
  }
  return null;
}
