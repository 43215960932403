import { NewExperimentStateType } from "./newExperimentStateType";

/**
 * Initial state for newExperiment reducer
 */

export const initialState: NewExperimentStateType = {
  caseName: "",
  solverName: "",
  experimentParameters: [
    {
      path: "",
      variableName: "",
      progressionType: "geometric",
      startValue: "",
      endValue: "",
      iteratedStepSize: "",
      setsStepSize: "",
    },
  ],
  caseInputParameter: [],
  experimentParamsValid: false,
  caseInputParameterValid: false,
  machine: {},
  decomposeTo: "0",
  runScript: "",
  decomposeParDict: "",
  iterationType: "iterated",
  errors: {},
  receiveEmails: true,
  file: {},
  fileUploadProgress: { totalBytes: 0, uploadedBytes: 0 },

  solversLoading: false,
  solvers: [],

  machinesLoading: false,
  machines: [],

  createNewExperimentLoading: false,
  createNewCaseLoading: false,
};
