import React, { useState, useCallback } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Alert } from "reactstrap";
import { useMutation } from "@apollo/client";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import styled from "styled-components";
import { confirmEmail } from "../../../queries/user-queries";

/**
 * SendEmail mutation allows to the user send a new email
 * to confirm his email address
 * email =>  and userId are used to send emails
 * modal => to display the modal dialog
 */
function ConfirmEmail({ email, userId, modal }) {
  const [open, setModal] = useState(modal);
  const [redirect, setRedirect] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [labelError, setErrorLabel] = useState(false);

  const toggle = () => setModal(!open);

  // send email
  const [sendEmail] = useMutation(confirmEmail);

  // redirect page to /login
  const redirectPage = useCallback(() => {
    setRedirect(true);
  });

  // send confirmation email again
  const sendEmailAgain = useCallback(async () => {
    setSpinner(true);
    try {
      await sendEmail({
        variables: {
          userId,
          email,
        },
      });
      setErrorLabel(false);
    } catch (e) {
      console.error(e);
      // displays error label
      setErrorLabel(true);
    }
    // stop spinner
    setSpinner(false);
  });

  return (
    <>
      {redirect && <Redirect to="/login" />}
      <Modal className="modal-primary modal-dialog-centered" isOpen={open} backdrop="static" toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <Icon className="fa fa-share" aria-hidden="true" />
          Confirmation email
        </ModalHeader>
        <ModalBody>
          Please check your email:
          <b>{email}</b>
          <br />
          {labelError && <Alert color="danger">Something went wrong, please try again :(</Alert>}
          If you did not received a confirmation email click
          <Button id="newEmail" color="link" onClick={sendEmailAgain}>
            {spinner ? <i className="fa fa-spinner fa-spin fa-1x fa-fw" /> : "here"}
          </Button>
          to receive a new email
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={redirectPage}>
            Back to login
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
const Icon = styled.i`
  padding: "0.2em";
`;
ConfirmEmail.propTypes = {
  modal: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
};
export default ConfirmEmail;
