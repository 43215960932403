import {
  CLEAN_UP_CASEFILES_INFO,
  SET_SELECTED_FILE_EXTENSIONS,
  SET_SELECTED_ALL_CASE_FILES,
  SET_SELECTED_COMMON_FILES,
  FETCH_BDS_URL_START,
  FETCH_BDS_URL_SUCCESS,
  FETCH_BDS_URL_ERROR,
  SELECT_ALL_FILE_EXTENSIONS,
} from "./actions";
import { getTotalFileExtensions } from "../caseFiles/utils";

/* initial state */
const initialState = {
  selected: [], // selected cases for BDS
  checked: [], // checked files on UI
  selectAll: false,
  totalSize: 0,
  totalFiles: 0,
  url: "",
  loading: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_SELECTED_FILE_EXTENSIONS: {
      const exts = state.selected.slice();

      if (state.selected.includes(payload.data)) {
        exts.splice(exts.indexOf(payload.data), 1);
      } else {
        exts.push(payload.data);
      }

      const { totalFiles, totalFileSize } = getTotalFileExtensions(exts, payload.files);

      return { ...state, selected: exts, checked: exts, totalSize: totalFileSize, totalFiles };
    }

    case SET_SELECTED_ALL_CASE_FILES: {
      return {
        ...state,
        selected: payload.data,
        checked: payload.checked,
        totalSize: payload.totalSize,
        totalFiles: payload.data.length,
      };
    }

    case SET_SELECTED_COMMON_FILES: {
      return {
        ...state,
        selected: payload.checked,
        checked: payload.data,
        totalSize: payload.totalSize,
        totalFiles: payload.totalFiles,
      };
    }

    case SELECT_ALL_FILE_EXTENSIONS: {
      if (state.selectAll) {
        return {
          ...state,
          selected: [],
          checked: [],
          totalSize: 0,
          totalFiles: 0,
          selectAll: false,
        };
      }

      return {
        ...state,
        selected: payload.data.ext,
        checked: payload.data.ext,
        totalSize: payload.data.totalSize,
        totalFiles: payload.data.totalFiles,
        selectAll: true,
      };
    }

    case CLEAN_UP_CASEFILES_INFO: {
      return { ...state, selected: [], checked: [], totalSize: 0, totalFiles: 0, selectAll: false };
    }

    case FETCH_BDS_URL_START: {
      return {
        ...state,
        loading: true,
      };
    }
    case FETCH_BDS_URL_SUCCESS: {
      return { ...state, url: payload.data.response, loading: false };
    }
    case FETCH_BDS_URL_ERROR: {
      const { error } = payload;
      return {
        ...state,
        url: error,
        loading: false,
      };
    }

    default:
      return state;
  }
}

/**
 * Return an array with all the file extensions
 * totalSize and totalFiles
 * @param {Object} state
 */
export const getAllExtensionFiles = state => {
  // validates loading state
  if (state.caseFiles.fileExtensions.loading) {
    return { ext: [], totalSize: 0, totalFiles: 0 };
  }

  // validates error state
  if (!state.caseFiles.fileExtensions.success) {
    return { ext: [], totalSize: 0, totalFiles: 0 };
  }

  const extensionArray = [];
  let totalSize = 0;
  let totalFiles = 0;

  state.caseFiles.fileExtensions.data.forEach(caseVal => {
    extensionArray.push(caseVal.fileExt);
    totalSize += caseVal.fileSize * 1000;
    totalFiles += caseVal.numberOfFiles;
  });

  return { ext: extensionArray, totalSize, totalFiles };
};
