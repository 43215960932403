// eslint-disable-next-line no-unused-vars
import React from "react";

import { isKccUrl, getKccDomain, kccDomains } from "../../libs/kcc/domainUrls";
import SimvantageIcon from "../../assets/img/simvantage/favicon.ico";
import SesesIcon from "../../assets/img/seses/s2d_ico.png";
// import ComsolIcon from "../../assets/img/brand/logo.svg";
import DefaultIcon from "../../assets/img/brand/logo.svg";
import GeoDictIcon from "../../assets/img/geodict/favicon.png";

/**
 * function to load the Icon given a kcc domain
 * @param {*} host
 * @returns
 */
export function getKccIcon(host) {
  if (host !== null) {
    const isKcc = isKccUrl(host.url);
    if (isKcc) {
      const response = getKccDomain(host.url);
      switch (response) {
        case kccDomains.simvantage:
          return SimvantageIcon;
        case kccDomains.seses:
          return SesesIcon;
        case kccDomains.comsol:
          return DefaultIcon;
        case kccDomains.geodict:
          return GeoDictIcon;
        default:
          return DefaultIcon;
      }
    }
  }
  return null;
}
