/* eslint-disable no-restricted-syntax */
import { REACTOR_PARAMETERS_KEY } from "../../../constants/aibat/aibatJsonFile";
import { findJsonDifferences } from "../../../helpers/findJsonDifferences";

/**
 * Concat two json objects and it also
 * overwrites if the value in json1 already exists
 * @param {Objet} json1
 * @param {Objet} json2
 * @returns
 */
function jsonConcatAndOverwriteValues(json1, json2) {
  const jsonObject1 = { ...json1 };
  const jsonObject2 = { ...json2 };

  for (const key in jsonObject2) {
    if (Object.prototype.hasOwnProperty.call(jsonObject2, key)) {
      if (jsonObject1[key] && typeof jsonObject1[key] === "object" && typeof jsonObject2[key] === "object") {
        // If both values are objects, recursively merge them
        jsonObject1[key] = jsonConcatAndOverwriteValues(jsonObject1[key], jsonObject2[key]);
      } else {
        // Otherwise, overwrite the value from json1 with the value from json2
        jsonObject1[key] = jsonObject2[key];
      }
    }
  }

  return jsonObject1;
}

/**
 * It receives two json objects, the first one will be used as template
 * It reads both files and add/update the fields
 * in the first json file based on the values from the second file
 * @param {JSON} defaultJson
 * @param {JSON} newJson
 * @returns
 */
function setAibatParameters(defaultJson, newJson) {
  let jsonOutput = {};

  const defaultJsonValues = !defaultJson[REACTOR_PARAMETERS_KEY] ? {} : defaultJson[REACTOR_PARAMETERS_KEY];

  jsonOutput = jsonConcatAndOverwriteValues(jsonOutput, defaultJsonValues);

  const finalJsonOutput = jsonConcatAndOverwriteValues(jsonOutput, newJson[REACTOR_PARAMETERS_KEY]);
  // we should concat also inner objects

  // gives proper format for json parameter items
  const response = {};
  response[REACTOR_PARAMETERS_KEY] = { ...finalJsonOutput };

  let diff = {};

  if (jsonOutput && newJson[REACTOR_PARAMETERS_KEY]) {
    // iterate over object
    diff = findJsonDifferences(jsonOutput, newJson[REACTOR_PARAMETERS_KEY]);
  }

  const jsonParameters = response;
  const differences = diff;

  return { jsonParameters, differences };
}

export default setAibatParameters;
