// Action types

export const CLEAN_CASE_FILES = "caseFiles/CLEAN_CASE_FILES";

// thunk types
export const FETCH_ALL_CASEFILES_START = "caseFiles/FETCH_ALL_CASEFILES_START";
export const FETCH_ALL_CASEFILES_SUCCESS = "caseFiles/FETCH_ALL_CASEFILES_SUCCESS";
export const FETCH_ALL_CASEFILES_ERROR = "caseFiles/FETCH_ALL_CASEFILES_ERROR";

// file extensions
export const FETCH_FILE_EXTENSIONS_START = "caseFiles/FETCH_FILE_EXTENSIONS_START";
export const FETCH_FILE_EXTENSIONS_SUCCESS = "caseFiles/FETCH_FILE_EXTENSIONS_SUCCESS";
export const FETCH_FILE_EXTENSIONS_ERROR = "caseFiles/FETCH_FILE_EXTENSIONS_ERROR";

// common files
export const FETCH_COMMON_FILES_START = "caseFiles/FETCH_COMMON_FILES_START";
export const FETCH_COMMON_FILES_SUCCESS = "caseFiles/FETCH_COMMON_FILES_SUCCESS";
export const FETCH_COMMON_FILES_ERROR = "caseFiles/FETCH_COMMON_FILES_ERROR";

// Action creators
export const cleanCaseFiles = () => ({
  type: CLEAN_CASE_FILES,
  payload: {},
});

// thunk action creators

export const fetchAllCaseFilesStart = () => ({
  type: FETCH_ALL_CASEFILES_START,
  payload: {},
});

export const fetchAllCaseFilesSuccess = data => ({
  type: FETCH_ALL_CASEFILES_SUCCESS,
  payload: { data },
});

export const fetchAllCaseFilesError = error => ({
  type: FETCH_ALL_CASEFILES_ERROR,
  payload: { error },
});

// common files
export const fetchCommonFilesStart = () => ({
  type: FETCH_COMMON_FILES_START,
  payload: {},
});

export const fetchCommonFilesSuccess = data => ({
  type: FETCH_COMMON_FILES_SUCCESS,
  payload: { data },
});

export const fetchCommonFilesError = error => ({
  type: FETCH_COMMON_FILES_ERROR,
  payload: { error },
});
// File extensions

export const fetchFileExtensionsStart = () => ({
  type: FETCH_FILE_EXTENSIONS_START,
  payload: {},
});

export const fetchFileExtensionsSuccess = data => ({
  type: FETCH_FILE_EXTENSIONS_SUCCESS,
  payload: { data },
});

export const fetchFileExtensionsError = error => ({
  type: FETCH_FILE_EXTENSIONS_ERROR,
  payload: { error },
});
