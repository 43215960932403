import { ProjectType } from "../../components/projects/project-list/projectTypes";

// actions types
export const LOAD_PROJECT_LIST = "projectList/LOAD_PROJECT_LIST";
export const RESET_PROJECT_LIST = "projectList/RESET_PROJECT_LIST";
export const SELECT_PROJECT = "projectList/SELECT_PROJECT";
export const UNSELECT_PROJECT = "projectList/UNSELECT_PROJECT";
export const SELECT_ALL_PROJECTS = "projectList/SELECT_ALL_PROJECTS";
export const UNSELECT_ALL_PROJECTS = "projectList/UNSELECT_ALL_PROJECTS";

// actions for project list
export const loadProjectList = (data: ProjectType[]) => ({
  type: LOAD_PROJECT_LIST,
  payload: { data },
});

export const resetProjectList = () => ({
  type: RESET_PROJECT_LIST,
  payload: {},
});

export const selectProject = (projectId: string) => ({
  type: SELECT_PROJECT,
  payload: { projectId },
});

export const unSelectProject = (projectId: string) => ({
  type: UNSELECT_PROJECT,
  payload: { projectId },
});

export const selectAllProjects = () => ({
  type: SELECT_ALL_PROJECTS,
  payload: {},
});

export const unSelectAllProjects = () => ({
  type: UNSELECT_ALL_PROJECTS,
  payload: {},
});
