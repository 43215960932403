import React from "react";
import ReactDOM from "react-dom";
import config from "react-global-configuration";
import * as Sentry from "@sentry/browser";
import { CaptureConsole } from "@sentry/integrations";

import "./config/config";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { icons } from "./assets/icons";

React.icons = icons;

global.appVersion = config.get("versionNumber");
const sentryTraceLevel = config.get("sentryTraceLevel");

// init sentry
Sentry.init({
  dsn: "https://9f8fe5fec1244082ad5b64c77c02d4e4@o625721.ingest.sentry.io/5753979",
  integrations: [
    new CaptureConsole({
      levels: ["error"],
    }),
  ],
  environment: process.env.REACT_APP_ENV || "development",
  tracesSampleRate: sentryTraceLevel,
});

console.log(`Using Env: ${process.env.NODE_ENV}`);
console.log(`Using React App Env: ${process.env.REACT_APP_ENV}`);
console.log(`Instance Id: ${process.env.GAE_INSTANCE}`);

ReactDOM.render(<App />, document.getElementById("root"));

// Changes made here
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          if (window.confirm("There is a new version of the app ready. Please reload to update.")) {
            window.location.reload();
          }
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});
