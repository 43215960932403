import { configureStore, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useDispatch, useSelector, TypedUseSelectorHook } from "react-redux";

// Step 2: Create a slice
const initialState = {
  sidebarShow: "responsive",
};

interface AppState {
  sidebarShow: string;
}

const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setSidebarShow: (state, action: PayloadAction<string>) => {
      state.sidebarShow = action.payload;
    },
  },
});

// Step 4: Export actions for components
export const { setSidebarShow } = layoutSlice.actions;

export default layoutSlice.reducer;