export const GET_LICENSE_SERVER_START = "licenseServer/GET_LICENSE_SERVER_START";
export const GET_LICENSE_SERVER_SUCCESS = "licenseServer/GET_LICENSE_SERVER_SUCCESS";
export const GET_LICENSE_SERVER_FAILURE = "licenseServer/GET_LICENSE_SERVER_FAILURE";
export const RERENDER_LICENSE_SERVER = "licenseServer/RERENDER_LICENSE_SERVER";

export const getLicenseServerStart = () => ({
  type: GET_LICENSE_SERVER_START,
  payload: {},
});

export const getLicenseServerSuccess = list => ({
  type: GET_LICENSE_SERVER_SUCCESS,
  payload: { list },
});

export const getLicenseServerFailure = error => ({
  type: GET_LICENSE_SERVER_FAILURE,
  payload: { error },
});

export const reRenderLicenseServer = rerender => ({
  type: RERENDER_LICENSE_SERVER,
  payload: { rerender },
});
