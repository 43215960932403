const {
  INITSTATE,
  STARTUPSTATE,
  RESERVEVMSTATE,
  WAITFORRESERVATIONREADYSTATE,
  RESERVATIONPOLLSTATE,
  RESERVEVMFAILEDSTATE,
  RETRYRESERVEVMSTATE,
  TERMINATEDURINGRESERVATIONSTATE,
  STARTVMSTATE,
  RETRYVMCREATIONSTATE,
  WAITFORVMTOSTARTSTATE,
  VMSTARTFAILEDSTATE,
  WAITFORVMMGRBEFORETERMINATESTATE,
  WAITFORSTARTTOTERMINATESTATE,
  TERMINATEDURINGVMSTARTUPSTATE,
  WAITFORVMPUBSUBSETUPSTATE,
  VMPUBSUBSETUPFAILEDSTATE,
  VMRUNNINGSTATE,
  TIMEOUTSIGNALEDSTATE,
  VMSHUTTINGDOWNSTATE,
  TERMINATEREQUESTEDSTATE,
  ABORTREQUESTEDSTATE,
  ABORTVMSHUTTINGDOWNSTATE,
  BALANCEZEROABORTVMSSTATE,
  BALANCEZEROWAITFORVMABORTSTATE,
  BALANCEZEROVMSHUTTINGDOWNSTATE,
  BALANCEZEROABORTFAILEDSTATE,
  BALANCEZEROABORTFAILEDVMSHUTTINGDOWNSTATE,
  EXPCONFFAILWAITFORVMSHUTDOWNSTATE,
  EXPCONFFAILVMSHUTTINGDOWNSTATE,
  EXPERIMENTCONFIGURATIONFAILURESTATE,
  POSTPROCESSSTATE,
  WATCHDOGUPDATESTATE,
  WATCHDOGFAILEDSTATE,
  COMPLETEDSTATE,
  ABORTEDSTATE,
  TERMINATEDSTATE,
  TIMEDOUTSTATE,
  SHUTTINGDOWNTERMINATEDSTATE,
  VMTERMINATIONREQUESTFAILEDSTATE,
  BALANCEZEROTERMINATEDSTATE,
  BALANCEZEROABORTEDSTATE,
  RESOURCEUNAVAILABLESTATE,
  SIMULATIONCRASHEDSTATE,
  FAILEDSTATE,
  LICENSESERVERNOTFOUNDSTATE,
  ENDSTATE,
  SHUTTINGDOWNSIMULATIONCRASHEDSTATE,
  CASEEXPIREDSTATE,
} = require("./smStates");
// frontend only states (do not merge with statement above)
const { REQUESTSTATE, OPENSTATE, UPLOADSTATE, INITIALSTATE, WAITINGFORINPUTFILE } = require("./smStates");

/**
 * Firebase contains strings associated with the current state of a run.
 *
 * These strings are associated with a Key in the smStates file
 *
 * If the state is missing from the table the function just returns undefined
 */

const smStateStrings = [
  {
    state: INITIALSTATE,
    text: "",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: REQUESTSTATE,
    text: "Please wait",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: WAITINGFORINPUTFILE,
    text: "Waiting for input file",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: UPLOADSTATE,
    text: "Uploading",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: CASEEXPIREDSTATE,
    text: "Case Expired",
    color: "warning",
    className: "fa fa-clock-o",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: false,
  },
  {
    state: OPENSTATE,
    text: "Open",
    color: "light",
    className: "",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: false,
  },
  {
    state: INITSTATE,
    text: "Initialising",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: STARTUPSTATE,
    text: "Starting up",
    color: "light",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RESERVEVMSTATE,
    text: "Reserving your machine",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RETRYRESERVEVMSTATE,
    text: "Reserving your machine",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RETRYRESERVEVMSTATE,
    text: "Retrying creation",
    color: "info",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: WAITFORRESERVATIONREADYSTATE,
    text: "Reserving your machine",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RESERVATIONPOLLSTATE,
    text: "Reserving your machine",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RESERVEVMFAILEDSTATE,
    text: "Retrying reservations",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: TERMINATEDURINGRESERVATIONSTATE,
    text: "Terminating",
    color: "dark",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: STARTVMSTATE,
    text: "Configuring your machine",
    color: "info",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: RETRYVMCREATIONSTATE,
    text: "Configuring your machine",
    color: "info",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: WAITFORVMTOSTARTSTATE,
    text: "Starting your machine",
    color: "info",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: WAITFORVMPUBSUBSETUPSTATE,
    text: "Starting your machine",
    color: "info",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: VMPUBSUBSETUPFAILEDSTATE,
    text: "Startup failure",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: VMSTARTFAILEDSTATE,
    text: "Startup failure",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: WAITFORVMMGRBEFORETERMINATESTATE,
    text: "Terminate pending",
    color: "dark",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: WAITFORSTARTTOTERMINATESTATE,
    text: "Terminate pending",
    color: "dark",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: TERMINATEDURINGVMSTARTUPSTATE,
    text: "Terminate processing",
    color: "dark",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: VMRUNNINGSTATE,
    text: "Simulation Running",
    color: "primary",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: false,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: TIMEOUTSIGNALEDSTATE,
    text: "Timeout signaled",
    color: "primary",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: VMSHUTTINGDOWNSTATE,
    text: "Transferring results",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: SHUTTINGDOWNSIMULATIONCRASHEDSTATE,
    text: "Transferring results",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: TERMINATEREQUESTEDSTATE,
    text: "Terminate requested",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: VMTERMINATIONREQUESTFAILEDSTATE,
    text: "Terminate requested",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: SHUTTINGDOWNTERMINATEDSTATE,
    text: "Shutting Down - terminate",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: ABORTREQUESTEDSTATE,
    text: "Abort requested",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: ABORTVMSHUTTINGDOWNSTATE,
    text: "Aborted VM Shutting down",
    color: "warning",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: BALANCEZEROABORTVMSSTATE,
    text: "Aborting - 0 balance",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: BALANCEZEROWAITFORVMABORTSTATE,
    text: "Aborting - 0 balance",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: BALANCEZEROVMSHUTTINGDOWNSTATE,
    text: "Shutting Down - 0 balance",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: BALANCEZEROABORTFAILEDSTATE,
    text: "Terminating - 0 balance",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: BALANCEZEROABORTFAILEDVMSHUTTINGDOWNSTATE,
    text: "Shutting Down - 0 balance",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: false,
    blockDelete: true,
  },
  {
    state: COMPLETEDSTATE,
    text: "Completed",
    color: "success",
    className: "fa fa-check-circle-o",
    blockRun: true,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: ABORTEDSTATE,
    text: "Aborted",
    color: "dark",
    className: "fa fa-times-circle-o",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: TERMINATEDSTATE,
    text: "Terminated",
    color: "dark",
    className: "fa fa-times-circle-o",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: TIMEDOUTSTATE,
    text: "Timed out",
    color: "dark",
    className: "fa fa-times-circle-o",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: BALANCEZEROTERMINATEDSTATE,
    text: "Terminated - 0 balance",
    color: "dark",
    className: "fa fa-times-circle-o",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: BALANCEZEROABORTEDSTATE,
    text: "Aborted - 0 balance",
    color: "dark",
    className: "fa fa-times-circle-o",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: FAILEDSTATE,
    text: "Failed",
    color: "danger",
    className: "fa fa-times",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: RESOURCEUNAVAILABLESTATE,
    text: "Resource Unavailable",
    color: "secondary",
    className: "fa fa-times",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: LICENSESERVERNOTFOUNDSTATE,
    text: "License Server not found",
    color: "danger",
    className: "fa fa-times",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: POSTPROCESSSTATE,
    text: "Post Processing",
    color: "secondary",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true,
    blockDelete: true,
  },
  {
    state: WATCHDOGFAILEDSTATE,
    text: "Shutting down - Simulation crashed",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: true,
  },
  {
    state: SIMULATIONCRASHEDSTATE,
    text: "Simulation Crashed",
    color: "danger",
    className: "fa fa-times",
    blockRun: false,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: EXPCONFFAILWAITFORVMSHUTDOWNSTATE,
    text: "Shutting Down - Experiment Failure",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: true,
  },
  {
    state: EXPCONFFAILVMSHUTTINGDOWNSTATE,
    text: "Shutting Down - Experiment Failure",
    color: "danger",
    className: "fa fa-spinner fa-spin",
    blockRun: true,
    blockEdit: true,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: true,
  },
  {
    state: EXPERIMENTCONFIGURATIONFAILURESTATE,
    text: "Experiment configuration failure",
    color: "danger",
    className: "fa fa-times",
    blockRun: true,
    blockEdit: false,
    blockAbort: true,
    blockTerminate: true, //block
    blockDelete: false,
  },
  {
    state: ENDSTATE,
    text: "Ended",
    color: "light",
    className: "fa fa-times",
    blockRun: false,
    blockEdit: false,
    blockAbort: false,
    blockTerminate: false,
    blockDelete: false,
  },
];
// ENDSTATE:"" << removed end state because we should show Completed, aborted or failed as the last state.
// OSM will not send this state to firebase
function getStateDescription(stateString) {
  // filter array object
  const [smDescription] = smStateStrings.filter((smValue) => {
    // return only the object that match with stateString
    return smValue.state === stateString;
  });

  return smDescription;
}

/**
 * Get state object using the text state as filter
 * @param {String} textState 
 * @returns 
 */
function getStateDescriptionFromTextState(textState) {
  // filter array object
  const [smDescription] = smStateStrings.filter((smValue) => {
    // return only the object that match with stateString
    return smValue.text.trim() === textState.trim();
  });

  return smDescription;
}

function getIsRunningState(state) {
  return (
    state !== ENDSTATE &&
    state !== FAILEDSTATE &&
    state !== BALANCEZEROABORTEDSTATE &&
    state !== BALANCEZEROTERMINATEDSTATE &&
    state !== TERMINATEDSTATE &&
    state !== ABORTEDSTATE &&
    state !== COMPLETEDSTATE &&
    state !== OPENSTATE &&
    state !== VMSTARTFAILEDSTATE &&
    state !== EXPERIMENTCONFIGURATIONFAILURESTATE &&
    state !== RESOURCEUNAVAILABLESTATE
  );
}

function getIsOpenState(state) {
  return state === OPENSTATE;
}

module.exports = {
  smStateStrings,
  getStateDescription,
  getIsRunningState,
  getIsOpenState,
  getStateDescriptionFromTextState,
};
