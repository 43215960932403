/**
 * Function that returns correct run script as a string for given case parameters
 * @param {string} solverName
 * @param {number} numCPUs
 * @param {string} sliderValue
 * @param {boolean} parallel
 * @param {string} currentRunScript
 * @returns {string} the runScript itself
 */
export function getRunScriptValue(solverName, numCPUs, sliderValue, parallel, currentRunScript) {
  let runScript;
  if (solverName !== "") {
    if (parallel) {
      const cpu = sliderValue === "1" ? numCPUs / 2 : numCPUs; // refs: T973
      const checkSolver = "MultiRegion";
      // check if the solver name contains MultiRegion
      if (solverName.includes(checkSolver)) {
        runScript = `decomposePar -allRegions &> $ROOT_DIRECTORY/log.txt\nmpirun --allow-run-as-root -np ${cpu} ${solverName} -parallel &>> $ROOT_DIRECTORY/log.txt\nreconstructPar -allRegions &>> $ROOT_DIRECTORY/log.txt\nrm -r processor*`;
      } else {
        runScript = `decomposePar &> $ROOT_DIRECTORY/log.txt\nmpirun --allow-run-as-root -np ${cpu} ${solverName} -parallel &>> $ROOT_DIRECTORY/log.txt\nreconstructPar &>> $ROOT_DIRECTORY/log.txt\nrm -r processor*`;
      }
    } else {
      runScript = `${solverName} &> $ROOT_DIRECTORY/log.txt`;
    }
    return runScript;
  }
  return currentRunScript;
}

/**
 * Function to get value for parallel execution
 * @param {string} sliderValue
 * @param {number} numCPUs
 */
export function getIsParallel(sliderValue, numCPUs) {
  // parallel should be false for single core and when 2 cores and physical option is selected
  return !(sliderValue === "0" || (sliderValue === "1" && numCPUs === 2));
}

/**
 * function to format the inputParameter field to the right one in database
 * @param {Array} inputParameter
 */
export function formatInputParameter(inputParameters) {
  const inputParametersData = inputParameters.map(inputParameter => {
    // validate filePath field
    const filePath = inputParameter.filePath ? inputParameter.filePath : inputParameter.path;

    // validate variable field
    const variable = inputParameter.variable ? inputParameter.variable : inputParameter.variableName;

    return {
      filePath,
      variable,
      value: inputParameter.value,
    };
  });

  return inputParametersData;
}
