// Action types

export const SUBSCRIBE_START = "heartbeat/SUBSCRIBE_START";
export const SUBSCRIBE_UPDATE = "heartbeat/SUBSCRIBE_UPDATE";
export const SUBSCRIBE_ERROR = "heartbeat/SUBSCRIBE_ERROR";

// Action creators
export const subscribeStart = runId => ({
  type: SUBSCRIBE_START,
  payload: { runId },
});

export const subscribeUpdate = (runId, heartbeat) => ({
  type: SUBSCRIBE_UPDATE,
  payload: { runId, heartbeat },
});

export const subscribeError = (runId, error) => ({
  type: SUBSCRIBE_ERROR,
  payload: { runId, error },
});
