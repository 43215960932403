import { validate } from "email-validator";

type RegistrationRequest = {
  email: string;
  password: string;
  repeatPassword: string;
  firstName: string;
  lastName: string;
  countryCode: string;
  captcha: boolean;
  country: string;
  termsAndConditions: boolean;
};

type RegistrationError = {
  email: string;
  password: string;
  password2: string;
  fname: string;
  lname: string;
  captcha: string;
  country: string;
  termsAndConditions: string;
};

type RegistrationResponse = {
  error: RegistrationError;
  formIsValid: boolean;
};
/**
 * function that validates the fields when registering a new user
 * @param {*} param0
 * @returns
 */
export function validateForm(data: RegistrationRequest, token?: string): RegistrationResponse {
  const {
    email,
    password,
    repeatPassword,
    firstName,
    lastName,
    captcha,
    termsAndConditions,
    country,
  } = data;

  const error = {} as RegistrationError;
  let formIsValid = true;

  if (!validate(email)) {
    formIsValid = false;
    error.email = "Email is not valid!";
  }

  if (password !== repeatPassword) {
    formIsValid = false;
    error.password2 = "Passwords do not match";
  }

  if (password === "") {
    formIsValid = false;
    error.password = "This field cannot be empty";
  }
  if (firstName === "") {
    formIsValid = false;
    error.fname = "This field cannot be empty";
  }
  if (lastName === "") {
    formIsValid = false;
    error.lname = "This field cannot be empty";
  }
  if (!token && !captcha ) {
    formIsValid = false;
    error.captcha = "Please complete the captcha";
  }

  if (!termsAndConditions) {
    formIsValid = false;
    error.termsAndConditions = "Please check the terms & conditions";
  }

  if (!country) {
    formIsValid = false;
    error.country = "Please select a country";
  }
  return { formIsValid, error };
}
