import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import styled from "styled-components";
import { Card, CardGroup, Col, Container, Row, CardBody, CardFooter, Input, CardTitle, Button } from "reactstrap";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { loginWithRecoveryCode } from "../../../queries/user-queries";
import ReEnableModal from "./ReEnableModal";
import ThemedLogo from "../../common/themedLogo/ThemedLogo";

/**
 * Component that renders the login with recovery page
 * displays an input where the user should write the recovery code
 * If login is true, displays a modal with extra information
 * @param {userId} props
 */

function RecoveryCodePage(props) {
  const [recoveryCode, setRecoveryCode] = useState("");
  const [modal, setModal] = useState(false);
  const [token, setToken] = useState("");

  const { userId } = props.location;

  const [login] = useMutation(loginWithRecoveryCode);

  const onSubmitCode = async () => {
    try {
      const { data } = await login({
        variables: {
          userId,
          recoveryCode,
        },
      });
      if (data.loginWithRecoveryCode.success) {
        setToken(data.loginWithRecoveryCode.token);
        localStorage.setItem("token", token);
        setModal(true);
      } else {
        toast.error(data.loginWithRecoveryCode.errors[0].message);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const onInputChange = (event) => {
    setRecoveryCode(event.target.value);
  };

  return (
    <>
      {modal ? <ReEnableModal isOpen={modal} token={token} userId={userId} /> : false}
      {userId === undefined ? (
        <Redirect to="/login" />
      ) : (
        <div className=" align-items-center">
          <Container>
            <Row className="justify-content-center mb-3">
              <Col md="8">
                <CardGroup>
                  <Card className="p-4">
                    <StyledThemedLogo className="mx-auto d-block" alt="Logo" />
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md="8">
                <Card body>
                  <CardTitle>Log In with Recovery Code</CardTitle>
                  <CardBody className="text-center">
                    <h5> Please write your recovery code below</h5>
                    <RecoveryCodeInput maxLength="8" onChange={onInputChange} />
                  </CardBody>
                  <Button color="success" className="m-2 rounded" onClick={onSubmitCode}>
                    Submit
                  </Button>
                  <CardFooter className="d-flex align-items-center">
                    <h5>Back to Login Page</h5>
                    <Link to="/login">
                      <Button className="ml-2" color="primary">
                        Login
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}

const StyledThemedLogo = styled(ThemedLogo)`
  width: 95%;
`;

const RecoveryCodeInput = styled(Input)`
  width: 100%;
  font-size: 50px;
  color: black;
  text-align: center;
`;

export default RecoveryCodePage;
