import { GET_GPU_LIST_START, GET_GPU_LIST_SUCCESS, GET_GPU_LIST_FAILURE } from "./actions";

/* initial state */
export const initialState = {
  gpuList: [],
  gpuListLoading: false,
};

export default function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case GET_GPU_LIST_START: {
      return {
        ...state,
        gpuListLoading: true,
      };
    }
    case GET_GPU_LIST_SUCCESS: {
      const { gpuList } = payload;
      return {
        ...state,
        gpuListLoading: false,
        gpuList,
      };
    }
    case GET_GPU_LIST_FAILURE: {
      const { error } = payload;
      return {
        ...state,
        gpuListLoading: false,
        getGpuListError: error,
      };
    }

    default:
      return state;
  }
}
