import React, { Component } from "react";
import PropTypes from "prop-types";
import zxcvbn from "zxcvbn";

import { Input, InputGroup, InputGroupAddon, InputGroupText } from "reactstrap";

class PasswordField extends Component {
  constructor(props) {
    super(props);
    const { minstrength = 3, thresholdlength = 7 } = props;

    this.minstrength = typeof minstrength === "number" ? Math.max(Math.min(minstrength, 4), 0) : 3;

    this.thresholdlength = typeof thresholdlength === "number" ? Math.max(thresholdlength, 7) : 7;

    this.state = { password: "", strength: 0 };
  }

  stateChanged = state => {};

  validatePasswordStrong = event => {
    event.preventDefault();

    const value = event.target.value;

    this.setState({
      password: value,
      strength: zxcvbn(value).score,
    });

    this.props.onChange(event);
  };

  render() {
    const { type, validator, onStateChanged, children, ...restProps } = this.props;
    const { password, strength } = this.state;
    const passwordLength = password.length;
    const passwordStrong = strength >= this.minstrength;
    const passwordLong = passwordLength > this.thresholdlength;

    const counterClass = [
      "badge badge-pill",
      passwordLong ? (passwordStrong ? "badge-success" : "badge-warning") : "badge-danger",
    ]
      .join(" ")
      .trim();

    const strengthClass = ["strength-meter mt-2", passwordLength > 0 ? "visible" : "invisible"].join(" ").trim();

    return (
      <>
        <div className="position-relative">
          <div className={strengthClass}>
            <div className="strength-meter-fill" data-strength={strength} />
          </div>
          <div className="position-absolute password-count mx-3">
            <span className={counterClass}>
              {passwordLength ? (passwordLong ? `${this.thresholdlength}+` : passwordLength) : ""}
            </span>
          </div>
        </div>
        <InputGroup className="mb-3">
          <InputGroupAddon addonType="prepend">
            <InputGroupText>
              <i className="icon-lock" />
            </InputGroupText>
          </InputGroupAddon>

          <Input data-test-id="input-password" type="password" {...restProps} onChange={this.validatePasswordStrong} />
          {children}
        </InputGroup>
      </>
    );
  }
}

PasswordField.propTypes = {
  label: PropTypes.string.isRequired,
  fieldId: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  children: PropTypes.node,
  onChange: PropTypes.func,
  minstrength: PropTypes.number,
  thresholdLength: PropTypes.number,
};

export default PasswordField;
