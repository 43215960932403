import React, { useCallback, useState } from "react";
import { Button, Alert, Card, CardBody, CardFooter, Col, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { useMutation } from "@apollo/client";
import { verifyEmail } from "../../../queries/user-queries";
import LayoutLogo from "../../common/LayoutLogo";

/**
 * Requests a new confirmation email if the user
 * did not enabled his account yet
 * @param {Object} props
 */
function SendNewEmail(props) {
  const { email } = props;
  const [verify] = useMutation(verifyEmail);
  const [errorFlag, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = useCallback(async () => {
    try {
      const { data } = await verify({
        variables: { email },
      });
      const { success } = data.verifyEmail;
      if (success) {
        setError(false);
        toast.success("Email was sent successfully");
      } else {
        setError(true);
        const { message } = data.verifyEmail.errors[0];
        setErrorMessage(message);
      }
    } catch (e) {
      console.error(e);
      setErrorMessage("Network error :(, please try again");
    }
  });
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <LayoutLogo />
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="p-4">
              <CardBody className="p-4">
                <p>
                  Your email address has not been confirmed. Please click the link in the email we sent to you to
                  continue. If you did not receive the email please click
                  <Button id="sendEmail" color="link" onClick={validateEmail}>
                    here
                  </Button>
                  to request a new one.
                </p>

                <br />
                {errorFlag && <Alert color="danger">{errorMessage}</Alert>}
              </CardBody>
              <CardFooter className="p-4 d-flex align-items-center">
                <h4>Have you already confirmed your account?</h4>
                <Link to="/login">
                  <Button className="ml-2" color="primary">
                    Login
                  </Button>
                </Link>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  const { confirmEmail } = state;
  return {
    email: confirmEmail.email,
  };
}

export default connect(mapStateToProps, null)(SendNewEmail);
