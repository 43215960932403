/* eslint-disable consistent-return */

export const key = "kcc";
export default class domainStore {
  static storeDomain(domain) {
    try {
      const domainStorage = {
        url: domain.url,
        domain: domain.domain,
      };
      localStorage.setItem(key, JSON.stringify(domainStorage));
    } catch (err) {
      console.error("error: ", err);
    }
  }

  static getDomain() {
    let domain = null;
    try {
      domain = JSON.parse(localStorage.getItem(key));
    } catch (err) {
      console.info(err);
    }
    return domain;
  }

  static clearDomain() {
    try {
      localStorage.removeItem(key);
    } catch (err) {
      console.error(err);
    }
  }
}
