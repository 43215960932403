import React, { useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
/**
 * Component that handles the <Input> from reactstrap with some neccesary validations
 * neccesary attributes for the props
 * placeholder= the input placeholder (string)
 * maxLenght= how many numbers are required for the input (int)
 */
const NumberInput = props => {
  const [value, setValue] = useState("");
  const formatInput = e => {
    // Prevent characters that are not numbers ("e", ".", "+" & "-") ✨
    let checkIfNum;
    if (e.key !== undefined) {
      // Check if it's a "e", ".", "+" or "-"
      checkIfNum = e.key === "e" || e.key === "." || e.key === "+" || e.key === "-";
    } else if (e.keyCode !== undefined) {
      // Check if it's a "e" (69), "." (190), "+" (187) or "-" (189)
      checkIfNum = e.keyCode === 69 || e.keyCode === 190 || e.keyCode === 187 || e.keyCode === 189;
    }
    return checkIfNum && e.preventDefault();
  };

  const maxLengthCheck = object => {
    setValue(object.target.value);
    if (object.target.value.length > object.target.maxLength) {
      setValue(object.target.value.slice(0, object.target.maxLength));
    }
  };

  return (
    <TokenInput
      style={props.style}
      name="numInput"
      type="number"
      value={value}
      onChange={props.onInputChange}
      onKeyDown={formatInput}
      maxLength={props.maxLength}
      placeholder={props.placeholder}
      onInput={maxLengthCheck}
      min={0}
    />
  );
};

const TokenInput = styled.input`
  width: 100%;
  font-size: 60px;
  color: black;
  text-align: center;
`;
NumberInput.propTypes = {
  maxLength: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  onInputChange: PropTypes.func.isRequired,
};
export default NumberInput;
