import gql from 'graphql-tag';

export const getData = gql`
    query runState{
        runState @client{
            json
            inputFile
        }
    }
`
export const putData = gql`
    mutation addSetRun($json:String,$inputFile:String){
        addSetRun(json:$json,inputFile:$inputFile) @client
    }
`
//--- local queries to get and set data ro run cases
export const getLocalRunData = gql`
    query getLocalRun{
        getLocalRun @client{
            caseId
        }
    }
`
export const setLocalRunData = gql`
    mutation setLocalRun( $run:idataRun! ){
        setLocalRun( run:$run ) @client
    }
`
export const updateLocalRunData = gql`
    mutation updateLocalRun( $run:idataRun! ){
        updateLocalRun( run:$run ) @client
    }
`
export const resetLocalStorage = gql`
    mutation restoreLocal{
        restoreLocal @client
    }
`
export const updateLocalFileId = gql`
    mutation updateLocalFileId($url:String!,$inputFileId:ID!){
        updateLocalFileId(url:$url,inputFileId:$inputFileId) @client
    }
`
export const getLocalNumberCases = gql`
    query getLocalNumberCases{
        getLocalNumberCases @client{
            cases
        }
    }
`
export const incrementCounter = gql`
    mutation incrementCounter{
        incrementCounter @client
    }
`
export const decrementCounter = gql`
    mutation decrementCounter{
        decrementCounter @client
    }
`
export const resetCounter = gql`
    mutation resetCounter{
        resetCounter @client
    }
`