import React, { Suspense, lazy } from "react";
import { useSelector } from "react-redux";

import { GEODICT, AIBAT } from "../../../featureFlags";
import { getFeatureFlag } from "../../../store/featureFlags/reducer";
import domainStore from "../../../helpers/kcc/DomainStore";
import { getKccLogo } from "../../../helpers/kcc/kccLogo";

const DefaultLogo = lazy(() => import("./DefaultLogo"));
const GeodictLogo = lazy(() => import("./GeodictLogo"));
const DefaultSmallLogo = lazy(() => import("./DefaultSmallLogo"));

/**
 * Component that loads the logo given the node_env or domain url
 * @param {*} props
 * @returns
 */
function ThemedLogo(props) {
  const isGeoDictEnabled = useSelector((state) => getFeatureFlag(state, GEODICT));
  const isAibatEnabled = useSelector((state) => getFeatureFlag(state, AIBAT));
  let Logo = isAibatEnabled || !isGeoDictEnabled ? DefaultLogo : GeodictLogo;

  const kccLogo = getKccLogo(domainStore.getDomain());
  if (kccLogo !== null) {
    Logo = kccLogo;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Logo {...props} />
    </Suspense>
  );
}

export function ThemedSmallLogo(props) {
  const isGeoDictEnabled = useSelector((state) => getFeatureFlag(state, GEODICT));
  // fix once we have a small logo for GeoDict
  const Logo = isGeoDictEnabled ? GeodictLogo : DefaultSmallLogo;

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Logo {...props} />
    </Suspense>
  );
}

export default ThemedLogo;
