import React, { useCallback, useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { Container, Row, Col, Card, Button, Form, Alert } from "reactstrap";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import LayoutLogo from "../../common/LayoutLogo";
import TextInputInline from "../../common/TextInputInline";
import PasswordField from "../signup/PasswordField";
import { resetPasswordMutation } from "../../../queries/user-queries";
/**
 * Reset password form
 * Allows to the user set a new password
 * @param {Object} props
 */
function ResetPassword(props) {
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [redirect, setRedirect] = useState(false);
  const [loading, setLoading] = useState(false);

  const [token, setToken] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [resetPassword] = useMutation(resetPasswordMutation);

  // if email does not exist
  useEffect(() => {
    const { emailtoken } = props.match.params;

    if (!emailtoken) setRedirect(true);
    else setToken(emailtoken);
  }, []);

  const onPasswordChange = useCallback(e => {
    setPassword(e.target.value);
  });

  const onRepeatPasswordChange = useCallback(e => {
    setRepeatPassword(e.target.value);
  });
  // reset password
  const confirmResetPassword = useCallback(async () => {
    setLoading(true);
    if (!password || !repeatPassword) setErrorMessage("Complete the required fields.");
    else if (password !== repeatPassword) setErrorMessage("Password fields do not match");
    else {
      try {
        const { data } = await resetPassword({ variables: { token, password } });
        const { success, errors } = data.resetPassword;
        // validating response
        if (!success) setErrorMessage(errors[0].message);
        else {
          setErrorMessage(null);
          toast.success("Your password has been changed successfully!");
          // redirect to login page
          setRedirect(true);
        }
      } catch (e) {
        console.error(e);
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
    setLoading(false);
  });

  return (
    <div className="app flex-row align-items-center">
      {redirect && <Redirect to="/login" />}
      <Container>
        <LayoutLogo />
        <Row className="justify-content-center">
          <Col md="8">
            <Card className="p-4">
              <Form>
                <h1>Reset Password</h1>
                <p className="text-muted">Please enter your new password and confirm.</p>
                {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                <PasswordField
                  label="Password"
                  name="password"
                  placeholder="Password"
                  autoComplete="new-password"
                  onChange={onPasswordChange}
                  thresholdlength={7}
                  minstrength={3}
                  required
                />
                <TextInputInline
                  groupClass="mb-3"
                  required
                  type="password"
                  value={repeatPassword}
                  name="repeatPassword"
                  placeholder="Confirm New Password"
                  autoComplete="email"
                  onChange={onRepeatPasswordChange}
                  icon="icon-lock"
                />

                <Button disabled={loading} id="reset-button" color="primary" onClick={confirmResetPassword}>
                  Reset my Password
                </Button>
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default ResetPassword;
