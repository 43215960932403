import React from "react";
import Helmet from "react-helmet";

import FeatureToggle from "./common/FeatureToggle";
import { GEODICT, AIBAT } from "../featureFlags";

import GeoDictIcon from "../assets/img/geodict/favicon.png";
import KaleidoIcon from "../assets/img/brand/favicon.png";
import domainStore from "../helpers/kcc/DomainStore";
import { getKccIcon } from "../helpers/kcc/kccIcon";

/**
 * Component to render Head element based on geodict flag
 */
function DocHead() {
  const domain = domainStore.getDomain();
  const kccIcon = getKccIcon(domain);
  if (kccIcon !== null) {
    return (
      <Helmet>
        <title>{domain.domain}</title>
        <link rel="shortcut icon" href={kccIcon} />
      </Helmet>
    );
  }

  return (
    <>
      <FeatureToggle flag={GEODICT}>
        <Helmet>
          <title>Geodict</title>
          <link rel="shortcut icon" href={GeoDictIcon} />
        </Helmet>
      </FeatureToggle>
      <FeatureToggle flag={AIBAT}>
        <Helmet>
          <title>AiBat</title>
          <link rel="shortcut icon" href={KaleidoIcon} />
        </Helmet>
      </FeatureToggle>
      <FeatureToggle flag={[GEODICT, AIBAT]} hide>
        <Helmet>
          <title>KaleidoSim</title>
          <link rel="shortcut icon" href={KaleidoIcon} />
        </Helmet>
      </FeatureToggle>
    </>
  );
}

export default DocHead;
